import { routes } from "../../../../router";
import BusinessUnitConfigStore from "../../../../store/BusinessUnitConfigStore";
import { BaseListViewModel } from "../../../components/BaseListViewModel";
import {
  AnnouncementListModel,
  CreateAgentGroupModel,
  UpdateAgentGroupModel,
} from "../../../../services/BusinessUnitConfigService";
import { action, observable } from "mobx";
import Collection from "../../../../infrastructure/CollectionHelper";
import { ErrorModel } from "../../../../infrastructure/ErrorModel";
import { AbstractValidator } from "fluent-ts-validator";
import UserContext from "../../../../infrastructure/UserContext";
import Utils from "../../../../infrastructure/Utils";

export class AgentGroupListViewModel extends BaseListViewModel<AnnouncementListModel> {
  @observable groups = new Collection<{ label: string; value: string }>();
  @observable selectedGroups: any = null;
  @observable users = new Collection<{ label: string; value: string }>();
  @observable selectedUsers: any = null;
  @observable addEditGroupName: boolean = false;
  @observable GroupName: string | undefined;
  @observable CreatedBy: number | 0 = 0;
  @observable actionTitle: string = "";
  @observable response: any;
  @observable isMessgeVisible: boolean = false;
  @observable public ErrorModel = new ErrorModel(new AgentGroupValidator());

  constructor() {
    super(
      "Agent Groups",
      "Agent Group",
      routes.addagentGroup,
      routes.editagentGroup,
      "id",
      "id",
      false
    );
  }

  protected async getItems(
    pageIndex: number,
    pageSize: number,
    sortColumn: string,
    sortOrder: boolean
  ) {
    return await BusinessUnitConfigStore.loadAgentGroupList(
      this.selectedGroups,
      this.selectedUsers,
      pageIndex,
      pageSize,
      sortColumn,
      sortOrder
    );
  }
  protected toServiceModel() {
    return {
      id: this.selectedId,
      groupName: this.GroupName,
      createdby: UserContext.getUserId(),
    };
  }
  protected async loadLookups() {
    this.response = [];
    this.isMessgeVisible = false;
    var items = await BusinessUnitConfigStore.getAllUserLookup();
    Utils.mapListItemsToDropdown(items, this.users, "");

    var itemsGroups =
      await BusinessUnitConfigStore.getAllAgentGroupNameLookup();
    Utils.mapListItemsToDropdown(itemsGroups, this.groups, "");
    if (itemsGroups.length == 0) {
      this.selectedGroups = null;
    }
    if (
      this.selectedGroups &&
      this.selectedGroups.length > 0 &&
      itemsGroups.length > 0
    ) {
      let groups = itemsGroups.filter((i) =>
        this.selectedGroups.includes(i.value)
      );
      this.selectedGroups = groups.map((i) => i.value);
    }
  }
  protected resetModel() {
    this.GroupName = "";
    this.response = [];
    this.isMessgeVisible = false;
  }
  @action setSelectedUsers = (value: any) => {
    this.selectedUsers = value;
  };
  @action setSelectedGroups = (value: any) => {
    this.selectedGroups = value;
  };
  @action public Validate = async () => {
    return await this.ErrorModel.Validate(this);
  };
  @action public ResetValidate = async () => {
    return await this.ErrorModel.ResetValidation(this);
  };
  @action saveAgentGroup = async () => {
    try {
      if (await this.Validate()) {
        return false;
      }
      if (this.selectedId === 0) {
        let model: CreateAgentGroupModel = {
          groupName: this.GroupName,
          createdUserId: UserContext.getUserId(),
        };
        await BusinessUnitConfigStore.addAgentGroup(model);
      } else {
        let model: UpdateAgentGroupModel = {
          id: this.selectedId,
          groupName: this.GroupName,
        };
        await BusinessUnitConfigStore.updateAgentGroup(model);
      }
      this.addEditGroupName = false;
      this.loadItems();
      this.loadLookups();
    } catch (e: any) {
      this.isMessgeVisible = false;
      e.text().then((_responseText: any) => {
        let _title = JSON.parse(_responseText).title;
        let _status = JSON.parse(_responseText).status;
        var responseModels = {
          status: _status,
          title: "",
          errors: { ValidationError: [_title] },
        };
        this.response = JSON.stringify(responseModels);
        this.isMessgeVisible = true;
      });
      //this.setAddEditGroupNameDialogVisible(false);
    }
  };

  protected async deleteItem(id: number) {
    try {
      await BusinessUnitConfigStore.deleteAgentGroup(id);
      this.loadLookups();
    } catch (e) {}
  }

  @action async resetFiltersToDefault(): Promise<void> {
    this.pageSize = 10;
    this.selectedUsers = null;
    this.selectedGroups = null;
    this.loadItems();
  }
  @action editAgentGroup(id: number) {
    this.selectedId = id;
    this.actionTitle = "Edit Group Name";
    this.addEditGroupName = true;
    this.loadItem(id);
  }
  protected async loadItem(id: number): Promise<void> {
    var item = await BusinessUnitConfigStore.getAgentGroupDetail(id);
    if (item) {
      this.selectedId = item.id || 0;
      this.GroupName = item.groupName || "";
      this.CreatedBy = item.createdUserId || 0;
    }
  }
  @action setAddEditGroupNameDialogVisible(value: boolean) {
    this.ResetValidate();
    this.resetModel();
    this.actionTitle = value ? "Add Group Name" : "Edit Group Name";
    this.addEditGroupName = value;
    this.response = [];
    this.isMessgeVisible = false;
  }

  @action onHide = async (value: string) => {
    this.setAddEditGroupNameDialogVisible(false);
    this.response = [];
    this.isMessgeVisible = false;
  };
}
class AgentGroupValidator extends AbstractValidator<AgentGroupListViewModel> {
  public constructor() {
    super();
    this.validateIfString((input) => input.GroupName)
      .matches(/\S+/, "i")
      .hasMaxLength(100)
      .isNotEmpty()
      .withFailureMessage(
        "Group Name is required and should be less than 100 characters"
      );
  }
}
