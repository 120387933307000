import ViewModel from "../infrastructure/ViewModel";
import { observable, action, computed, toJS } from "mobx";
import {
  routes,
  getCurrentRoute,
  agentGroup,
  administrationGroup,
  workflowGroup,
  autoDialerGroup,
  customerGroup,
  submissionGroup,
  reportGroup,
  carrierManagementGroup,
} from "./../router";

import UserStore from "../store/IdentityStore";
import {
  ReloadMenusListener,
  AppInlineProfileViewModel,
} from "./AppInlineProfileViewModel";
import { TopHeaderSearchViewModel } from "./TopHeaderSearchViewModel";
import { MenuItem } from "primereact/components/menuitem/MenuItem";
import { HomeDashboardViewModel } from "./home/HomeDashboardViewModel";
import RouteList from "../infrastructure/RouteList";
import RouteParamDetails from "../infrastructure/RouteParamDetails";
import IMASLog from "../infrastructure/IMASLog";
import { AddTaskViewModel } from "./agents/AddTaskViewModel";
import { LeadListViewModel } from "./leads/LeadListViewModel";
import { SalesLeadListViewModel } from "./leads/SalesLeadListViewModel";
import { AgentLeadAddViewModel } from "./leads/AgentLeadAddViewModel";
import { AgentLeadEditViewModel } from "./leads/AgentLeadEditViewModel";
import { NonContractedAgentLeadDetailViewModel } from "./leads/NonContractedAgentLeadDetailViewModel";
import { ContractedAgentLeadDetailViewModel } from "./leads/ContractedAgentLeadDetailViewModel";
import { SalesOpsAgentLeadsDetailViewModel } from "./leads/SalesOpsAgentLeadsDetailViewModel";
import { UnmatchedLeadsDetailViewModel } from "./leads/UnmatchedLeadsDetailViewModel";
import { CustomersViewModel } from "./customers/CustomersViewModel";
import { AddEditPolicyViewModel } from "./agents/policies/AddEditPolicyViewModel";
import { DashboardViewModel } from "./DashboardViewModel";
import { AddSupplyOrderViewModel } from "./agents/orders/AddSupplyOrderViewModel";
import { AgentOrdersViewModel } from "./agents/orders/AgentOrdersViewModel";
import { OrderDetailsViewModel } from "./agents/orders/OrderDetailsViewModel";
import { SaleOpsAgentLeadAddViewModel } from "./leads/SaleOpsAgentLeadAddViewModel";
import { SaleOpsAgentLeadEditViewModel } from "./leads/SaleOpsAgentLeadEditViewModel";
import { UnmatchedLeadListViewModel } from "./leads/UnmatchedLeadListViewModel";
import { ReportsViewModel } from "./../modules/reports/standardReports/ReportsViewModel";
import { SelfServiceReportDashboardViewModel } from "./../modules/reports/SelfServiceReportDashboardViewModel";
import { ErrorViewModel } from "./ErrorViewModel";
import { DocumentManagementViewModel } from "./documents/DocumentManagementViewModel";
import { AddEditCustomerViewModel } from "./customers/AddEditCustomerViewModel";
import { AddCustomerDocViewModel } from "./customers/AddCustomerDocViewModel";
import { CustomerDetailsTabViewModel } from "./customers/CustomerDetailsTabViewModel";
import { MergeCustomersViewModel } from "./customers/MergeCustomersViewModel";
import { SearchWorkflowDocsViewModel } from "./workflow/SearchWorkflowDocsViewModel";
import { DocWorkflowViewModel } from "./workflow/DocWorkflowViewModel";
import { DocumentInfoViewModel } from "./workflow/DocumentInfoViewModel";
import { DocWorkflowDetailsTabViewModel } from "./workflow/DocWorkflowDetailsTabViewModel";
import { LicenseCommunicationViewModel } from "./workflow/LicenseCommunicationViewModel";
import PageContext from "../infrastructure/PageContext";
import { AddEditCustomerTaskViewModel } from "./customers/AddEditCustomerTaskViewModel";
import { ContractSummaryViewModel } from "./agents/contracts/ContractSummaryViewModel";
import { PdfViewerViewModel } from "./agents/documents/PdfViewerViewModel";
import {
  AppMenuItems,
  DocType,
  ErrorTypes,
  MessageType,
} from "../infrastructure/enum/Common";
import { CreditCheckHistoryViewModel } from "./agents/CreditCheckHistoryViewModel";
import { AgentsDashboardViewModel } from "./agents/AgentsDashboardViewModel";
import { AdminDashboardViewModel } from "./administration/AdminDashboardViewModel";
import { TasksDashboardViewModel } from "./tasks/TasksDashboardViewModel";
import { CustomersDashboardViewModel } from "./customers/CustomersDashboardViewModel";
import { DocWorkflowDashboardViewModel } from "./workflow/DocWorkflowDashboardViewModel";
import { LeadsDashboardViewModel } from "./leads/LeadsDashboardViewModel";
import { SuppliesDashboardViewModel } from "./supplies/SuppliesDashboardViewModel";
import { Route } from "type-route";
import { AddEditMarketingEventViewModel } from "./home/AddEditMarketingEventViewModel";
import { MarketingEventDetailViewModel } from "./home/MarketingEventDetailViewModel";
import { MarketingEventsViewModel } from "./home/MarketingEventsViewModel";
import { HomeViewModel } from "./home/HomeViewModel";
import { PageTitleConstants } from "../infrastructure/enum/Constants";
import { AgentSearchByMapViewModel } from "./maps/AgentSearchByMapViewModel";
import { AutoDialerViewModel } from "./autoDialers/AutoDialerViewModel";
import { ExistingCallListViewModel } from "./autoDialers/ExistingCallListViewModel";
import { StartCallingViewModel } from "./autoDialers/StartCallingViewModel";
import { CarrierCMDashboardViewModel } from "./../modules/administration/carrierManagement/CarrierCMDashboardViewModel";
import UserContext, {
  AgentSearchUserProperties,
} from "../infrastructure/UserContext";
import Utils from "../infrastructure/Utils";
import { Permission } from "../infrastructure/enum/Permission";
import LoggedInUser from "../infrastructure/Auth0Authenticator";
import { AgentsGroupSummaryViewModel } from "./agents/AgentsGroupSummaryViewModel";
import { EmailDownloadViewModel } from "./emails/EmailDownloadViewModel";
import { GeolocationDownloadViewModel } from "./agents/geolocations/GeolocationDownloadViewModel";
import { NeedHelpViewModel } from "../views/footer/NeedHelpViewModel";
import { TermsofUseViewModel } from "../views/footer/TermsofUseViewModel";
import { googleTagManager } from "../infrastructure/tracking";
import { toast } from "react-toastify";
import { RtsReviewModel } from "../views/rtsReview/RtsReviewModel";
import { BookOfBusinessViewModel } from "../views/bookOfBusiness/BookOfBusinessViewModel";

export class AppViewModel implements ViewModel, ReloadMenusListener {
  reloadMenus(): void {
    this.getMenusBasedOnPermission();
    this.AppInlineProfileViewModel.setMenusBasedOnPermission(this.menu);
    this.getAppMenusBasedOnPermission();
    this.showCustomAppMenuItems = true;
  }
  @observable CarrierCMDashboardViewModel: CarrierCMDashboardViewModel =
    new CarrierCMDashboardViewModel();
  @observable RtsReviewModel: RtsReviewModel = new RtsReviewModel();
  @observable BookOfBusinessViewModel: BookOfBusinessViewModel =
    new BookOfBusinessViewModel();
  @observable route: Route<typeof routes> = getCurrentRoute();
  @observable webChatConfig: any;
  @observable enableChat: boolean = true;

  @observable env = Utils.getAppHostKey();

  @observable bookUrl = `https://book-${
    this.env
  }.integrityconnect.com/app?tenantName=${Utils.getBUName()} `.replace(
    "-prod",
    ""
  );

  @computed
  get CanClose(): boolean {
    if (!this.SelectedViewModel) return true;
    if (this.SelectedViewModel.CanClose) return true;
    return false;
  }
  Close(): void {
    if (this.SelectedViewModel) this.SelectedViewModel.Close();
  }
  @computed
  get IsLoaded(): boolean {
    return this.isLoading;
  }
  get IsLoading(): boolean {
    throw new Error("Method not implemented.");
  }
  @computed
  get CanRoute(): boolean {
    if (!this.SelectedViewModel) return true;
    if (this.SelectedViewModel.CanRoute) return true;
    return false;
  }

  @action setPageTitle(title: any) {
    window.document.title = `${Utils.getBUName()} - ${title}`;
  }

  @action getImpersonatingUserId = async () => {
    try {
      var result = await UserStore.getImpersonateUserList();
      if (result) {
        this.AppInlineProfileViewModel.impersonatingUsers =
          this.AppInlineProfileViewModel.mapListItemAndSort(result);
        var selectedUser = result.filter((item: any) => {
          return item.selected === true;
        });
        if (selectedUser && selectedUser.length > 0) {
          UserContext.userId = selectedUser[0].value
            ? +selectedUser[0].value
            : 0;
          this.AppInlineProfileViewModel.selectedImpersonatingUserId =
            UserContext.userId;
          await this.AppInlineProfileViewModel.setImpersonatingFirstTime(
            selectedUser[0].value || ""
          );
        }
        // used for webchat UI
        this.webChatConfig = {
          botConfig: require(`../infrastructure/WebChat/bot-config.json`),
          marketerName: LoggedInUser.displayName,
          marketerId: UserContext.userId || 0,
          authorization: await Utils.getApiKey(),
          host: Utils.getAppHostKey(),
          hostUrl: Utils.getServiceUrlByEnvironment(),
        };
        var env = Utils.getServiceUrlByEnvironment();
        console.log("selected user", selectedUser);
      }
    } catch (e) {}
  };
  @action Route = async (
    currentRoute: RouteList,
    isImpersonatingCheck?: boolean
  ): Promise<void> => {
    toast.dismiss("validation-toaster");
    Utils.removeScrollEvent(PageContext.updateHorizontalScrollBar);
    PageContext.setIsLoadingVisible(false);
    this.route = currentRoute;
    var routeParams = JSON.parse(JSON.stringify(currentRoute.params));
    IMASLog.log("current route : " + currentRoute.name);

    if (!UserContext.getUserId() || UserContext.getUserId() === 0) {
      await this.getImpersonatingUserId();
      this.getMenusBasedOnPermission();
      await this.AppInlineProfileViewModel.load(this.menu);
      this.getAppMenusBasedOnPermission();
    }

    if (currentRoute.name === routes.agentProduction.name) {
      googleTagManager.trackAction("production_viewed", {
        feature: currentRoute.name,
        level: "Any",
        user_id: UserContext.userId,
        agent_id: routeParams.agentId,
      });
    }

    if (currentRoute.name === routes.noAccess.name) {
      PageContext.setUserAccess(false);
      if (localStorage.getItem("auth0UserItem") != null) {
        let error = {
          status: 401,
          title: "Unauthorized to access",
          errors: { "": [] },
        };
        PageContext.setResponseMessage(JSON.stringify(error));
        PageContext.setIsMessageVisible(true);
      }
      return;
    }

    let hasRouteAccess = true;
    this.AppInlineProfileViewModel.routeUrl = currentRoute;
    PageContext.setIsMessageVisible(false);
    PageContext.setResponseMessage("");

    var routeParamObj = JSON.parse(JSON.stringify(currentRoute.params));
    var agentParamId: any | undefined;
    var groupParamId: any | undefined;
    this.setPageTitle("IntegrityCONNECT");

    if (currentRoute.name === routes.home.name) {
      this.menuActiveIndex = this.getMenuIndex(AppMenuItems.Home);
      this.menuActiveItem = AppMenuItems.Home;
      // if (!(this.SelectedViewModel instanceof HomeDashboardViewModel)) {
      //   this.SelectedViewModel = new HomeDashboardViewModel();
      // }
      if (!(this.SelectedViewModel instanceof HomeViewModel)) {
        // this.SelectedViewModel = new HomeViewModel();
        this.SelectedViewModel = this.HomeViewModel;
      }
      this.HomeViewModel.setAppInlineViewModel(this.AppInlineProfileViewModel);
      // setTimeout (() => {
      //   if(this.SelectedViewModel) {
      this.SelectedViewModel.Load(currentRoute.params);
      this.SelectedViewModel.Route(currentRoute);
      //   }
      // },1000)
    } else if (
      currentRoute.name === routes.addMarketingEvent.name ||
      currentRoute.name === routes.editMarketingEvent.name
    ) {
      this.menuActiveIndex = this.getMenuIndex(AppMenuItems.Home);
      this.menuActiveItem = AppMenuItems.Home;
      if (!(this.SelectedViewModel instanceof AddEditMarketingEventViewModel)) {
        this.SelectedViewModel = new AddEditMarketingEventViewModel();
      }
      await this.SelectedViewModel.Load(routeParamObj.marketingEventId);
      await this.SelectedViewModel.Route(currentRoute);
    } else if (currentRoute.name === routes.marketingEventDetail.name) {
      this.menuActiveIndex = this.getMenuIndex(AppMenuItems.Home);
      this.menuActiveItem = AppMenuItems.Home;
      if (!(this.SelectedViewModel instanceof MarketingEventDetailViewModel)) {
        this.SelectedViewModel = new MarketingEventDetailViewModel();
      }
      await this.SelectedViewModel.Load(routeParamObj.marketingEventId);
      await this.SelectedViewModel.Route(currentRoute);
    } else if (currentRoute.name === routes.listMarketingEvent.name) {
      this.menuActiveIndex = this.getMenuIndex(AppMenuItems.Home);
      this.menuActiveItem = AppMenuItems.Home;
      if (!(this.SelectedViewModel instanceof MarketingEventsViewModel)) {
        this.SelectedViewModel = new MarketingEventsViewModel();
      }
      await this.SelectedViewModel.Load(routeParamObj.marketingEventId);
      await this.SelectedViewModel.Route(currentRoute);
    } else if (
      // agents module lazy

      currentRoute.name === routes.agentSearch.name ||
      currentRoute.name === routes.addAgent.name ||
      currentRoute.name === routes.mergeAgents.name ||
      currentRoute.name === routes.mapsView.name ||
      currentRoute.name === routes.emailsView.name ||
      agentGroup.has(currentRoute) ||
      currentRoute.name === routes.editAgent.name ||
      currentRoute.name === routes.agentGroupDetails.name
    ) {
      let title =
        currentRoute.name === routes.addAgent.name
          ? PageTitleConstants.AddAgent
          : currentRoute.name === routes.editAgent.name
          ? PageTitleConstants.EditAgent
          : currentRoute.name === routes.agentSearch.name
          ? PageTitleConstants.AgentSearch
          : currentRoute.name === routes.mapsView.name
          ? PageTitleConstants.AgentMapSearch
          : currentRoute.name === routes.emailsView.name
          ? PageTitleConstants.Emails
          : PageTitleConstants.AgentDetails;
      this.setPageTitle(title);
      if (currentRoute.name !== routes.emailsView.name) {
        this.menuActiveIndex = this.getMenuIndex(AppMenuItems.Agents);
        this.menuActiveItem = AppMenuItems.Agents;
      }

      if (!(this.SelectedViewModel instanceof AgentsDashboardViewModel)) {
        this.SelectedViewModel = this.AgentsDashboardViewModel;
      }
      this.AgentsDashboardViewModel.setTopHeaderViewModel(
        this.TopHeaderSearchViewModel
      );
      this.AgentsDashboardViewModel.setAppInlineProfileViewModel(
        this.AppInlineProfileViewModel
      );
      await this.SelectedViewModel.Load(currentRoute.params);
      await this.SelectedViewModel.Route(currentRoute);

      if (
        currentRoute.name === routes.agentSearch.name &&
        !this.agentNewsSeen
      ) {
        this.agentNewsSeen = true;
      }
    } else if (
      // Admin module lazy
      administrationGroup.has(currentRoute) ||
      submissionGroup.has(currentRoute)
    ) {
      this.setPageTitle(PageTitleConstants.Administration);
      this.menuActiveIndex = this.getMenuIndex(AppMenuItems.Administration);
      this.menuActiveItem = AppMenuItems.Administration;
      if (!(this.SelectedViewModel instanceof AdminDashboardViewModel)) {
        this.SelectedViewModel = this.AdminDashboardViewModel;
      }
      await this.SelectedViewModel.Load(currentRoute.params);
      await this.SelectedViewModel.Route(currentRoute);
    }

    //Need Help
    else if (currentRoute.name === routes.needhelp.name) {
      this.setPageTitle(PageTitleConstants.NeedHelp);
      this.menuActiveIndex = this.getMenuIndex(AppMenuItems.NeedHelp);
      // this.menuActiveItem = AppMenuItems.Home;
      if (!(this.SelectedViewModel instanceof NeedHelpViewModel)) {
        this.SelectedViewModel = this.NeedHelpViewModel;
      }
      await this.SelectedViewModel.Load();
      await this.SelectedViewModel.Route(currentRoute);
    }

    //Terms of Use
    else if (currentRoute.name === routes.termsofuse.name) {
      this.setPageTitle(PageTitleConstants.TermsofUse);
      this.menuActiveIndex = this.getMenuIndex(AppMenuItems.TermsOfUse);
      // this.menuActiveItem = AppMenuItems.Home;
      if (!(this.SelectedViewModel instanceof TermsofUseViewModel)) {
        this.SelectedViewModel = this.TermsofUseViewModel;
      }
      await this.SelectedViewModel.Load();
      await this.SelectedViewModel.Route(currentRoute);
    }

    //autodialer
    else if (
      currentRoute.name === routes.autoDialer.name ||
      currentRoute.name === routes.backToAutoDialer.name
    ) {
      this.setPageTitle(PageTitleConstants.AutoDialer);
      this.menuActiveIndex = this.getMenuIndex(AppMenuItems.Dialer);
      this.menuActiveItem = AppMenuItems.Dialer;
      if (!(this.SelectedViewModel instanceof AutoDialerViewModel)) {
        this.SelectedViewModel = this.AutoDialerViewModel;
      }
      if (currentRoute.name === routes.backToAutoDialer.name) {
        await this.SelectedViewModel.Load(false);
      } else {
        await this.SelectedViewModel.Load(true);
      }
      await this.SelectedViewModel.Route(currentRoute);
    } else if (autoDialerGroup.has(currentRoute)) {
      this.setPageTitle(PageTitleConstants.AutoDialer);
      // this.menuActiveIndex = this.getMenuIndex(AppMenuItems.AutoDialer);
      // this.menuActiveItem = AppMenuItems.AutoDialer;
      var routePamParamObj = JSON.parse(JSON.stringify(currentRoute.params));

      RouteParamDetails.callListId = routePamParamObj.callListId;

      if (!(this.SelectedViewModel instanceof StartCallingViewModel)) {
        this.SelectedViewModel = this.StartCallingViewModel;
      }
      await this.SelectedViewModel.Load(RouteParamDetails.callListId);
      await this.SelectedViewModel.Route(currentRoute);
    } else if (
      // task module lazy
      currentRoute.name === routes.tasks.name ||
      currentRoute.name === routes.addMyTask.name ||
      currentRoute.name === routes.editMyTask.name
    ) {
      this.setPageTitle(PageTitleConstants.Tasks);
      this.menuActiveIndex = this.getMenuIndex(AppMenuItems.Tasks);
      this.menuActiveItem = AppMenuItems.Tasks;
      if (!(this.SelectedViewModel instanceof TasksDashboardViewModel)) {
        this.SelectedViewModel = this.TasksDashboardViewModel;
      }
      await this.SelectedViewModel.Load(currentRoute.params);
      await this.SelectedViewModel.Route(currentRoute);
      if (currentRoute.name === routes.tasks.name && !this.tasksNewsSeen) {
        this.tasksNewsSeen = true;
      }
    } else if (
      //customer module lazy load
      currentRoute.name === routes.customerSearch.name ||
      currentRoute.name === routes.addCustomer.name ||
      currentRoute.name === routes.editCustomer.name ||
      currentRoute.name === routes.mergeCustomers.name ||
      customerGroup.has(currentRoute)
    ) {
      this.setPageTitle(PageTitleConstants.Insured);
      this.menuActiveIndex = this.getMenuIndex(AppMenuItems.Insured);
      this.menuActiveItem = AppMenuItems.Insured;
      if (!(this.SelectedViewModel instanceof CustomersDashboardViewModel)) {
        this.SelectedViewModel = this.CustomersDashboardViewModel;
      }
      await this.SelectedViewModel.Load(currentRoute.params);
      await this.SelectedViewModel.Route(currentRoute);
    } else if (
      //workflow module lazy load
      currentRoute.name === routes.searchWorkflowDocs.name ||
      currentRoute.name === routes.docWorkflow.name
    ) {
      this.setPageTitle(PageTitleConstants.Workflow);
      this.menuActiveIndex = this.getMenuIndex(AppMenuItems.Workflow);
      this.menuActiveItem = AppMenuItems.Workflow;
      if (!(this.SelectedViewModel instanceof DocWorkflowDashboardViewModel)) {
        this.SelectedViewModel = this.DocWorkflowDashboardViewModel;
      }
      await this.SelectedViewModel.Load();
      await this.SelectedViewModel.Route(currentRoute);
    } else if (workflowGroup.has(currentRoute)) {
      this.setPageTitle(PageTitleConstants.Workflow);
      this.menuActiveIndex = this.getMenuIndex(AppMenuItems.Workflow);
      this.menuActiveItem = AppMenuItems.Workflow;
      routePamParamObj = JSON.parse(JSON.stringify(currentRoute.params));

      RouteParamDetails.documentId = routePamParamObj.documentId;
      RouteParamDetails.workflowStatus = routePamParamObj.workflowStatus;
      if (!(this.SelectedViewModel instanceof DocWorkflowDashboardViewModel)) {
        this.SelectedViewModel = this.DocWorkflowDashboardViewModel;
      }
      await this.SelectedViewModel.Load(
        RouteParamDetails.documentId,
        RouteParamDetails.workflowStatus
      );
      await this.SelectedViewModel.Route(currentRoute);
    } else if (
      // Leads module lazy load
      currentRoute.name === routes.leads.name ||
      currentRoute.name === routes.addAgentLead.name ||
      currentRoute.name === routes.addSaleOpsLead.name ||
      currentRoute.name === routes.editSaleOpsLead.name ||
      currentRoute.name === routes.editAgentLead.name ||
      currentRoute.name === routes.detailNonContarctedLeads.name ||
      currentRoute.name === routes.detailContarctedLeads.name ||
      currentRoute.name === routes.detailSaleOpsLeads.name ||
      currentRoute.name === routes.detailUnmatchedLeads.name ||
      currentRoute.name === routes.salesLeads.name ||
      currentRoute.name === routes.unmatchedLeads.name
    ) {
      this.setPageTitle(PageTitleConstants.Leads);
      this.menuActiveIndex = this.getMenuIndex(AppMenuItems.Leads);
      this.menuActiveItem = AppMenuItems.Leads;
      if (!(this.SelectedViewModel instanceof LeadsDashboardViewModel)) {
        this.SelectedViewModel = this.LeadsDashboardViewModel;
      }
      await this.SelectedViewModel.Load();
      await this.SelectedViewModel.Route(currentRoute);
    } else if (currentRoute.name === routes.documents.name) {
      // documents module for lazy load
      this.setPageTitle(PageTitleConstants.DocumentManagement);
      this.menuActiveIndex = this.getMenuIndex(AppMenuItems.Documents);
      this.menuActiveItem = AppMenuItems.Documents;
      if (!(this.SelectedViewModel instanceof DocumentManagementViewModel)) {
        this.SelectedViewModel = this.DocumentManagementViewModel;
      }
      await this.SelectedViewModel.Load(routeParamObj.agentId);
      await this.SelectedViewModel.Route(currentRoute);
    } else if (currentRoute.name === routes.rtsReview.name) {
      // documents module for lazy load
      this.setPageTitle(PageTitleConstants.rtsReview);
      this.menuActiveIndex = this.getMenuIndex(AppMenuItems.rtsReview);
      this.menuActiveItem = AppMenuItems.rtsReview;
      if (!(this.SelectedViewModel instanceof RtsReviewModel)) {
        this.SelectedViewModel = this.RtsReviewModel;
      }
      await this.SelectedViewModel.Load(routeParamObj.agentId);
      await this.SelectedViewModel.Route(currentRoute);
    } else if (currentRoute.name === routes.bookOfBusiness.name) {
      // documents module for lazy load
      this.setPageTitle(PageTitleConstants.bookOfBusiness);
      this.menuActiveIndex = this.getMenuIndex(AppMenuItems.bookOfBusiness);
      this.menuActiveItem = AppMenuItems.bookOfBusiness;
      if (!(this.SelectedViewModel instanceof BookOfBusinessViewModel)) {
        this.SelectedViewModel = this.BookOfBusinessViewModel;
      }
      await this.SelectedViewModel.Load(routeParamObj.agentId);
      await this.SelectedViewModel.Route(currentRoute);
    }
    // else if (currentRoute.name === routes.reports.name) {
    //   // reports module for lazy load
    //   this.setPageTitle(PageTitleConstants.Reports);
    //   this.menuActiveItem = AppMenuItems.Reports;
    //   this.menuActiveIndex = this.getMenuIndex(AppMenuItems.Reports);
    //   if (!(this.SelectedViewModel instanceof ReportsViewModel)) {
    //     this.SelectedViewModel = this.ReportsViewModel;
    //   }
    //   await this.SelectedViewModel.Load();
    //   await this.SelectedViewModel.Route(currentRoute);
    // }
    else if (reportGroup.has(currentRoute)) {
      // reports list module for lazy load
      this.setPageTitle(PageTitleConstants.Reports);
      this.menuActiveItem = AppMenuItems.Reports;
      this.menuActiveIndex = this.getMenuIndex(AppMenuItems.Reports);
      if (
        !(this.SelectedViewModel instanceof SelfServiceReportDashboardViewModel)
      ) {
        this.SelectedViewModel = this.SelfServiceReportDashboardViewModel;
      }
      await this.SelectedViewModel.Load();
      await this.SelectedViewModel.Route(currentRoute);
    } else if (
      currentRoute.name === routes.addCarrierManagement.name ||
      currentRoute.name === routes.editCarrierManagement.name ||
      currentRoute.name === routes.previewCarrierManagement.name ||
      currentRoute.name === routes.carrierManagement.name ||
      currentRoute.name === routes.carrierCMList.name ||
      currentRoute.name === routes.carrierGroupCMList.name
    ) {
      // reports list module for lazy load
      this.setPageTitle(PageTitleConstants.carrierManagement);
      this.menuActiveItem = AppMenuItems.carrierManagement;
      this.menuActiveIndex = this.getMenuIndex(AppMenuItems.carrierManagement);
      if (!(this.SelectedViewModel instanceof CarrierCMDashboardViewModel)) {
        this.SelectedViewModel = this.CarrierCMDashboardViewModel;
      }
      await this.SelectedViewModel.Load();
      await this.SelectedViewModel.Route(currentRoute);
    } else if (currentRoute.name === routes.supplies.name) {
      this.setPageTitle(PageTitleConstants.SupplyOrders);
      this.menuActiveIndex = this.getMenuIndex(AppMenuItems.Supplies);
      this.menuActiveItem = AppMenuItems.Supplies;
      if (!(this.SelectedViewModel instanceof SuppliesDashboardViewModel)) {
        this.SelectedViewModel = this.SuppliesDashboardViewModel;
      }
      await this.SelectedViewModel.Load(routeParamObj.agentId, false);
      await this.SelectedViewModel.Route(currentRoute);
    } else if (currentRoute.name === routes.suppliesOrderDetails.name) {
      this.setPageTitle(PageTitleConstants.SupplyOrders);
      this.menuActiveIndex = this.getMenuIndex(AppMenuItems.Supplies);
      this.menuActiveItem = AppMenuItems.Supplies;
      if (!(this.SelectedViewModel instanceof SuppliesDashboardViewModel)) {
        this.SelectedViewModel = this.SuppliesDashboardViewModel;
      }
      await this.SelectedViewModel.Load(routeParamObj.orderId, false);
      await this.SelectedViewModel.Route(currentRoute);
    } else if (currentRoute.name === routes.suppliesAddOrder.name) {
      this.goToSupplyOrder(currentRoute, routeParamObj);
    } else if (currentRoute.name === routes.suppliesEditOrder.name) {
      this.goToSupplyOrder(currentRoute, routeParamObj);
    }

    if (currentRoute.name === routes.agentContractSummary.name) {
      this.isContractSummary = true;
      agentParamId = routeParamObj.agentId;
      RouteParamDetails.id = +agentParamId;
      this.ContractSummaryViewModel.Load(+agentParamId);
      return;
    } else {
      this.isContractSummary = false;
    }
    if (currentRoute.name === routes.agentGroupDetails.name) {
      this.setPageTitle(PageTitleConstants.AgentGroupDetails);
      this.isGroupSummary = true;
      groupParamId = routeParamObj.id;
      RouteParamDetails.id = +groupParamId;
      this.AgentsGroupSummaryViewModel.Load(agentParamId, groupParamId);
      // this.AgentsGroupSummaryViewModel.Route(currentRoute);
      return;
    } else {
      this.isGroupSummary = false;
    }

    if (currentRoute.name === routes.agentCreditCheckHistory.name) {
      this.menuActiveIndex = this.getMenuIndex(AppMenuItems.Agents);
      this.menuActiveItem = AppMenuItems.Agents;
      if (!(this.SelectedViewModel instanceof CreditCheckHistoryViewModel)) {
        this.SelectedViewModel = this.CreditCheckHistoryViewModel;
      }
      await this.SelectedViewModel.Load(routeParamObj.agentId);
      await this.SelectedViewModel.Route(currentRoute);
    } else {
      this.isCreditCheckHistory = false;
      this.isPdfViewer = false;
      this.isSingleView = false;
    }
    if (
      currentRoute.name === routes.pdfViewer.name ||
      currentRoute.name === routes.customerDocsViewer.name ||
      currentRoute.name === routes.dmsDocsViewer.name ||
      currentRoute.name === routes.agentDocsViewer.name ||
      currentRoute.name === routes.workflowDocsViewer.name
    ) {
      this.enableChat = false;
      this.isSingleView = true;
      this.isPdfViewer = true;
      this.enableChat = false;
      agentParamId = routeParamObj.agentId;
      var docId = routeParamObj.docId;
      var typeId = routeParamObj.typeId;
      if (agentParamId) {
        RouteParamDetails.id = +agentParamId;
      }
      let docType = DocType.Document;
      if (currentRoute.name === routes.pdfViewer.name) {
        docType = DocType.Document;
      } else if (
        currentRoute.name === routes.customerDocsViewer.name ||
        currentRoute.name === routes.agentDocsViewer.name
      ) {
        docType = DocType.Customer;
      } else if (currentRoute.name === routes.dmsDocsViewer.name) {
        docType = DocType.DMS;
      } else if (currentRoute.name === routes.workflowDocsViewer.name) {
        docType = DocType.WorkFlow;
      }

      this.pdfViewerModel.Load(docId, typeId, docType);
    } else {
      this.isPdfViewer = false;
      this.isSingleView = false;
    }

    if (currentRoute.name === routes.demotest.name) {
      this.menuActiveIndex = this.getMenuIndex(AppMenuItems.UITemplate);
      this.menuActiveItem = AppMenuItems.UITemplate;
      if (!(this.SelectedViewModel instanceof DashboardViewModel)) {
        this.SelectedViewModel = this.DashboardViewModel;
      }
      await this.SelectedViewModel.Load();
      await this.SelectedViewModel.Route(currentRoute);
    }

    if (
      currentRoute.name === routes.emailDownload.name ||
      currentRoute.name === routes.emailDownloadWitoutType.name
    ) {
      var fileName = routeParamObj.fileName;
      var reportType = routeParamObj.reportType;
      if (!(this.SelectedViewModel instanceof EmailDownloadViewModel)) {
        this.SelectedViewModel = this.EmailDownloadViewModel;
      }
      if (reportType !== null && reportType !== undefined) {
        await this.SelectedViewModel.Load(fileName, reportType);
      } else {
        await this.SelectedViewModel.Load(fileName, "0");
      }
      // await this.SelectedViewModel.Route(currentRoute);
    }

    if (currentRoute.name === routes.geolocationDownload.name) {
      var zip = routeParamObj.zipcode;
      var carrier = routeParamObj.carrier;
      var distance = routeParamObj.distance;
      if (!(this.SelectedViewModel instanceof GeolocationDownloadViewModel)) {
        this.SelectedViewModel = this.GeolocationDownloadViewModel;
      }
      await this.SelectedViewModel.Load(zip, carrier, distance);
      await this.SelectedViewModel.Route(currentRoute);
    }

    if (!hasRouteAccess && isImpersonatingCheck) {
      if (!(this.SelectedViewModel instanceof ErrorViewModel)) {
        this.SelectedViewModel = new ErrorViewModel();
      }
      await this.SelectedViewModel.Load(ErrorTypes.NoAccess);
      let errorRoute = JSON.parse(JSON.stringify(currentRoute));
      errorRoute.name = routes.error.name;
      await this.SelectedViewModel.Route(errorRoute);
    }
  };
  @action goToSupplyOrder = async (currentRoute: any, routeParamObj: any) => {
    this.setPageTitle(PageTitleConstants.SupplyOrders);
    this.menuActiveIndex = this.getMenuIndex(AppMenuItems.Supplies);
    this.menuActiveItem = AppMenuItems.Supplies;
    if (!(this.SelectedViewModel instanceof SuppliesDashboardViewModel)) {
      this.SelectedViewModel = this.SuppliesDashboardViewModel;
    }
    await this.SelectedViewModel.Load(
      RouteParamDetails.id,
      routeParamObj.orderId,
      false
    );
    await this.SelectedViewModel.Route(currentRoute);
  };
  @action Load = async () => {
    this.route = getCurrentRoute();
    this.Loaded();
    //  await this.TopHeaderSearchViewModel.Load();
    try {
      var notification = JSON.parse(
        localStorage.getItem("broker-notification") || "[]"
      );
      if (notification && notification.length > 0) {
        this.AppInlineProfileViewModel.progressInfo.MessageType =
          MessageType.Toaster;
        this.AppInlineProfileViewModel.progressInfo.ToaterInfo = notification;
        this.AppInlineProfileViewModel.progressInfo.Total = notification.length;
      }
    } catch (error) {
      IMASLog.log(error);
    }
  };

  @action getMenuIndex = (menu: AppMenuItems) => {
    PageContext.setModuleName(menu);
    let finalObj = {};
    let arrayToObject = this.menu.map((item) => ({
      [item.label.replace(" ", "").replace(" ", "")]: item.label.replace(" ", "").replace(" ", ""),
    }));
    for (let i = 0; i < arrayToObject.length; i++) {
      Object.assign(finalObj, arrayToObject[i]);
    }
    if (this.showCustomAppMenuItems) {
      
      return menu
        ? Object.keys(finalObj).indexOf(
            menu.toString().replace(" ", "").replace(" ", "")
          )
        : -1;
    }
  };

  @observable showCustomAppMenuItems: boolean = false;
  @observable appMenusBasedOnPermission: any;

  @observable getAppMenusBasedOnPermission = () => {
    this.appMenusBasedOnPermission = {};
    this.appMenusBasedOnPermission.Home = AppMenuItems.Home;
    if (Utils.hasUserPermission(Permission.ViewAgent)) {
      this.appMenusBasedOnPermission.Agents = AppMenuItems.Agents;
    }
    if (Utils.hasUserPermission(Permission.ViewInsured)) {
      this.appMenusBasedOnPermission.Insured = AppMenuItems.Insured;
    }
    this.appMenusBasedOnPermission.Tasks = AppMenuItems.Tasks;
    if (Utils.hasUserPermission(Permission.ViewDocuments)) {
      this.appMenusBasedOnPermission.Documents = AppMenuItems.Documents;
    }
    if (Utils.hasUserPermission(Permission.ViewAutoDialer)) {
      this.appMenusBasedOnPermission.Dialer = AppMenuItems.Dialer;
    }

    this.appMenusBasedOnPermission.Workflow = AppMenuItems.Workflow;
    if (Utils.hasUserPermission(Permission.ViewLeads)) {
      this.appMenusBasedOnPermission.Leads = AppMenuItems.Leads;
    }
    if (Utils.hasUserPermission(Permission.ViewSupplyOrders)) {
      this.appMenusBasedOnPermission.Supplies = AppMenuItems.Supplies;
    }
    if (
      Utils.hasUserPermission(Permission.ViewStandardReports) ||
      Utils.hasUserPermission(Permission.ViewCustomReports)
    ) {
      this.appMenusBasedOnPermission.Reports = AppMenuItems.Reports;
    }
    if (Utils.hasUserPermission(Permission.ViewAdminFunctions)) {
      this.appMenusBasedOnPermission.Administration =
        AppMenuItems.Administration;
    }
  };

  @observable agentName: string | undefined = "";
  @observable customerName: string | undefined = "";
  @observable customerState: string | undefined = "";
  @observable agentId: string | undefined = "";
  @observable apiKey: string = "";
  @observable searchAgentParam: string = "";
  @observable isLoading: boolean = true;

  @observable
  HomeDashboardViewModel: HomeDashboardViewModel = new HomeDashboardViewModel();
  @observable
  AgentsDashboardViewModel: AgentsDashboardViewModel =
    new AgentsDashboardViewModel();
  @observable
  AdminDashboardViewModel: AdminDashboardViewModel =
    new AdminDashboardViewModel();
  @observable
  TasksDashboardViewModel: TasksDashboardViewModel =
    new TasksDashboardViewModel();
  @observable
  CustomersDashboardViewModel: CustomersDashboardViewModel =
    new CustomersDashboardViewModel();
  @observable
  AppInlineProfileViewModel: AppInlineProfileViewModel =
    new AppInlineProfileViewModel();
  @observable
  TopHeaderSearchViewModel: TopHeaderSearchViewModel =
    new TopHeaderSearchViewModel();
  @observable EmailDownloadViewModel: EmailDownloadViewModel =
    new EmailDownloadViewModel();
  @observable GeolocationDownloadViewModel: GeolocationDownloadViewModel =
    new GeolocationDownloadViewModel();

  @observable
  AutoDialerViewModel: AutoDialerViewModel = new AutoDialerViewModel();
  @observable
  ExistingCallListViewModel: ExistingCallListViewModel =
    new ExistingCallListViewModel();
  @observable
  StartCallingViewModel: StartCallingViewModel = new StartCallingViewModel();

  @observable
  SuppliesViewModel: AgentOrdersViewModel = new AgentOrdersViewModel();
  @observable
  SuppliesOrderDetailsViewModel: OrderDetailsViewModel =
    new OrderDetailsViewModel();
  @observable
  SuppliesAddEditOrderViewModel: AddSupplyOrderViewModel =
    new AddSupplyOrderViewModel();
  @observable LeadListViewModel: LeadListViewModel = new LeadListViewModel();
  @observable
  SalesLeadListViewModel: SalesLeadListViewModel = new SalesLeadListViewModel();
  @observable
  UnmatchedLeadListViewModel: UnmatchedLeadListViewModel =
    new UnmatchedLeadListViewModel();
  @observable
  AgentLeadAddViewModel: AgentLeadAddViewModel = new AgentLeadAddViewModel();
  @observable
  SaleOpsAgentLeadAddViewModel: SaleOpsAgentLeadAddViewModel =
    new SaleOpsAgentLeadAddViewModel();
  @observable
  SaleOpsAgentLeadEditViewModel: SaleOpsAgentLeadEditViewModel =
    new SaleOpsAgentLeadEditViewModel();
  @observable
  AgentLeadEditViewModel: AgentLeadEditViewModel = new AgentLeadEditViewModel();
  @observable
  NonContractedAgentLeadDetailViewModel: NonContractedAgentLeadDetailViewModel =
    new NonContractedAgentLeadDetailViewModel();
  @observable
  ContractedAgentLeadDetailViewModel: ContractedAgentLeadDetailViewModel =
    new ContractedAgentLeadDetailViewModel();

  @observable
  SalesOpsAgentLeadsDetailViewModel: SalesOpsAgentLeadsDetailViewModel =
    new SalesOpsAgentLeadsDetailViewModel();
  @observable
  UnmatchedLeadsDetailViewModel: UnmatchedLeadsDetailViewModel =
    new UnmatchedLeadsDetailViewModel();

  @observable DashboardViewModel: DashboardViewModel = new DashboardViewModel();
  @observable AddTaskViewModel: AddTaskViewModel = new AddTaskViewModel();
  @observable
  CustomersViewMoldel: CustomersViewModel = new CustomersViewModel();
  @observable
  AddEditPolicyViewModel: AddEditPolicyViewModel = new AddEditPolicyViewModel();
  @observable ReportsViewModel: ReportsViewModel = new ReportsViewModel();
  @observable
  SelfServiceReportDashboardViewModel: SelfServiceReportDashboardViewModel =
    new SelfServiceReportDashboardViewModel();
  @observable
  DocumentManagementViewModel: DocumentManagementViewModel =
    new DocumentManagementViewModel();
  @observable
  NeedHelpViewModel: NeedHelpViewModel = new NeedHelpViewModel();
  @observable
  TermsofUseViewModel: TermsofUseViewModel = new TermsofUseViewModel();
  @observable
  AddEditMarketingEventViewModel: AddEditMarketingEventViewModel =
    new AddEditMarketingEventViewModel();
  @observable
  MarketingEventDetailViewModel: MarketingEventDetailViewModel =
    new MarketingEventDetailViewModel();
  @observable
  MarketingEventsViewModel: MarketingEventsViewModel =
    new MarketingEventsViewModel();
  @observable HomeViewModel: HomeViewModel = new HomeViewModel();
  @observable SelectedViewModel: ViewModel | undefined;
  @observable agentNewsSeen: boolean = false;
  @observable tasksNewsSeen: boolean = false;
  @observable layoutColorMode: string = "dark";
  @observable layoutMode: string = "static";
  @observable staticMenuInactive: boolean = false;
  @observable overlayMenuActive: boolean = false;
  @observable mobileMenuActive: boolean = false;
  @observable menuClick: boolean = false;
  @observable isDesktop: boolean = window.self.innerWidth > 768;
  @observable menuActiveIndex: number | string | null = 0;
  @observable isContractSummary: boolean = false;
  @observable isSingleView: boolean = false;
  @observable isPdfViewer: boolean = false;
  @observable isMapsView: boolean = false;
  @observable isCreditCheckHistory: boolean = false;
  @observable menuActiveItem: AppMenuItems = AppMenuItems.Agents;
  @observable currentRoute: object = getCurrentRoute();
  @observable
  ContractSummaryViewModel: ContractSummaryViewModel =
    new ContractSummaryViewModel();
  @observable
  CreditCheckHistoryViewModel: CreditCheckHistoryViewModel =
    new CreditCheckHistoryViewModel();
  @observable pdfViewerModel: PdfViewerViewModel = new PdfViewerViewModel();
  @observable
  AgentSearchByMapViewModel: AgentSearchByMapViewModel =
    new AgentSearchByMapViewModel();
  @observable
  CustomerDetailsTabViewModel: CustomerDetailsTabViewModel =
    new CustomerDetailsTabViewModel();
  @observable
  AddCustomerDocViewModel: AddCustomerDocViewModel =
    new AddCustomerDocViewModel();
  @observable
  AddEditCustomerViewModel: AddEditCustomerViewModel =
    new AddEditCustomerViewModel();
  @observable
  MergeCustomersViewModel: MergeCustomersViewModel =
    new MergeCustomersViewModel();

  @observable
  SearchWorkflowDocsViewModel: SearchWorkflowDocsViewModel =
    new SearchWorkflowDocsViewModel();
  @observable
  DocWorkflowViewModel: DocWorkflowViewModel = new DocWorkflowViewModel();
  @observable
  DocumentInfoViewModel: DocumentInfoViewModel = new DocumentInfoViewModel();
  @observable
  DocWorkflowDetailsTabViewModel: DocWorkflowDetailsTabViewModel =
    new DocWorkflowDetailsTabViewModel();
  @observable
  LicenseCommunicationViewModel: LicenseCommunicationViewModel =
    new LicenseCommunicationViewModel();
  @observable
  AddEditCustomerTaskViewModel: AddEditCustomerTaskViewModel =
    new AddEditCustomerTaskViewModel();
  @observable
  DocWorkflowDashboardViewModel: DocWorkflowDashboardViewModel =
    new DocWorkflowDashboardViewModel();
  @observable
  LeadsDashboardViewModel: LeadsDashboardViewModel =
    new LeadsDashboardViewModel();
  @observable
  SuppliesDashboardViewModel: SuppliesDashboardViewModel =
    new SuppliesDashboardViewModel();
  @observable
  AgentsGroupSummaryViewModel: AgentsGroupSummaryViewModel =
    new AgentsGroupSummaryViewModel();
  @observable isGroupSummary: boolean = false;
  @observable recentProfilesDetails: any;

  @action onWrapperClick = () => {
    if (!this.menuClick) {
      this.overlayMenuActive = false;
      this.mobileMenuActive = false;
    }
    this.menuClick = false;
  };
  @observable breadCrumbMenuItems: MenuItem[] = [
    {
      label: "Agents",
      command: () => {
        // this.goBackToAgents();
      },
    },
  ];
  @action onToggleMenu = () => {
    this.menuClick = true;
    if (window.innerWidth > 768) {
      if (this.layoutMode === "overlay") {
        this.overlayMenuActive = !this.overlayMenuActive;
      } else if (this.layoutMode === "static") {
        this.staticMenuInactive = !this.staticMenuInactive;
      }
    } else {
      this.mobileMenuActive = !this.mobileMenuActive;
    }
  };

  @action onSideMenuItemClick = (
    menu: AppMenuItems,
    index: number | string
  ) => {
    this.menuActiveIndex = index;
    switch (menu) {
      case AppMenuItems.Home:
        routes.home.push();
        return;
      case AppMenuItems.Agents:
        routes.agentSearch.push();
        return;
      case AppMenuItems.bookOfBusiness:
        routes.bookOfBusiness.push();
        return;
      case AppMenuItems.rtsReview:
        routes.rtsReview.push();
        return;
      case AppMenuItems.Insured:
        routes.customerSearch.push();
        return;
      case AppMenuItems.Tasks:
        routes.tasks.push();
        return;
      case AppMenuItems.Documents:
        routes.documents.push();
        return;
      case AppMenuItems.Workflow:
        routes.docWorkflow.push();
        return;
      case AppMenuItems.Leads:
        routes.leads.push();
        return;
      case AppMenuItems.Supplies:
        routes.supplies.push();
        return;
      case AppMenuItems.carrierManagement:
        routes.carrierManagement.push();
        return;
      case AppMenuItems.Administration:
        routes.administration.push();
        return;
      case AppMenuItems.Reports:
        routes.reports.push();
        return;
      case AppMenuItems.SelfServiceReports:
        routes.reports.push();
        return;
      case AppMenuItems.Dialer:
        routes.autoDialer.push();
        return;

      case AppMenuItems.UITemplate:
        routes.demotest.push();
        return;
    }
  };
  @action Loaded = async () => {
    this.isLoading = false;
    this.agentId = "" + RouteParamDetails.id;
    this.agentName = RouteParamDetails.firstName;
    this.customerName = RouteParamDetails.customerName;
    this.customerState = RouteParamDetails.customerState;
    this.AppInlineProfileViewModel.setMenuListener(this);
    // if (!UserContext.getUserId() || UserContext.getUserId() === 0) {
    //   await this.getImpersonatingUserId();
    // } else {
    //   await this.AppInlineProfileViewModel.callImpersonating()
    // }
    this.AppInlineProfileViewModel.routefn = this.Route;
    this.getMenusBasedOnPermission();
    await this.AppInlineProfileViewModel.load(this.menu);
    this.getAppMenusBasedOnPermission();
    this.getRecentProfiles(UserContext.userId || 0);
    // if (this.route.name === routes.home.name) {
    //   this.HomeViewModel.Load();
    // }
  };

  @action getRecentProfiles = async (userId: number) => {
    if (userId != 0)
      this.recentProfilesDetails = await UserStore.getUserRecentActivities(
        userId
      );
  };

  @action getMenusBasedOnPermission = () => {
    let home = {
      label: AppMenuItems.Home,
      url: routes.home.href(),
      icon: "menu-home",
      command: () => {
        this.onSideMenuItemClick(
          AppMenuItems.Home,
          this.getMenuIndex(AppMenuItems.Home)
        );
      },
    };
    let agents = {
      label: AppMenuItems.Agents,
      url: routes.agentSearch.href(),
      icon: "menu-agents",
      command: () => {
        this.onSideMenuItemClick(
          AppMenuItems.Agents,
          this.getMenuIndex(AppMenuItems.Agents)
        );
      },
    };
    let insured = {
      label: AppMenuItems.Insured,
      url: routes.customerSearch.href(),
      icon: "menu-insured",
      command: () => {
        this.onSideMenuItemClick(
          AppMenuItems.Insured,
          this.getMenuIndex(AppMenuItems.Insured)
        );
      },
    };
    let tasks = {
      label: AppMenuItems.Tasks,
      icon: "menu-tasks",
      url: routes.tasks.href(),
      command: () => {
        this.onSideMenuItemClick(
          AppMenuItems.Tasks,
          this.getMenuIndex(AppMenuItems.Tasks)
        );
      },
    };
    let docs = {
      label: AppMenuItems.Documents,
      url: routes.documents.href(),
      icon: "menu-documents",
      command: () => {
        this.onSideMenuItemClick(
          AppMenuItems.Documents,
          this.getMenuIndex(AppMenuItems.Documents)
        );
      },
    };
    let autodialer = {
      label: AppMenuItems.Dialer,
      url: routes.autoDialer.href(),
      icon: "menu-dialer",
      command: () => {
        this.onSideMenuItemClick(
          AppMenuItems.Dialer,
          this.getMenuIndex(AppMenuItems.Dialer)
        );
      },
    };
    let workflow = {
      label: AppMenuItems.Workflow,
      url: routes.docWorkflow.href(),
      icon: "menu-workflow",
      command: () => {
        this.onSideMenuItemClick(
          AppMenuItems.Workflow,
          this.getMenuIndex(AppMenuItems.Workflow)
        );
      },
    };
    let leads = {
      label: AppMenuItems.Leads,
      url: routes.leads.href(),
      icon: "menu-leads",
      command: () => {
        this.onSideMenuItemClick(
          AppMenuItems.Leads,
          this.getMenuIndex(AppMenuItems.Leads)
        );
      },
    };
    let supplies = {
      label: AppMenuItems.Supplies,
      url: routes.supplies.href(),
      icon: "menu-supplies",
      command: () => {
        this.onSideMenuItemClick(
          AppMenuItems.Supplies,
          this.getMenuIndex(AppMenuItems.Supplies)
        );
      },
    };
    let selfServiceReports = {
      label: AppMenuItems.SelfServiceReports,
      url: routes.reports.href(),
      icon: "menu-reports",
      command: () => {
        this.onSideMenuItemClick(
          AppMenuItems.SelfServiceReports,
          this.getMenuIndex(AppMenuItems.SelfServiceReports)
        );
      },
    };
    let reports = {
      label: AppMenuItems.Reports,
      url: routes.reports.href(),
      icon: "menu-reports",
      command: () => {
        this.onSideMenuItemClick(
          AppMenuItems.Reports,
          this.getMenuIndex(AppMenuItems.Reports)
        );
      },
    };
    let Carriers = {
      label: AppMenuItems.carrierManagement,
      url: routes.carrierManagement.href(),
      icon: "carrier-management-icon",
      command: () => {
        this.onSideMenuItemClick(
          AppMenuItems.carrierManagement,
          this.getMenuIndex(AppMenuItems.carrierManagement)
        );
      },
    };
    let bookOfBusiness = {
      label: AppMenuItems.bookOfBusiness,
      url: routes.bookOfBusiness.href(),
      icon: "menu-bob",
      command: () => {
        localStorage.setItem("tenantName", "DEV2");
        this.onSideMenuItemClick(
          AppMenuItems.bookOfBusiness,
          this.getMenuIndex(AppMenuItems.bookOfBusiness)
        );
      },
    };
    let rtsReview = {
      label: AppMenuItems.rtsReview,
      url: routes.rtsReview.href(),
      icon: "menu-rts-review",
      command: () => {
        this.onSideMenuItemClick(
          AppMenuItems.rtsReview,
          this.getMenuIndex(AppMenuItems.rtsReview)
        );
      },
    };
    let admin = {
      label: AppMenuItems.Administration,
      url: routes.administration.href(),
      icon: "menu-administration",
      command: () => {
        this.onSideMenuItemClick(
          AppMenuItems.Administration,
          this.getMenuIndex(AppMenuItems.Administration)
        );
      },
    };
    this.menu = [];
    this.menu.push(home);
    Utils.hasUserPermission(Permission.ViewAgent) && this.menu.push(agents);
    Utils.hasUserPermission(Permission.ViewBookOfBusiness) &&
      this.menu.push(bookOfBusiness);
    Utils.hasUserPermission(Permission.EditAgentContract) &&
      Utils.hasUserPermission(Permission.ViewContractTabOnAgentScreen) &&
      Utils.hasUserPermission(Permission.ViewRTSReview) &&
      this.menu.push(rtsReview);
    Utils.hasUserPermission(Permission.ViewInsured) && this.menu.push(insured);
    //!UserContext.userHasDownlineAgency() && this.menu.push(tasks);
    Utils.hasUserPermission(Permission.ViewTasks) && this.menu.push(tasks);
    Utils.hasUserPermission(Permission.ViewDocuments) && this.menu.push(docs);
    Utils.hasUserPermission(Permission.ViewAutoDialer) &&
      this.menu.push(autodialer);
    Utils.hasUserPermission(Permission.ViewWorkflow) &&
      this.menu.push(workflow);
    //!UserContext.userHasDownlineAgency() && this.menu.push(workflow);
    Utils.hasUserPermission(Permission.ViewLeads) && this.menu.push(leads);
    Utils.hasUserPermission(Permission.ViewSupplyOrders) &&
      this.menu.push(supplies);
    (Utils.hasUserPermission(Permission.ViewStandardReports) ||
      Utils.hasUserPermission(Permission.ViewCustomReports)) &&
      this.menu.push(reports);
    // Utils.hasUserPermission(Permission.ViewReports) && this.menu.push(reports);
    Utils.hasUserPermission(Permission.ViewCarrierManagement) &&
      this.menu.push(Carriers);
    Utils.hasUserPermission(Permission.ViewAdminFunctions) &&
      this.menu.push(admin);
  };
  @observable menu: any = [];
  @observable type: string = "6";
  @computed get getMenu() {
    return this.AppInlineProfileViewModel.userMenus;
  }

  @computed get getImpersonatedUsers() {
    return this.AppInlineProfileViewModel.impersonatingUsers;
  }
  @computed get getselectedImpersonatingUserId() {
    this.getRecentProfiles(
      this.AppInlineProfileViewModel.selectedImpersonatingUserId
    );
    return this.AppInlineProfileViewModel.selectedImpersonatingUserId
      ? this.AppInlineProfileViewModel.selectedImpersonatingUserId.toString()
      : "0";
  }

  @computed get getAgentSearchUserProperties() {
    let host = Utils.getHost();
    var userId = UserContext.getUserId();
    const agentSearchUserProperties = localStorage.getItem(
      "agentSearchUserProperties"
    );
    if (agentSearchUserProperties) {
      const userprop: AgentSearchUserProperties = JSON.parse(
        agentSearchUserProperties
      );
      if (host == userprop.buName && userprop.userId == userId) {
        this.type = userprop.searchHeaderType || "6";
      }
    }
    return this.type;
  }
  @computed get getNotifications() {
    return {
      classNameNotif: this.AppInlineProfileViewModel.notificatioClassName,
      notifications: this.AppInlineProfileViewModel.notifications,
      progressInfo: this.AppInlineProfileViewModel.progressInfo,
      smsNotifications: this.AppInlineProfileViewModel.smsNotifications,
      closeClick: (index: number) =>
        this.AppInlineProfileViewModel.closeNotificationBaseOnIndex(index),
      closeSMSClick: (id: string) =>
        this.AppInlineProfileViewModel.closeSMSNotification(id),
      //below two are notifications requested to be added in bell icon notification
      user: this.AppInlineProfileViewModel.assignedToUserDocumentCount,
      userTeam: this.AppInlineProfileViewModel.assignedToUserTeamDocumentCount,
      smsListner: this.AppInlineProfileViewModel.smsListner,
    };
  }
}
