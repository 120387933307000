import { action, observable } from "mobx";
import ViewModel from "../../infrastructure/ViewModel";
import RouteList from "../../infrastructure/RouteList";
import Collection from "../../infrastructure/CollectionHelper";
import ProducerStore from "../../store/ProducerStore";
import Utils from "../../infrastructure/Utils";
import {
  MarketingEventDetailModel,
  MarketingEventListDisplayView,
} from "../../services/ProducerService";
import { AbstractValidator } from "fluent-ts-validator";
import { ErrorModel } from "../../infrastructure/ErrorModel";
import { routes } from "../../router";
import {
  AddEditMarketingEventListViewModel,
  ListListener,
} from "./AddEditMarketingEventListViewModel";
import IMASLog from "../../infrastructure/IMASLog";

import { AddEmailsViewModel, EmailListListener } from "./AddEmailsViewModel";
import { Constants } from "../../infrastructure/enum/Constants";

export class AddEditMarketingEventViewModel
  implements ViewModel, ListListener, EmailListListener {
  get CanClose(): boolean {
    throw new Error("Method not implemented.");
  }
  Close(): void {
    throw new Error("Method not implemented.");
  }
  get IsLoaded(): boolean {
    throw new Error("Method not implemented.");
  }
  get IsLoading(): boolean {
    throw new Error("Method not implemented.");
  }
  get CanRoute(): boolean {
    return true;
  }
  @observable minDate: Date = new Date();

  @action DefaultDate = () => {
    return new Date(
      new Date().getFullYear(),
      new Date().getMonth() - 6,
      new Date().getDate()
    )
  }

  @action Load = async (id?: number) => {
    this.isLoading = true
    this.saveButtonDisabled = false
    this.minDate = this.DefaultDate()
    this.reset();
    this.AddEditMarketingEventListViewModel.setListListener(this);
    this.AddEmailsViewModel.setListListener(this);
    if (id) {
      this.isEdit = true;
      this.id = id;
      this.title = "Edit Marketing Event";
      await this.getAllLookups();
      await this.getValues();
      await this.getEventList();
    } else {
      this.isEdit = false;
      this.title = "Add Marketing Event";
      await this.getAllLookups();
    }
    this.isLoading = false
  };
  @action Loaded = () => { };

  @observable
  AddEditMarketingEventListViewModel: AddEditMarketingEventListViewModel = new AddEditMarketingEventListViewModel();
  @observable AddEmailsViewModel: AddEmailsViewModel = new AddEmailsViewModel();
  @observable MrktgEventGroupList = new Collection<{
    label: string;
    value: string;
  }>();
  @observable typeList = new Collection<{
    label: string;
    value: string;
  }>();
  @observable formatList = new Collection<{
    label: string;
    value: string;
  }>();
  @observable ownerList = new Collection<{
    label: string;
    value: string;
  }>();
  @observable requestorList = new Collection<{
    label: string;
    value: string;
  }>();
  @observable companyList = new Collection<{
    label: string;
    value: string;
  }>();
  @observable productLineList = new Collection<{
    label: string;
    value: string;
  }>();
  @observable id: number | undefined = undefined;
  @observable isLoading: boolean = true;
  @observable isEdit: boolean = false;
  @observable title: string = "Add Marketing Event";
  @observable addTitle: string = "";
  @observable mrktgEventGroup: string = "";
  @observable subjectLine: string = "";
  @observable fromEmailAddress: string = "";
  @observable fromDisplayName: string = "";
  @observable type: string = "";
  @observable description: string = "";
  @observable format: string = "";
  @observable owner: string = "";
  @observable requestor: string = "";
  @observable company: string = "";
  @observable productLine: string = "";
  @observable startTime: Date | undefined = undefined;
  @observable endTime: Date | undefined = undefined;
  @observable url: string = "";
  @observable audience: string = "";
  @observable audienceCount: string = "0";
  @observable purchasedLead: boolean = false;
  @observable validationErrorDateTime: boolean = false;

  @observable first: number = 0;
  @observable startIndex: number = 0;
  @observable rows: number = 10;
  @observable sortColumn: string = "id";
  @observable sortAscending: boolean = false;
  @observable totalRecords: number | undefined = 0;
  @observable isDeleteEventConfirm: boolean = false;
  @observable isDeleteEventListConfirm: boolean = false;
  @observable isRemoveAllEmailsConfirm: boolean = false;
  @observable selectedEventListId: number = 0;
  @observable saveButtonDisabled: boolean = false;
  @observable eventList = new Collection<MarketingEventListDisplayView>();
  @observable isSubjectLineValid: boolean = false;
  @observable isFromEmailAddressValid: boolean = false;
  Route = async (currentRoute: RouteList): Promise<void> => { };

  @action addEditListListener(): void {
    this.getEventList();
  }
  @action cancelAddEditListListener(): void {
    this.setAddEditMarketingEventListVisibility(false, false);
  }

  @action addEmailListListener(): void {
    this.getEventList();
  }
  @action cancelEmailListListener(): void {
    this.setEmailListVisibility(false);
  }

  @action reset = () => {
    this.first = 0;
    this.rows = 10;
    this.startIndex = 0;
    this.sortColumn = "id";
    this.sortAscending = false;
    this.totalRecords = 0;
    this.eventList.values = [];
    this.isLoading = false;
    this.addTitle = "";
    this.description = "";
    this.mrktgEventGroup = "";
    this.subjectLine = "";
    this.fromEmailAddress = "";
    this.fromDisplayName = "";
    this.type = "";
    this.format = "";
    this.owner = "";
    this.requestor = "";
    this.company = "";
    this.productLine = "";
    this.startTime = undefined;
    this.endTime = undefined;
    this.url = "";
    this.audience = "";
    this.audienceCount = "0";
    this.purchasedLead = false;
    this.validationErrorDateTime = false;
    this.selectedEventListId = 0;
    this.isDeleteEventConfirm = false;
    this.isDeleteEventListConfirm = false;
    this.saveButtonDisabled = false;
    this.isSubjectLineValid = false;
    this.isFromEmailAddressValid = false;
  };
  @action validateURL(input: string) {
    let pattern = new RegExp(
      "^(https?:\\/\\/)?" + // protocol
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
      "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
      "(\\#[-a-z\\d_]*)?$",
      "i"
    ); // fragment locator
    return !!pattern.test(input);
  }

  @observable isAddEditMarketingEventListVisible: boolean = false;
  @observable dialogTitle: string = "";

  @observable isEmailListVisible: boolean = false;

  @action setEmailListVisibility = (visibility: boolean) => {
    this.isEmailListVisible = visibility;
    if (!visibility) this.resetEmailList();
  };

  @action setAddEditMarketingEventListVisibility = (
    visibility: boolean,
    isEdit: boolean,
    id?: number
  ) => {
    if (isEdit) {
      //title
      this.dialogTitle = "Edit Marketing Event List";
      this.AddEditMarketingEventListViewModel.Load(id);
    } else {
      //title
      this.dialogTitle = "Add Marketing Event List";
    }
    this.isAddEditMarketingEventListVisible = visibility;
    if (!visibility) this.resetAddEditList();
  };

  @action resetAddEditList = () => {
    let time = new Date().setDate(new Date().getDate() + 1)
    this.AddEditMarketingEventListViewModel.addListTitle = "";
    this.AddEditMarketingEventListViewModel.startTime = new Date(time);
    this.AddEditMarketingEventListViewModel.endTime = new Date(time);
    this.AddEditMarketingEventListViewModel.ResetValidate();
  };

  @action resetEmailList = () => {
    this.AddEmailsViewModel.emailList = "";
    this.AddEmailsViewModel.ResetValidate();
  };

  @action setMrktgEventGroup = (value: string) => {
    this.mrktgEventGroup = value;
  };
  @action setAddTitle = (value: string) => {
    this.addTitle = value;
    this.saveButtonDisabled = false;
  };
  @action setSubjectLine = (value: string) => {
    this.subjectLine = value;
  }
  @action setFromEmailAddress = (value: string) => {
      this.fromEmailAddress = value;
      this.saveButtonDisabled = false;
  }
  @action setFromDisplayName = (value: string) => {
      this.fromDisplayName = value;
      this.saveButtonDisabled = false;
  }
  @action setDescription = (value: string) => {
    this.description = value;
    this.saveButtonDisabled = false;
  };
  @action setType = (value: string) => {
    this.type = value;
  };
  @action setFormat = (value: string) => {
    this.format = value;
  };

  @action setOwner = (value: string) => {
    this.owner = value;
  };
  @action setRequestor = (value: string) => {
    this.requestor = value;
  };
  @action setCompany = (value: string) => {
    this.company = value;
  };
  @action setProductLine = (value: string) => {
    this.productLine = value;
  };
  @action setStartTime = (value: Date) => {
    this.startTime = value;
  };
  @action setEndTime = (value: Date) => {
    this.endTime = value;
  };
  @action setUrl = (value: string) => {
    this.url = value;
    this.saveButtonDisabled = false;
  };
  @action setAudience = (value: string) => {
    this.audience = value;
    this.saveButtonDisabled = false;
  };
  @action setAudienceCount = (value: string) => {
    this.audienceCount = value;
    this.saveButtonDisabled = false;
    // Utils.formatNum(this.audienceCount)
  };

  @action setPurchasedLead = (value: boolean) => {
    this.purchasedLead = value;
  };

  @action setSortOrder() {
    this.sortAscending = !this.sortAscending;
    this.startIndex = 0;
    this.first = 0;
  }
  @action setSortColumn(column: string) {
    this.sortColumn = column;
  }

  @action onPage = async (firstIndex: number, rows: number) => {
    this.first = firstIndex;
    this.rows = rows;
    this.startIndex = firstIndex / this.rows;
    await this.getEventList();
  };

  @action noRecordsFound() {
    this.totalRecords = 0;
    this.eventList.values = [];
  }

  @action getValues = async () => {
    try {
      this.isLoading = true;
      let result = await ProducerStore.getMarketingEventById(
        this.id ? this.id : 0
      );
      if (result) {
        let response: MarketingEventDetailModel = result;
        this.addTitle = response.title ? response.title : "";
        this.subjectLine = response.emailSubject ? response.emailSubject : "";
        this.fromEmailAddress = response.fromEmailAddress ? response.fromEmailAddress : "";
        this.fromDisplayName = response.fromDisplayName ? response.fromDisplayName : "";
        this.description = response.description ? response.description : "";
        this.mrktgEventGroup = response.longRunningMarketingEventGroupId
          ? response.longRunningMarketingEventGroupId.toString()
          : "0";
        this.type = response.marketingEventTypeId
          ? response.marketingEventTypeId.toString()
          : "";
        this.format = response.marketingFormatId
          ? response.marketingFormatId.toString()
          : "";
        this.owner = response.ownerId ? response.ownerId.toString() : "";
        this.requestor = response.requestorId
          ? response.requestorId.toString()
          : "";
        this.company = response.companyId ? response.companyId.toString() : "0";
        this.productLine = response.productLineId
          ? response.productLineId.toString()
          : "0";
        this.purchasedLead = response.purchasedLead
          ? response.purchasedLead
          : false;

        this.startTime = new Date(
          Utils.getDateAndTime(response.dateStart, response.timeStart)
        );
        this.endTime = new Date(
          Utils.getDateAndTime(response.dateStop, response.timeStop)
        );
        this.url = response.url ? response.url : "";
        this.audience = response.audience ? response.audience : ''
        this.audienceCount = response.audienceSize ? response.audienceSize.toString() : "0"
      }
      this.isLoading = false;
    } catch (e) { }
    this.isLoading = false;
  };

  @action getEventList = async () => {
    this.isLoading = true;
    try {
      let id = this.id ? this.id : 0;
      let result = await ProducerStore.getMarketingEventListByEventId(
        id,
        this.first,
        this.rows,
        this.sortColumn,
        this.sortAscending
      );

      if (result.recordCount) {
        this.totalRecords = result.recordCount;
        if (result.data) {
          this.eventList.values = result.data;
        } else {
          this.eventList.values = [];
        }
      } else {
        this.noRecordsFound();
      }
      this.isLoading = false;
    } catch (e) {
      this.isLoading = false;
    }
  };

  @action getAllLookups = async () => {
    this.isLoading = true;
    try {
      let result = await ProducerStore.getmarketingeventlookup();
      if (result) {
        let list = result.longRunningMarketingEventGroups;
        let defaultItem = { label: "None", value: "0" };
        if (list) {
          this.MrktgEventGroupList.values = [];

          list.forEach((obj: any) => {
            var data1 = {
              label: obj.text ? obj.text : "",
              value: obj.value ? obj.value.toString() : "",
            };

            this.MrktgEventGroupList.values.push(data1);
          });

          this.MrktgEventGroupList.values.splice(0, 0, defaultItem);
          this.mrktgEventGroup = "0";
        } else {
          this.MrktgEventGroupList.values = [];
          this.MrktgEventGroupList.values.splice(0, 0, defaultItem);
          this.mrktgEventGroup = "0";
        }

        let types = result.types;
        if (types) {
          this.typeList.values = [];
          types.forEach((obj: any) => {
            var data2 = {
              label: obj.text ? obj.text : "",
              value: obj.value ? obj.value.toString() : "",
            };

            this.typeList.values.push(data2);

            this.type = this.typeList.values.length > 0 ? "1" : "";
          });
        } else {
          this.typeList.values = [];
        }
        let format = result.formats;
        if (format) {
          this.formatList.values = [];
          format.forEach((obj: any) => {
            var data3 = {
              label: obj.text ? obj.text : "",
              value: obj.value ? obj.value.toString() : "",
            };

            this.formatList.values.push(data3);
            this.format = this.formatList.values.length > 0 ? "1" : "";
          });
        } else {
          this.formatList.values = [];
        }

        let owner = result.owners;
        if (owner) {
          this.ownerList.values = [];
          owner.forEach((obj: any) => {
            var data4 = {
              label: obj.text ? obj.text : "",
              value: obj.value ? obj.value.toString() : "",
            };

            this.ownerList.values.push(data4);
            this.owner = this.ownerList.values.length > 0 ? "97" : "";
          });
        } else {
          this.ownerList.values = [];
        }

        let requestor = result.requestors;
        if (requestor) {
          this.requestorList.values = [];
          requestor.forEach((obj: any) => {
            var data5 = {
              label: obj.text ? obj.text : "",
              value: obj.value ? obj.value.toString() : "",
            };

            this.requestorList.values.push(data5);
            this.requestor = this.requestorList.values.length > 0 ? "97" : "";
          });
        } else {
          this.requestorList.values = [];
        }

        let company = result.companies;
        if (company) {
          this.companyList.values = [];
          company.forEach((obj: any) => {
            var data6 = {
              label: obj.text ? obj.text : "",
              value: obj.value ? obj.value.toString() : "",
            };

            this.companyList.values.push(data6);
          });
          this.companyList.values.splice(0, 0, { label: "All", value: "0" });
          this.company = "0";
        } else {
          this.companyList.values = [];
          this.companyList.values.splice(0, 0, { label: "All", value: "0" });
          this.company = "0";
        }

        let productLines = result.productLines;
        if (productLines) {
          this.productLineList.values = [];
          productLines.forEach((obj: any) => {
            var data7 = {
              label: obj.text ? obj.text : "",
              value: obj.value ? obj.value.toString() : "",
            };

            this.productLineList.values.push(data7);
          });
          this.productLineList.values.splice(0, 0, {
            label: "All",
            value: "0",
          });
          this.productLine = "0";
        } else {
          this.productLineList.values = [];
          this.productLineList.values.splice(0, 0, {
            label: "All",
            value: "0",
          });
          this.productLine = "0";
        }

        this.isLoading = false;
      }
    } catch (e) {
      this.isLoading = false;
    }
  };

  @action chkTime = (time: any) => {
    let chk = time ? time.split(":")[0].length : undefined;
    if (chk === 1) {
      let temp = time.split(":");
      temp[0] = "0".concat(temp[0]);
      temp = temp[0].concat(":", temp[1]);
      return temp;
    }
    return time;
  };

  @action createDto = () => {
    let startDate = this.startTime
      ? Utils.getDateInFormat(this.startTime).toString()
      : undefined;
    let endDate = this.endTime
      ? Utils.getDateInFormat(this.endTime).toString()
      : undefined;
    let startTime = this.startTime
      ? Utils.getTimeInFormat(this.startTime).toString()
      : undefined;
    let endTime = this.endTime
      ? Utils.getTimeInFormat(this.endTime).toString()
      : undefined;
    return {
      id: this.isEdit ? this.id : 0,
      marketingEventTypeId: this.type ? Number(this.type) : 0,
      marketingFormatId: this.format ? Number(this.format) : 0,
      ownerId: this.owner ? Number(this.owner) : 0,
      companyId: this.company ? Number(this.company) : 0,
      // divisionId?: number,
      dateStart: startDate,
      dateStop: endDate,
      timeStart: this.chkTime(startTime),
      timeStop: this.chkTime(endTime),
      title: this.addTitle ? this.addTitle.trim() : undefined,
      description: this.description ? this.description.trim() : undefined,
      url: this.url ? this.url.trim() : undefined,
      audience: this.audience ? this.audience.trim() : undefined,
      requestorId: this.requestor ? Number(this.requestor) : undefined,
      audienceSize: this.audienceCount ? Number(this.audienceCount.split(",").join("")) : undefined,
      purchasedLead: this.purchasedLead ? this.purchasedLead : undefined,
      emailSubject: this.subjectLine ? this.subjectLine.trim() : undefined,
      fromEmailAddress: this.fromEmailAddress ? this.fromEmailAddress.trim() : undefined,
      fromDisplayName: this.fromDisplayName ? this.fromDisplayName.trim() : undefined,
      // replyTo?: string | undefined,
      longRunningMarketingEventGroupId: this.mrktgEventGroup
        ? Number(this.mrktgEventGroup)
        : undefined,
      productLineId: this.productLine ? Number(this.productLine) : 0,
      displayStartDate: new Date(),
    };
  };
  @action getValidationForDateTime = () => {
    let end = this.endTime ? this.endTime : 0;
    let start = this.startTime ? this.startTime : 0;
    let dateTimeChck = start > end;
    if (dateTimeChck) this.validationErrorDateTime = true;
    else this.validationErrorDateTime = false;
    return dateTimeChck;
  };

  @action onDeleteEventClick = async () => {
    try {
      let id = this.id ? this.id : 0;
      await ProducerStore.deleteMarketingEvent(id);
      setTimeout(async () => {
        this.setDeleteEventConfirmation(false);
        routes.listMarketingEvent.push({});
      }, Utils.timeDelay_Delete());
    } catch (error) {
      IMASLog.log("exception ..deleting debt");
    }
  };
  @action onDeleteEventListClick = async () => {
    this.isLoading = true;
    try {
      let id = this.selectedEventListId;
      await ProducerStore.deletMarketingEventList(id);
      setTimeout(async () => {
        this.setDeleteEventListConfirmation(false);
        await this.getEventList();
      }, Utils.timeDelay_Delete());

    } catch (error) {
      IMASLog.log("exception ..deleting debt");
      this.isLoading = false;
      window.scrollTo(0, 0);
      this.setDeleteEventListConfirmation(false);
    }
    this.isLoading = false
  };

  @action onRemoveAllEmailsClick = async () => {
    try {
      this.isLoading = true
      let id = this.selectedEventListId;
      await ProducerStore.deleteMarketingEventEmail(id);
      setTimeout(async () => {
        this.setRemoveAllEmailsConfirmation(false);
        await this.getEventList();
      }, Utils.timeDelay_Delete());
      this.isLoading = false
    } catch (error) {
      IMASLog.log("exception ..deleting debt");
      this.isLoading = false
    }
  };

  @action setSelectedEventListId = (value: number) => {
    this.selectedEventListId = value;
  };

  @action setDeleteEventConfirmation(value: boolean) {
    this.isDeleteEventConfirm = value;
  }
  @action setDeleteEventListConfirmation(value: boolean) {
    this.isDeleteEventListConfirm = value;
  }
  @action setRemoveAllEmailsConfirmation(value: boolean) {
    this.isRemoveAllEmailsConfirm = value;
  }
  @action save = async () => {
    await this.submit();
  };
  @action submit = async () => {
    var isValid = true;
    await this.ResetValidate();
    this.isSubjectLineValid =false;
    this.isFromEmailAddressValid =false;
    try {
      if (!(await this.Validate()) && !this.getValidationForDateTime()) {
        if (this.isEdit) {
          if (this.subjectLine === null || this.subjectLine === '') {
            isValid = false;
            this.isSubjectLineValid = true;
          }
          if (this.fromEmailAddress === null || this.fromEmailAddress === '') {
            isValid = false;
            this.isFromEmailAddressValid = true;
          }
          if (!isValid) {
            return false;
          }
        }
        if (isValid) {
          this.saveButtonDisabled = true;
          this.isLoading = true
          let dto = this.createDto();
          let add: any;
          if (this.isEdit) {
            add = await ProducerStore.updateMarketingEvent(dto);
            this.isLoading = false
          } else {
            add = await ProducerStore.addMarketingEvent(dto);
            this.isLoading = false
          }

          this.reset();
          setTimeout(() => {
            routes.marketingEventDetail.push({
              marketingEventId: add ? add : this.id,
            });
          }, Utils.timeDelay_Success());

        }
      }
    } catch (e) { }
  };

  public ErrorModel = new ErrorModel(new AddEditMarketingEventValidator());
  @action
  public Validate = async () => {
    return this.ErrorModel.Validate(this);
  };

  @action
  public ResetValidate = async () => {
    return this.ErrorModel.ResetValidation(this);
  };
}

class AddEditMarketingEventValidator extends AbstractValidator<AddEditMarketingEventViewModel> {
  public constructor() {
    super();
    this.validateIfString((input) => input.addTitle)
      .isNotEmpty()
      .hasMinLength(5)
      .withFailureMessage("Title should be 5 characters or more");
       this.validateIfString((input) => input.addTitle)
            .hasMaxLength(150)
            .isNotEmpty()
            .withFailureMessage("Title should not be greater than 150 characters")
    this.validateIfDate((input) => input.startTime)
      .isNotEmpty()
      // .isSameOrBefore(new Date())
      .withFailureMessage("Start date time is required");
    this.validateIfDate((input) => input.endTime)
      .isNotEmpty()
      // .isSameOrAfter(new Date())
      .withFailureMessage("End date time is required");
      
    this.validateIfString((input) => input.url)
      .isUrl()
      .whenNotEmpty()
      .withFailureMessage("Please enter valid URL");

    this.validateIfString((input) => input.fromEmailAddress)
      .isEmail()
      .whenNotEmpty()
      .withFailureMessage("Please enter valid email");
  }
}
