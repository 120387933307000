import { observable, action, toJS } from "mobx";
import ProducerStore from "../../store/ProducerStore";
import ViewModel from "../../infrastructure/ViewModel";
import Collection from "../../infrastructure/CollectionHelper";
import RouteList from "../../infrastructure/RouteList";
import { AgentNoteListModel } from "../../services/ProducerService";
import IMASLog from "../../infrastructure/IMASLog";
import Utils from "../../infrastructure/Utils";
import { Permission } from "../../infrastructure/enum/Permission";
import RouteParamDetails from "../../infrastructure/RouteParamDetails";
import PageContext from "../../infrastructure/PageContext";
import ContractsStore from "../../store/ContractsStore";
import { AddNoteViewModel } from "./AddNoteViewModel";
import DocumentStore from "../../store/DocumentStore";
import TaskStore from "../../store/TaskStore";
import { CreateUpdateTaskModel } from "../../services/TaskService";
import UserStore from "../../store/IdentityStore";
import { AppInlineProfileViewModel } from "../AppInlineProfileViewModel";
import { ErrorModel } from "../../infrastructure/ErrorModel";
import { AbstractValidator } from "fluent-ts-validator";
import { CustomerLookupListModelTask } from "./AgentContractsViewModel";
import InsuredStore from "../../store/InsuredStore";
import { AgentListModel } from "../../services/ProducerSearchService";
import ProducerSearchStore from "../../store/ProducerSearchStore";
import { object, string } from "prop-types";

export class AgentNotesViewModel implements ViewModel {
  get CanClose(): boolean {
    throw new Error("Method not implemented.");
  }
  get CanRoute(): boolean {
    throw new Error("Method not implemented.");
  }
  get IsLoaded(): boolean {
    throw new Error("Method not implemented.");
  }
  get IsLoading(): boolean {
    throw new Error("Method not implemented.");
  }
  Close(): void {
    throw new Error("Method not implemented.");
  }
  @action Load = async () => {};
  Route = async (currentRoute: RouteList): Promise<void> => {};
  public ErrorModel = new ErrorModel(new AddressValidator());
  static Symbol: symbol = Symbol("AgentNotesViewModel");

  @observable expanded: string | boolean = false;
  @observable rows: number = 25;
  @observable totalRecords: number = 0;
  @observable first: number = 0;
  @observable isCollapsed: boolean = true;
  @observable emptyTaskTitle: boolean = false;
  @observable isEditAccess: boolean = false;
  @observable selectedPanel: string = "agent";
  @observable agentId: number = 0;
  @observable NotesList = new Collection<AgentNoteListModel>();
  @observable name: string = "";
  @observable isLoading: boolean = true;
  @observable id: string = "";
  @observable startIndex: number = 0;
  @observable startSearchIndex: number = 0;
  @observable serachInputValue: string = "";
  @observable isShowAgentNotePin: boolean = false;
  @observable isShowAgentNoteTask: boolean = false;
  @observable isShowAgentNoteUnPin: boolean = false;
  @observable isPrevPinned: boolean = false;
  @observable isToasterAddNote: boolean = true;
  @observable validTitleError: string = "";

  @observable users = new Collection<{
    label: string;
    value: string;
  }>();
  @observable assignToNoteTask = new Collection<{
    label: string;
    value: string;
    isHeader?: boolean | undefined;
  }>();
  @observable noteTaskPriority = new Collection<{
    label: string;
    value: string;
  }>();

  @observable type = new Collection<{
    label: string;
    value: string;
  }>();
  @observable isDeleteNoteConfirm: boolean = false;
  @observable isCloneNoteConfirm: boolean = false;
  @observable isPingNoteConfirm: boolean = false;
  @observable isPingNote: boolean = false;

  @observable selectedType: string = "0";
  @observable selectedUser: string = "0";
  @observable selectedUserNoteTask: string | undefined = "0";
  @observable selectedAgentId: number | undefined = 0;

  @observable searchValue: string | undefined;
  @observable noteId: number = 0;
  @observable noteCreatedByUserId: number = 0;
  @observable sortColumn: string = "dateTime";
  @observable sortOrder: boolean = false;
  @observable impersonatedUserId: number | undefined = 0;
  @observable selectedCompany: string = "0";
  @observable companyList = [{ label: "ALL", value: "0" }];
  @observable AddNoteViewModel: AddNoteViewModel = new AddNoteViewModel();            
  @observable showInactiveCarriers: boolean = false;
  @observable carriersInitial = new Collection<{
    label: string;
    value: string;
  }>();




  getSymbol(): symbol {
    return AgentNotesViewModel.Symbol;
  }

  @action load = async (id: number) => {
    this.resetFilters();
    localStorage.removeItem("isClone");
    localStorage.removeItem("taskCreateToast");
    this.isShowAgentNoteTask = false;
    this.isShowAgentNotePin = false;
    this.isShowAgentNoteUnPin = false;
    this.agentId = id;
    this.noRecordsFound();
    this.impersonatedUserId = Utils.getUserId();
    this.loadLookups();
    this.sortColumn = "dateTime";
    this.sortOrder = false;
    this.selectedType = "0";
    this.selectedCompany = "0";
    this.rows = 25;
    setTimeout(() => {
      PageContext.setIsLoadingVisible(false);
    }, 3000);
    await this.loadNotes();
  };

  @action loadNotes = async () => {
    if (
      Utils.hasUserPermission(Permission.ViewPublicAgentNote) ||
      Utils.hasUserPermission(Permission.ViewPrivateAgentNote)
    ) {
      this.isLoading = true;
      var userId = +this.selectedUser;
      if (RouteParamDetails.notesUserId) {
        userId = RouteParamDetails.notesUserId;
      }
      var noteTypeId = +this.selectedType;
      try {
        var agentNotes = await ProducerStore.getAgentNotes(
          this.agentId,
          userId,
          noteTypeId,
          this.searchValue,
          parseInt(this.selectedCompany),
          this.startIndex,
          this.rows,
          this.sortColumn,
          this.sortOrder
        );
        if (RouteParamDetails.notesUserId) {
          var id = RouteParamDetails.notesUserId;
          await this.userChange("" + id);
        }
        if (agentNotes != null) {
          if (agentNotes.data) {
            this.totalRecords = agentNotes.recordCount
              ? agentNotes.recordCount
              : 0;
            this.NotesList.values = agentNotes.data;
          } else {
            this.noRecordsFound();
          }
        }
      } catch (e: any) {
        IMASLog.log("exception from store: " + e.value);
        this.noRecordsFound();
      }
      this.isLoading = false;
    }
  };
  @action noRecordsFound() {
    this.totalRecords = 0;
    this.NotesList.values = [];
  }

  @observable isMessgeVisible: boolean = false;
  @observable response: any;

  @action onDeleteNoteClick = async () => {
    try {
      this.isMessgeVisible = false;
      this.response = "";
      this.isLoading = true;
      this.setDeleteNoteConfirmation(false);
      await ProducerStore.deleteNote(this.noteId);
      if (this.isPrevPinned) {
        localStorage.setItem("AgentNotePin", "unpin");
        sessionStorage.setItem("isPinned", "unpin");
        this.isPrevPinned = false;
        this.isPingNote = false;
      }
      this.isMessgeVisible = true;
      setTimeout(async () => {
        await this.loadNotes();
        this.isLoading = false;
      }, Utils.timeDelay_Delete());
    } catch (error: any) {
      IMASLog.log("exception ..deleting note");
      this.isMessgeVisible = true;
      this.response = error.response;
      this.isLoading = false;
    }
  };
  @action searchIntervals = (text: string) => {
    this.serachInputValue = text;
    if (text) {
      this.searchValue = text;
    } else {
      this.searchValue = undefined;
      if (this.selectedUser === "0" && this.selectedType === "0") {
        this.startIndex = 0;
        this.loadNotes();
      } else {
        this.startSearchIndex = 0;
        this.loadNotes();
      }
    }
  };
  @observable createTaskModalVisibility: boolean = false;
  @observable taskRichtextValue: any = "";
  @observable characterCount: number = 0;
  @observable taskNoteTitle: string = "";
  @observable taskNoteCarrierID: any = null;

  @observable selectedNoteTaskPriority: string = "";
  @observable noteTaskDate: Date | undefined = new Date();
  @observable selectedCustomer: string = "";
  @observable selectedCustomerID: string = "0";
  @observable customersSearchList: number = 0;
  @observable FilteredCustomersList =
    new Collection<CustomerLookupListModelTask>();
  @observable selectedAgent: string = "";
  @observable FilteredAgentsList = new Collection<AgentListModel>();
  @observable agentsSearchList: number = 0;
  @observable selectedDocument: string = "";
  @observable FilteredDocumentList = new Collection<any>();
  @observable documentList = new Collection<any>();
  @observable documentTooltip: string = "Select an agent to attach a document";
  @observable isMaxLenghtExceed: boolean = false;
  @observable isCreateTaskSaveDisabled: boolean = false;

  @action setMessage = (value: string) => {
    if (value && value.length > 0) {
      this.characterCount = value.replace(/<[^>]+>/g, "").length;
      this.taskRichtextValue = value;
      this.isMaxLenghtExceed = this.characterCount > 500;
    } else {
      this.isMaxLenghtExceed = false;
      this.characterCount = 0;
    }
    this.checkCreateTaskSaveEnabled();
  };

  @action settaskTitle = (value: any) => {
    if (value.length == 1 && value == " ") {
      return false;
    } else {
      var regex = new RegExp(/\s{2}/, "g");
      if (regex.test(value)) {
        return false;
      } else {
        this.taskNoteTitle = value;
        return true;
      }
    }
  };

  @action resetTaskFields = async () => {
    this.taskNoteTitle = "";
    this.selectedNoteTaskPriority = "1";
    this.noteTaskDate = new Date();
    this.selectedCustomer = "";
    this.taskRichtextValue = "";
    this.characterCount = 0;
    this.isMaxLenghtExceed = false;
    this.documentList.values = [];
    this.selectedCompany = "0";
    this.selectedNoteTaskPriority = "";
  };
  @action setCreateTaskModalVisibility = async(value: boolean) => {   
    if (value) {
      await this.setSelectedAgentName();
      this.documentList.values = [];
    } else {
      this.resetTaskFields();
    }
    await this.getImpersonatingUserId();
    this.noteTaskPriority.values = [];
    this.noteTaskPriority.values.push(
      { value: "1", label: "Low" },
      { value: "2", label: "Medium" },
      { value: "3", label: "High" }
    );
    this.selectedDocument = "";
    this.selectedNoteTaskPriority = "1";
    this.emptyTaskTitle = false;
    this.noteTaskDate = new Date();
    this.isShowAgentNoteTask = false;
    this.createTaskModalVisibility = value;
  };
  @action saveTask = async () => {
    this.isLoading = true;
    this.isShowAgentNoteTask = false;
    try {
      var taskdto: CreateUpdateTaskModel = {};
      taskdto.id = 0;
      taskdto.userId = Number(this.selectedUserNoteTask);
      taskdto.agentId = this.agentId ? this.agentId : 0;
      taskdto.companyId =
        this.selectedCompany == null
          ? undefined
          : Number(this.selectedCompany) == 0
          ? null
          : Number(this.selectedCompany);
      taskdto.orderId = 0;
      taskdto.task = this.taskNoteTitle;
      taskdto.description = this.taskRichtextValue;
      taskdto.complete = false;
      taskdto.taskDate = this.noteTaskDate;
      taskdto.priority = parseInt(this.selectedNoteTaskPriority);

      taskdto.customerId =
        this.selectedCustomer == null
          ? undefined
          : Number(this.selectedCustomerID);
      taskdto.documentAttachmentList = this.documentList.values || null;

      await TaskStore.addTask(taskdto.agentId, taskdto);
      this.isLoading = false;
      Utils.showSuccessToaster("'" + this.taskNoteTitle + "' created.", 3000);
      //localStorage.setItem("taskCreateToast", "create");
      this.createTaskModalVisibility = false;
      //this.isShowAgentNoteTask = true;
    } catch (error) {
      this.isLoading = false;
    }
  };
  @action pingUnpingNote = async (isPinged: boolean) => {
    try {
      this.isShowAgentNotePin = false;
      this.isShowAgentNoteUnPin = false;
      //localStorage.removeItem("AgentNotePin");
      if (isPinged != null) {
        if (localStorage.getItem("AgentNotePinEdit") == "false-Edit")
          this.isPrevPinned = false;

        if (this.isPingNote && isPinged && this.isPrevPinned) {
          this.isPingNoteConfirm = true;
        } else {
          if (this.isPingNote && !isPinged) {
            this.isPingNote = false;
          }
          await ProducerStore.pinUnpinNote(this.noteId, isPinged, this.agentId);
          await this.loadNotes();
        }

        if (isPinged && !this.isPingNoteConfirm) {
          localStorage.setItem("AgentNotePin", "pin");
          sessionStorage.setItem("isPinned", "pin");
          this.isShowAgentNotePin = true;
          this.isPingNote = true;
        }
        if (!isPinged) {
          localStorage.setItem("AgentNotePin", "unpin");
          sessionStorage.setItem("isPinned", "unpin");
          this.isShowAgentNoteUnPin = true;
        }
        this.isPrevPinned = isPinged ? true : false;
      }
    } catch (error: any) {
      IMASLog.log("exception ..pinnged/unpingged note");
      this.isMessgeVisible = true;
      this.response = error.response;
      this.isLoading = false;
    }
  };

  @action onPinnedNoteClick = async () => {
    await ProducerStore.pinUnpinNote(this.noteId, true, this.agentId);
    await this.loadNotes();
    this.isPingNoteConfirm = false;

    this.isShowAgentNotePin = false;
    this.isShowAgentNoteUnPin = false;
    localStorage.removeItem("AgentNotePin");
    localStorage.setItem("AgentNotePin", "pin");
    sessionStorage.setItem("isPinned", "pin");
    this.isShowAgentNotePin = true;
    //this.isShowAgentNoteUnPin = false;
  };

  @observable pageEventPrevention: boolean = true;
  @action stopPagePropagatoin(pageEventPrevention: boolean) {
    this.pageEventPrevention = pageEventPrevention;
  }

  @action searchNotesDesc = async (key: string, text: string) => {
    if (key === "Enter") {
      this.startIndex = 0; // need to ensure that we are at the first page, otherwise we miss some search values
      this.loadNotes();
    }
  };

  @action goSearch = async () => {
    this.startIndex = 0; // need to ensure that we are at the first page, otherwise we miss some search values
    this.first = 0;
    await this.loadNotes();
  };

  @action setSelectedNote(noteId: number) {
    this.noteId = noteId;
  }

  @action setIsEditAccess(value: boolean) {
    this.isEditAccess = value;
  }

  @action setNoteCreatedByUserId(userId: number) {
    this.noteCreatedByUserId = userId;
  }
  @action setDeleteNoteConfirmation(value: boolean) {
    this.isDeleteNoteConfirm = value;
  }
  @action setCloneNoteConfirmation(value: boolean) {
    this.isCloneNoteConfirm = value;
  }
  @action setPinnedNoteConfirmation(value: boolean) {
    this.isPingNoteConfirm = value;
  }

  @action resetFilters = async () => {
    this.searchValue = undefined;
    this.selectedUser = "0";
    this.selectedType = "0";
    this.selectedCompany = "0";
    this.serachInputValue = "";
    this.startIndex = 0;
    this.first = 0;
    this.isEditAccess = false;
    RouteParamDetails.notesUserId = 0;
    this.showInactiveCarriers = false;
  };
  @action reset = async () => {
    this.resetFilters();
    await this.loadNotes();
  };

  @observable
  AppInlineProfileViewModel: AppInlineProfileViewModel =
    new AppInlineProfileViewModel();
  @action getImpersonatingUserId = async () => {
    try {
      var result = await UserStore.getImpersonateUserList();
      if (result) {
        Utils.mapListItemsToDropdown(result, this.assignToNoteTask, "");
        var selectedUser = result.filter((item: any) => {
          return item.selected === true;
        });
        if (selectedUser && selectedUser.length > 0) {          
          this.selectedUserNoteTask = selectedUser[0].value;
        }
      }
    } catch (e) {}
  };
  @action bindUsers = async (result: any) => {
    var defaultItem = { label: "All", value: "0" };
    try {
      if (result != null) {
        this.users.values = [];
        result.forEach((obj: any) => {
          var data = {
            label: obj.text ? obj.text : "",
            value: obj.value ? obj.value : "",
          };
          this.users.values.push(data);
        });
        this.users.values.splice(0, 0, defaultItem);
        this.selectedUser = "0";
      } else {
        this.users.values = [];
        this.users.values.splice(0, 0, defaultItem);
        this.selectedUser = "0";
      }
    } catch (e) {
      this.users.values = [];
      this.users.values.splice(0, 0, defaultItem);
      this.selectedUser = "0";
    }
    this.isLoading = false;
  };

  @action bindNotesTypes = async (result: any) => {
    var defaultItem = { label: "All", value: "0" };
    try {
      if (result != null) {
        this.type.values = [];
        this.type.values.push({ label: "All", value: "0" });
        result.forEach((obj: any) => {
          var res = {
            label: obj.text ? obj.text : "",
            value: obj.value ? obj.value : "",
          };
          this.type.values.push(res);
        });
        this.selectedType = "0";
      } else {
        this.type.values = [];
        this.type.values.splice(0, 0, defaultItem);
        this.selectedType = "0";
      }
    } catch (e) {
      this.type.values = [];
      this.type.values.splice(0, 0, defaultItem);
      this.selectedType = "0";
    }
  };

  @action loadLookups = async () => {
    this.isEditAccess = false;
    var result = await ProducerStore.getNoteScreenLookups();
    await this.loadCompanies("");
    if (result) {
      this.bindUsers(result.users || []);
      this.bindNotesTypes(result.noteTypes || []);
    }
  };

  compare = (a: any, b: any) => {
    // Use toUpperCase() to ignore character casing
    const nameA = a.lastName + ", " + a.firstName.toUpperCase();
    const nameB = b.lastName + ", " + b.firstName.toUpperCase();

    let comparison = 0;
    if (nameA > nameB) {
      comparison = 1;
    } else if (nameA < nameB) {
      comparison = -1;
    }
    return comparison;
  };
  @action onVirtual(index: number) {}

  @action onPage(firstIndex: number, rows: number) {
    this.rows = rows;
    this.first = firstIndex;
    //if (this.serachInputValue.trim().length > 0) {
    //  this.startSearchIndex = firstIndex / this.rows;
    //  this.loadNotes();
    //} else {
      this.startIndex = firstIndex / this.rows;
      this.loadNotes();
    //}
  }
  @action editNote(noteId: number | undefined) {}
  @action userChange = async (value: string) => {
    if (!this.serachInputValue || this.serachInputValue.trim() === "")
      this.searchValue = undefined;
    this.selectedUser = value;
    // this.searchNotes()
  };
  @action userNoteTaskChange = async (value: string) => {
    this.selectedUserNoteTask = value;
    this.checkCreateTaskSaveEnabled();
  };

  @action typeChange(value: string) {
    if (!this.serachInputValue || this.serachInputValue.trim() === "")
      this.searchValue = undefined;
    this.selectedType = value;
    //this.searchNotes()
  }
  @action searchChange(value: string) {
    this.serachInputValue = value;
    this.searchValue = value;
  }

  @action
  public Validate = async () => {
    return this.ErrorModel.Validate(this);
  };

  @action
  public IsFormValid = async () => {
    return this.ErrorModel.IsFormValid(this);
  };
  @action validateField = () => {
    this.emptyTaskTitle = this.taskNoteTitle.length < 1;
  };
  @action
  public ResetValidate = async () => {
    return this.ErrorModel.ResetValidation(this);
  };
  @action mapListItemAndSort(listObjects: any) {
    if (listObjects) {
      var sortList = listObjects.sort(Utils.compareListItem);
      sortList.forEach((element: any) => {
        element.label = element.text;
      });
      return sortList;
    } else {
      return [];
    }
  }

  @action handleInactiveCarriers = async (e: any) => {
    this.showInactiveCarriers = e.checked;
    this.companyList = []; 
    this.showInactiveCarriers ? (this.companyList = this.carriersInitial.values) :
    this.companyList = Utils.carriersFilter(this.carriersInitial.values); 
  };
  
  @action loadCompanies = async (carrier: any) => {
    try {
      this.isLoading = true;
      var carriers = await ContractsStore.GetActiveInactiveAllCarrierLookup();
      if (carriers) {
        var defaultItem = { label: "Select", value: "0" };
        this.companyList = this.mapListItemAndSort(carriers);
        this.companyList.splice(0, 0, defaultItem);
        this.carriersInitial.values = this.companyList;
        this.companyList = Utils.carriersFilter(this.carriersInitial.values);
    

        var tempVar = this.companyList.filter((item: any) => {
          return item.text == carrier;
        });
        this.selectedCompany = tempVar[0].value;
      }
      this.isLoading = false;
    } catch (e) {}
  };

  @action setSelectedCompany(value: string) {
    this.selectedCompany = value;
  }
  @action setSortOrder() {
    this.sortOrder = !this.sortOrder;
    this.startIndex = 0;
    this.first = 0;
  }
  @action setSortColumn(column: string) {
    this.sortColumn = column;
  }

  @action downloadDocumentLink = async (docId: number, fileName: string) => {
    this.isLoading = true;
    this.isMessgeVisible = false;
    this.response = {};
    try {
      var result = await DocumentStore.downloadFiles(docId, 0, true);
      if (result != null) {
        if (result.data) {
          Utils.downloadDocumentFile(
            result.data,
            result.fileName !== null && result.fileName !== undefined
              ? result.fileName
              : fileName,
            result.headers ? result.headers["content-type"] : result.data.type
          );
        }
      }
    } catch (e) {
      this.isMessgeVisible = true;
      this.response = e.response;
    }
    this.isLoading = false;
  };
  @action setSelectedCusomer(value: string) {
    if (typeof value == "string") {
      this.selectedCustomer = value;
    }
  }
  @action setSelectedCusomerID(value: string) {
    this.selectedCustomerID = value;
  }
  @action searchCustomers = async (value: string) => {
    IMASLog.log("search customers ..");
    var noCustomer: CustomerLookupListModelTask = { id: 0 };
    var searchTypeVal = 1;
    let isnum = /^\d+$/.test(value);
    if (isnum) {
      searchTypeVal = 6;
    }

    let result = await InsuredStore.getCustomerList(
      searchTypeVal,
      value,
      0,
      100,
      undefined,
      undefined
    );
    if (result !== null) {
      if (result.recordCount) {
        this.customersSearchList = result.recordCount;
        if (result.data) {
          result.data.splice(0, 0, {
            id: -1,
          });
          this.FilteredCustomersList.values = result.data;
        }
      } else {
        this.customersSearchList = 0;
        this.FilteredCustomersList.values = [noCustomer];
        this.selectedCustomer = "";
      }
    } else {
      this.customersSearchList = 0;
      this.FilteredCustomersList.values = [noCustomer];
      this.selectedCustomer = "";
    }
    this.isLoading = false;
    setTimeout(() => {}, 500);
  };
  @action getCityState = (agent: AgentListModel) => {
    if (agent.city && agent.state) return agent.city + ", " + agent.state;
    else if (agent.city && !agent.state) return agent.city;
    else if (!agent.city && agent.state) return agent.state;
    else return "";
  };
  @action setSelectedAgent(value: string) {
    if (typeof value == "string") {
      this.selectedAgent = value;
    }
  }
  @action searchAgents = async (value: string) => {
    let result = await ProducerSearchStore.getAgentsLookup(
      1,
      value,
      0,
      20,
      undefined,
      undefined
    );
    var noAgent: AgentListModel = { id: 0 };
    if (result !== null) {
      if (result.recordCount) {
        this.agentsSearchList = result.recordCount;
        if (result.data) {
          this.FilteredAgentsList.values = result.data;
        }
      } else {
        this.agentsSearchList = 0;
        this.FilteredAgentsList.values = [noAgent];
        this.selectedAgent = "";
        this.selectedAgentId = 0;
      }
    } else {
      this.agentsSearchList = 0;
      this.FilteredAgentsList.values = [noAgent];
      this.selectedAgent = "";
      this.selectedAgentId = 0;
    }
    this.isLoading = false;
    setTimeout(() => {}, 500);
  };

  @action deleteDocument(rowId: any, mappingId: any) {
    let document: any;
    if (rowId && rowId > 0 && mappingId == 0) {
      this.documentList.values = this.documentList.values.filter(
        (item: any) => {
          return item.rowId != rowId;
        }
      );
    } else if (mappingId && mappingId > 0) {
      document = this.documentList.values.filter((item: any) => {
        return item.mappingId == mappingId;
      });

      if (document && document.length > 0) {
        document[0].isDeleted = true;
      }
    }
  }
  @action filterDocuments = async (value: string) => {
    let result = await DocumentStore.getAgentDocuments(
      this.agentId
        ? this.agentId
        : parseInt("" + localStorage.getItem("currentAgentId")) || 0,
      0,
      0,
      this.selectedDocument,
      0,
      100,
      "id",
      false,
      Utils.hasUserPermission(Permission.ManagePrivateAgentDocuments)
        ? null
        : false
    );
    var noAgent: AgentListModel = { id: 0 };
    if (result && result.recordCount && result.data) {
      let self = this;
      result.data =
        self.documentList.values &&
        result.data.filter((i) => {
          return (
            self.documentList.values.find(
              (j) =>
                j.documentName == i.documentName &&
                j.fileType == i.fileType &&
                !j.isDeleted
            ) == null
          );
        });
      result.recordCount = (result.data && result.data.length) || 0;
    }
    if (result !== null) {
      if (result.recordCount) {
        if (result.data) {
          result.data.splice(0, 0, {
            id: -1,
          });
          this.FilteredDocumentList.values = result.data;
          var element = document.querySelector(".p-autocomplete-panel");
          setTimeout(() => {
            if (element) {
              element.scrollTo(0, 0);
            }
          }, 10);
        }
      } else {
        this.FilteredDocumentList.values = [noAgent];
        this.selectedDocument = "";
      }
    }
  };

  @action setSelectedDocument(value: any) {
    this.selectedDocument = value;
  }
  @action checkCreateTaskSaveEnabled = () => {   
    this.taskNoteTitle.length > 0 &&
    !this.emptyTaskTitle &&
    this.selectedUserNoteTask != "0" &&
    this.selectedAgentId != 0 &&
    !this.isMaxLenghtExceed
      ? (this.isCreateTaskSaveDisabled = false)
      : (this.isCreateTaskSaveDisabled = true);
  };

  @action setSelectedAgentName = async () => {
    try {
      this.isLoading = true;
      if (this.agentId != null && this.agentId != undefined) {
        var agentInfo = await ProducerStore.getAgentProfileInfoById(
          this.agentId
        );
        if (agentInfo && agentInfo.name) {
          let nameArray = agentInfo.name.split(" ");
          if (nameArray.length > 1) {
            this.selectedAgent = nameArray[1] + ", " + nameArray[0];
            this.selectedAgentId = agentInfo.id;
          } else {
            this.selectedAgent = nameArray[0];
            this.selectedAgentId = 0;
          }
        }
      }
      this.isLoading = false;
    } catch (e) {}
  };
}

class AddressValidator extends AbstractValidator<AgentNotesViewModel> {
  public constructor() {
    super();
    this.validateIfString((input) => input.taskNoteTitle)
      .isNotEmpty()
      .withFailureMessage("Title is required");
  }
}
