import { action, observable } from "mobx";
import ViewModel from "../../../infrastructure/ViewModel";
import {
	CommunicationLookupResponse,
	CommunicationRequest,
	ContractCommunicationSPModel,
} from "../../../services/ContractService";
import Collection from "../../../infrastructure/CollectionHelper";
import ContractsStore from "../../../store/ContractsStore";
import RouteList from "../../../infrastructure/RouteList";
import ProducerStore from "../../../store/ProducerStore";
import UserContext from "../../../infrastructure/UserContext";
import { NoteModel } from "../../../services/ProducerService";
import Utils from "../../../infrastructure/Utils";

export class CommunicationsViewModel implements ViewModel {
	@observable selectedPaymentPlanId: number = 0;
	@observable carrierName: string = "";
	get CanClose(): boolean {
		throw new Error("Method not implemented.");
	}
	get CanRoute(): boolean {
		return true;
	}
	get IsLoaded(): boolean {
		throw new Error("Method not implemented.");
	}
	get IsLoading(): boolean {
		throw new Error("Method not implemented.");
	}
	Close(): void {
		throw new Error("Method not implemented.");
	}
	@action Load = async (contractId: number, agentId: number) => {
		this.contractId = contractId;
		this.agentId = agentId;
		this.noCommunications();
		await this.loadContractCommunications();
	};
	Route = async (currentRoute: RouteList): Promise<void> => { };
	@observable contractId: number = 0;
	@observable agentId: number = 0;
	@observable isLoading: boolean = true;
	@observable isException: boolean = true;
	@observable isMessgeVisible: boolean = false;
	@observable response: any;

	@observable sortColumnCommu: string = "communicationTypeName";
	@observable sortOrderCommu: boolean = true;
	@observable pageIndexCommu: number = 0;
	@observable totalRecordsCommu: number = 0;
	@observable rowsCommu: number = 10;
	@observable firstCommu: number = 0;
	@observable selectedCommuId: number = 0;
	@observable CommunicationDataList =
		new Collection<ContractCommunicationSPModel>();
	@observable AllEmailDataList =
		new Collection<ContractCommunicationSPModel>();
	@observable isDeleteCommu: boolean = false;
	@observable isAddCommu: boolean = false;
	@observable communicationType: string = "";
	@observable communicationTypeList = new Collection<{
		label: string;
		value: string;
	}>();
	@observable communicationContact: string = "";
	@observable communicationContactList = new Collection<{
		label: string;
		value: string;
	}>();
	@observable commuInheritance: string = "0";
	@observable commuInheritanceList = [
		{ label: "Both This Contract and Downline", value: "0" },
		{ label: "This Contract", value: "1" },
		{ label: "Downline", value: "2" },
	];

	@action loadContractCommunications = async () => {
		this.isLoading = true;
		this.isMessgeVisible = false;
		try {
			if (this.contractId) {
				var result = await ContractsStore.getContractCommunications(
					this.contractId,
					this.pageIndexCommu,
					this.rowsCommu,
					this.sortColumnCommu,
					this.sortOrderCommu
				);
				if (result && result.data) {
					if (result.emailAllRecords) {
						this.AllEmailDataList.values = result.emailAllRecords;
					}
					this.CommunicationDataList.values = result.data;
					this.totalRecordsCommu = result.recordCount ? result.recordCount : 0;
					
				} else {
					this.noCommunications();
				}
			}
		} catch (e) {
			this.noCommunications();
			this.isLoading = false;
			this.isMessgeVisible = true;
			this.response = e.response;
		}
		this.isLoading = false;
	};

	@action noCommunications() {
		this.CommunicationDataList.values = [];
		this.AllEmailDataList.values = [];
		this.totalRecordsCommu = 0;
	}

	@action async setSortColumnCommu(sortField: any) {
		this.sortColumnCommu = sortField;
		await this.loadContractCommunications();
	}
	@action setSortOrderCommu() {
		this.sortOrderCommu = !this.sortOrderCommu;
		this.pageIndexCommu = 0;
		this.firstCommu = 0;
	}
	@action setSelectedCommuId(value: number) {
		this.selectedCommuId = value;
	}

	@action setCommuContact(value: string) {
		this.communicationContact = value;
	}
	@action setCommuInheritance(value: string) {
		this.commuInheritance = value;
	}
	@action setAddCommu(value: boolean) {
		this.isAddCommu = value;
		this.lookupCommunication();
	}

	@observable CommunicationLookupResponse: CommunicationLookupResponse | null =
		null;

	@action lookupCommunication = async () => {
		try {
			this.CommunicationLookupResponse =
				await ContractsStore.lookupCommunication(this.contractId);
			if (this.CommunicationLookupResponse) {
				if (
					this.CommunicationLookupResponse.communicationTypes &&
					this.CommunicationLookupResponse.communicationTypes.length > 0
				) {
					this.communicationTypeList.values = [];
					this.communicationTypeList.values.push({ label: "All", value: "0" });
					this.CommunicationLookupResponse.communicationTypes.forEach((obj) => {
						var data = {
							label: obj.text ? obj.text : "",
							value: obj.value ? obj.value : "",
						};
						this.communicationTypeList.values.push(data);
						this.communicationType =
							this.communicationTypeList.values.length > 0
								? this.communicationTypeList.values[0].value
								: "";
					});
				} else {
					this.communicationTypeList.values = [];
				}
				if (
					this.CommunicationLookupResponse.contactOptions &&
					this.CommunicationLookupResponse.contactOptions.length > 0
				) {
					this.communicationContactList.values = [];
					this.communicationContactList.values.push({
						label: "Use Default Contacts",
						value: "0",
					});
					this.CommunicationLookupResponse.contactOptions.forEach((obj) => {
						var data = {
							label:
								obj.contactValue && obj.description
									? obj.contactValue + " - " + obj.description
									: "",
							value: obj.id ? "" + obj.id : "",
						};
						this.communicationContactList.values.push(data);
					});
					this.communicationContact = "0";
				} else {
					this.communicationContactList.values = [];
				}
			}
		} catch (error) { }
	};

	@action setDeleteCommuConfirm(value: boolean) {
		this.isDeleteCommu = value;
	}
	@action deleteCommunication = async () => {
		try {
			this.isLoading = true;
			this.isMessgeVisible = false;
			if (this.contractId) {
				await ContractsStore.deleteCommunication(
					this.selectedCommuId,
					this.contractId
				);
				setTimeout(async () => {
					this.setDeleteCommuConfirm(false);
					await this.loadContractCommunications();
					this.isLoading = false;
				}, Utils.timeDelay_Delete());
			}
		} catch (e) {
			this.isLoading = false;
			this.isMessgeVisible = true;
			this.response = e.response;
			this.setDeleteCommuConfirm(false);
		}
	};

	@action addCommunication = async () => {
		try {
			this.isMessgeVisible = false;
			this.response = "";
			this.isLoading = true;
			var dto: CommunicationRequest = {
				contractId: +this.contractId,
				agentContactId: +this.communicationContact,
				communicationTypeId: +this.communicationType,
				inheritance: +this.commuInheritance,
				createdByUserId: UserContext.userId,
			};
			await ContractsStore.addCommunication(dto);

			this.isLoading = false;
			this.setAddCommu(false);
			await this.loadContractCommunications();
		} catch (error) {
			this.isLoading = false;
			this.isMessgeVisible = true;
			this.response = error.response;
		}
	};
	@action setCommuType(value: string) {
		this.communicationType = value;
	}
	@action getInheritance(value: number) {
		switch (value) {
			case 0:
				return "Both This Contract and Downline";
			case 1:
				return "This Contract Only";
			case 2:
				return "Downline Only";
			default:
				return "";
		}
	}
	@action getEmailHref = (
		value: string | undefined,
		type: number | undefined
	) => {
		return "mailto:" + value + "?Subject=";
	};
	@action getAllEmailHref = (
		value: string | undefined,
		type: number | undefined
	) => {
		var data = this.AllEmailDataList.values.filter(
			(x) => x.communicationTypeID == type
		);
		var emails = "";
		data.forEach((element: any) => {
			if (
				element.contactValue.match(
					/([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+)/gi
				)
			)
				emails = emails + element.contactValue + ";";
		});
		emails = emails.replace(/\s/g, '').slice(0, -1);
		return "mailto:" + emails + "?Subject=";
	}
	@action getEmailHrefAll = (
		value: string | undefined,
		type: number | undefined
	) => {
		var data = this.AllEmailDataList.values.filter(
			(x) => x.communicationTypeID == type
		);
		var emails = "";
		data.forEach((element: any) => {
			if (
				element.contactValue.match(
					/([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+)/gi
				)
			)
				emails = emails + element.contactValue + ";";
		}); 
		emails = emails.slice(0, -1);
		var link = document.createElement("a");
		var url = "mailto:" + emails + "?Subject=";
		link.href = url;
		link.click();
		window.URL.revokeObjectURL(url);
		link.remove();
	};

	@action emailAllVisible = (type: number | undefined) => {
		var data = this.CommunicationDataList.values.filter(
			(x) => x.communicationTypeID == type
		);
		var emailsCount = 0;
		var countVal = 0;
		data.forEach((element: any) => {
			if (data[countVal].contactTypeID == 1) emailsCount = emailsCount + 1;

			countVal = countVal + 1;
		});
		if (emailsCount > 1) return true;
		else return false;
	};
	@action onPageCommu = async (firstIndex: number, rows: number) => {
		this.rowsCommu = rows;
		this.firstCommu = firstIndex;
		this.pageIndexCommu = firstIndex / rows;
		await this.loadContractCommunications();
	};
	@action getContactPhoneNumber = (value: string | undefined) => {
		this.dialerLog(value);
		window.open("rcapp://r/call?number=" + value, "_parent");
	};
	@action dialerLog = async (number: any) => {
		var dto: NoteModel = {
			note: `Called ${number} from the Contract communications`,
			agentNoteTypeId: 1,
			private: false,
			dateTime: new Date(),
			companyId: 0,
			title: "",
			documentAttachmentList: undefined,
		};
		await ProducerStore.addNote(this.agentId, dto);
	};
	@observable pageEventPrevention: boolean = true;
	@action stopPagePropagatoin(pageEventPrevention: boolean) {
		this.pageEventPrevention = pageEventPrevention;
	}
}
