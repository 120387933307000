import fetchIntercept from "fetch-intercept";
import { routes } from "../router";
import Utils from "./Utils";
import IMASLog from "./IMASLog";
import PageContext from "./PageContext";

export const registerFetchInterceptor = () => {
  fetchIntercept.register({
    request: async function (url: any, config) {
      // internal references
      let internal_apis = [
        "integritymarketing",
        "integrity",
        "azurewebsites.net",
        "localhost",
      ];
      let isInternalApi = false;
      let urlToLoweerCase = url && JSON.stringify(url).toLowerCase();

      internal_apis.forEach((a) => {
        if (urlToLoweerCase.includes(a)) {
          isInternalApi = true;
        }
      });

      let withDefaults = Object.assign({}, config);
      if (isInternalApi) {
        let host = Utils.getHost();
        let authKey = await Utils.getApiKey();
        // Start with the original headers as a baseline:
        const modifiedHeaders = new Headers(withDefaults.headers);
        modifiedHeaders.set("HOSTNAME", host);
        modifiedHeaders.set("authorization", authKey);
        modifiedHeaders.set("Accept_Timezone", "" + Utils.getTimezoneOffset());
        let invitecode = localStorage.getItem("invite_code") || "";
        if (invitecode != "") {
          modifiedHeaders.set("Code_invite", invitecode);
        }
        withDefaults.headers = modifiedHeaders;
      }

      if (isInternalApi && !urlToLoweerCase.includes("chunk.js")) {
        PageContext.setIsMessageVisible(false);
      }
      PageContext.setIsLoadingVisible(true);
      return [url, withDefaults];
    },
    requestError: function (error) {
      IMASLog.log("request error");
      PageContext.setIsLoadingVisible(false);
      PageContext.setResponseMessage(error.response);
      PageContext.setIsMessageVisible(true);

      return Promise.reject(error);
    },
    response: function (response) {
      PageContext.setIsLoadingVisible(false);
      PageContext.setUserAccess(true);

      if (response.status === 401) {
        PageContext.setUserAccess(false);
        if (localStorage.getItem("auth0UserItem") != null) {
          response.text().then((_responseText) => {
            routes.noAccess.replace();
            let errorTitle = "Unauthorized to access.";
            if (_responseText) {
              let obj = JSON.parse(_responseText);
              errorTitle = obj.title;
            }

            let error = {
              status: 401,
              title: errorTitle,
              errors: { "": [] },
            };
            PageContext.setResponseMessage(JSON.stringify(error));
            PageContext.setIsMessageVisible(true);
          });
        }
      }
      if (response.status === 404) {
        response.text().then((_responseText) => {
          let errorTitle = "Data not found.";
          if (_responseText) {
            let obj = JSON.parse(_responseText);
            errorTitle = obj.title;
          }

          let error = {
            status: 404,
            title: errorTitle,
            errors: { "": [] },
          };
          PageContext.setResponseMessage(JSON.stringify(error));
          PageContext.setIsMessageVisible(true);
        });
      }
      response.status === 400 && handle400ValidationErrors(response);
      return response;
    },
    responseError: function (error) {
      console.log(error);
      IMASLog.log("response error");
      PageContext.setIsLoadingVisible(false);
      PageContext.setResponseMessage(
        JSON.stringify(PageContext.getTimeOutResponse())
      );
      PageContext.setIsMessageVisible(true);
      return Promise.reject(error);
    },
  });

  function handle400ValidationErrors(
    response: fetchIntercept.FetchInterceptorResponse
  ) {
    let location = window.location.href.toLowerCase();
    var n = response.request.url.toLowerCase().lastIndexOf("/");
    var result = response.request.url.toLowerCase().substring(n + 1);
    if (
      response.request.url.includes("BulkUpdateContracts") &&
      response.status === 400
    ) {
      throw response;
    } else if (
      response.request.url.toLowerCase().includes("documents/createdocument") &&
      response.status === 400
    ) {
      throw response;
    } else if (
      response.request.url.toLowerCase().includes("documents/updatedocument") &&
      response.status === 400
    ) {
      throw response;
    } else if (
      location.includes("workflow") &&
      response.request.url.toLowerCase().includes("/documents") &&
      response.status === 400
    ) {
      throw response;
    } else if (
      response.request.url.toLowerCase().includes("passwordlist") &&
      response.status === 400
    ) {
      throw response;
    } else if (
      location.includes("reports/customreports/add") &&
      result == "report" &&
      response.status == 400
    ) {
      throw response;
    } else if (
      location.includes("administration/users/edit") &&
      result == "users" &&
      response.status == 400
    ) {
      throw response;
    } else if (
      location.includes("administration/general/productlines/list") &&
      result == "productlines" &&
      response.status == 400
    ) {
      throw response;
    } else if (
      response.request.url.toLowerCase().includes("agents") &&
      (result == "createalert" || result == "updatealert") &&
      response.status == 400
    ) {
      throw response;
    } else if (
      location.includes("agents") &&
      response.request.url.toLowerCase().includes("contacts") &&
      response.status === 400
    ) {
      throw response;
    } else if (
      location.includes("agents") &&
      response.request.url.toLowerCase().includes("appointments") &&
      response.status === 400
    ) {
      throw response;
    } else if (
      (location.includes("agents") || location.includes("supplies/add")) &&
      response.request.url.toLowerCase().includes("address") &&
      response.status === 400
    ) {
      throw response;
    } else if (
      location.includes("addcustomer") &&
      response.request.url.toLowerCase().includes("validatephonenumber") &&
      response.status === 400
    ) {
      throw response;
    } else if (
      location.includes("customers") &&
      response.request.url.toLowerCase().includes("insuredcontact") &&
      response.status === 400
    ) {
      throw response;
    } else if (
      location.includes("announcement/list") &&
      response.request.url.toLowerCase().includes("announcements") &&
      response.status === 400
    ) {
      throw response;
    } else if (
      location.includes("documents/packet") &&
      response.request.url.toLowerCase().includes("duplicatepacket") &&
      response.status === 400
    ) {
      throw response;
    } else if (
      location.includes("general/notifications") &&
      result === "notification" &&
      response.status === 400
    ) {
      throw response;
    } else if (
      location.includes("agentgroups/list") &&
      response.request.url.toLowerCase().includes("agentgroups") &&
      response.status === 400
    ) {
      throw response;
    } else if (
      location.includes("customers") &&
      response.request.url.toLowerCase().includes("/policies/notes") &&
      response.status === 400
    ) {
      throw response;
    } else if (
      location.includes("licensestatusreasons") &&
      response.request.url.toLowerCase().includes("/licensestatusreason") &&
      response.status === 400
    ) {
      throw response;
    } else if (
      location.includes("payoutlevels") &&
      response.request.url.toLowerCase().includes("/payoutlevel") &&
      response.status === 400
    ) {
      throw response;
    } else if (
      location.includes("managerts") &&
      response.request.url
        .toLowerCase()
        .includes("/managerts/addrtscertification") &&
      response.status === 400
    ) {
      throw response;
    } else if (
      location.includes("contracts") &&
      response.request.url.toLowerCase().includes("/paymentplans/") &&
      response.status === 400
    ) {
      throw response;
    } else if (
      location.includes("docworkflow") &&
      (response.request.url.toLowerCase().includes("/replace") ||
        response.request.url.toLowerCase().includes("/notes") ||
        response.request.url.toLowerCase().includes("/documents") ||
        response.request.url.toLowerCase().includes("/attach") ||
        response.request.url.toLowerCase().includes("/sendto")) &&
      response.status === 400
    ) {
      throw response;
    } else if (
      location.includes("submissionmanagement/sendersubmissiontypes") &&
      response.request.url
        .toLowerCase()
        .includes("/submissionsenderemailtypes") &&
      response.status === 400
    ) {
      throw response;
    } else if (
      location.includes("managerts") &&
      (response.status === 400 || response.status === 404)
    ) {
      throw response;
    } else if (
      location.includes("roles/list") &&
      (response.request.url.toLowerCase().includes("roles") ||
        response.request.url.toLowerCase().includes("cloneRole")) &&
      response.status === 400
    ) {
      throw response;
    } else if (
      (location.includes("agents") || location.includes("policies/edit")) &&
      response.request.url.toLowerCase().includes("policies/notes") &&
      response.status === 400
    ) {
      throw response;
    } else if (response.status === 500 || response.status === 400) {
      response.text().then((_responseText) => {
        let _title = JSON.parse(_responseText).title;
        PageContext.setResponseMessage(_responseText);
        if (!_title.includes("Contact with Phone Number")) {
          PageContext.setIsMessageVisible(true);
        }
      });
    }
  }
};
