import { action, observable, toJS } from "mobx";
import ViewModel from "../../../infrastructure/ViewModel";
import Collection from "../../../infrastructure/CollectionHelper";
import Utils from "../../../infrastructure/Utils";
import ContractsStore from "../../../store/ContractsStore";
import {
  ApiException,
  ContractModel,
  CreateUpdateContractModel,
  ImmediateUplineResponse,
  LevelListModel,
  LicenseLocations,
  Notes,
  PrinicpalAgents,
  ProductDetailsModel,
  ProductModel,
  SubCategoryModel,
  WritingNumbers,
} from "../../../services/ContractService";
import RouteList from "../../../infrastructure/RouteList";
import RouteParamDetails from "../../../infrastructure/RouteParamDetails";
import { Permission } from "../../../infrastructure/enum/Permission";
import {
  ContractDetails,
  NotificationTemplateName,
} from "../../../infrastructure/enum/Contract";
import IdentityStore from "../../../store/IdentityStore";
import { routes } from "../../../router";
import { CommunicationsViewModel } from "./../contracts/CommunicationsViewModel";
import { ContractDocumentsViewModel } from "./../contracts/ContractDocumentsViewModel";
import { ContractPaymentplansViewModel } from "./../contracts/ContractPaymentplansViewModel";
import { ContractPersistencyViewModel } from "./../contracts/ContractPersistencyViewModel";
import { ContractStatesViewModel } from "./../contracts/ContractStatesViewModel";
import { AddEditPaymentPlanViewModel } from "./../paymentplans/AddEditPaymentPlanViewModel";
import { NotificationListModel } from "../../../services/NotificationService";
import NotificationStore from "../../../store/NotificationStore";
import { CustomAttributesComponentViewModel } from "../../../views/customers/CustomAttributesComponentViewModel";
import { CustAttrToEntityModel } from "../../../services/ContractService";
import CustomAttributeStore from "../../../store/CustomAttributeStore";
import PageContext from "../../../infrastructure/PageContext";
import ProducerStore from "../../../store/ProducerStore";
import axios from "axios";
import UserContext from "../../../infrastructure/UserContext";
import { toast } from "react-toastify";
import { closeToasterButtonTemplate } from "../../../views/components/controls/IMASTemplates";
import loggedInUser from "../../../infrastructure/Auth0Authenticator";

export class AddEditContractViewModel implements ViewModel {
  get CanClose(): boolean {
    throw new Error("Method not implemented.");
  }
  get CanRoute(): boolean {
    return true;
  }
  get IsLoaded(): boolean {
    throw new Error("Method not implemented.");
  }
  get IsLoading(): boolean {
    throw new Error("Method not implemented.");
  }
  Close(): void {
    throw new Error("Method not implemented.");
  }
  @action Load = async (
    agentId: number | undefined,
    contractId: number | undefined
  ) => {
    PageContext.isCustomAttribute = false;
    this.replacedByLicenseId = "";
    this.replacedFromLicenseId = "";
    this.isSave = false;
    this.isUpdateAgentStatusConfirm = false;
    this.updateAgentStatusBtn = true;
    this.isCustAttrWarning = false;
    this.contractId = contractId || 0;
    this.agentId = agentId || 0;
    this.resetFormFields();
    this.selectedTabIndex = 0;
    this.selectedPanel = ContractDetails.Info;
    this.isCancelChangesConfirmation = false;
    this.hierarchyFilter = "";
    this.agentHierarchyData = [];
    this.callDataLoadAPI = true;
    this.hideValidationToaster();
    await this.loadAllLookupList(contractId || 0, agentId || 0);
    this.isContractLoaded = true;
    if (contractId && this.contractId !== 0) {
      this.title = "";
      this.isEdit = true;
      this.copyUplineEnable(this.selectedUplineAgentId);
    } else {
      this.title = "New Contract";
      this.isEdit = false;
      let buName = Utils.getServiceUrlByEnvironment();
      if (buName.includes("pre")) {
        this.showActiveContract = true;
      }
    }
    if (localStorage.getItem("isFromViewContract")) {
      this.isFromViewContract =
        localStorage.getItem("isFromViewContract") == "true";
      localStorage.removeItem("isFromViewContract");
    }
    this.enableSave();
    this.loadEmailNotificationTemplates();

    if (
      Utils.hasUserPermission(Permission.PrivateCustomAttribute) ||
      Utils.hasUserPermission(Permission.PublicCustomAttribute)
    ) {
      await this.checkPermissions();
    }
  };

  Route = async (currentRoute: RouteList): Promise<void> => {
    switch (currentRoute.name) {
      case routes.contractDetails.name:
        //window.scrollTo(0, 0);
        this.selectedTabIndex = 0;
        this.selectedPanel = ContractDetails.Info;
        break;
      case routes.editContractAddPaymentPlan.name:
        localStorage.setItem("carrierName", this.carrierName);
        this.selectedTabIndex = 4;
        this.setIsAddPaymentPlan(true);
        this.selectedPanel = ContractDetails.PaymentPlans;
        this.AddEditPaymentPlanViewModel.isCalledFromEditContract = true;
        this.AddEditPaymentPlanViewModel.Load(this.agentId, undefined);
        break;
      case routes.editContractAddPaymentPlanFromLevel.name:
        this.selectedTabIndex = 4;
        this.setIsAddPaymentPlan(true);
        this.selectedPanel = ContractDetails.PaymentPlans;
        this.AddEditPaymentPlanViewModel.isCalledFromEditContract = true;
        this.AddEditPaymentPlanViewModel.Load(this.agentId, undefined, true);

        break;
      case routes.editContractEditPaymentPlan.name:
        this.selectedTabIndex = 4;
        this.setIsAddPaymentPlan(true);
        this.selectedPanel = ContractDetails.PaymentPlans;
        this.AddEditPaymentPlanViewModel.isCalledFromEditContract = true;
        this.AddEditPaymentPlanViewModel.Load(
          this.agentId,
          RouteParamDetails.paymentPlanId
        );
        break;
      case routes.editContractPaymentPlans.name:
        this.selectedTabIndex = 4;
        //await this.getContractSummary();
        localStorage.setItem("carrierName", this.carrierName);
        this.setIsAddPaymentPlan(false);
        this.selectedPanel = ContractDetails.PaymentPlans;
        if (localStorage.getItem("loadpaymentplans") === "true") {
          this.ContractPaymentplansViewModel.Load(
            this.contractId,
            this.agentId
          );
          localStorage.removeItem("loadpaymentplans");
        } else {
          this.ContractPaymentplansViewModel.Load(
            this.contractId,
            this.agentId
          );
        }
        break;
    }
  };
  /* #region variable declaration */
  @observable agentId: number = 0;
  @observable contractId: number = 0;
  @observable isAgency: boolean = false;
  @observable title: string = "";
  @observable agentName: string = "";
  @observable carrierName: string = "";
  @observable paymentPlanCount: number = 0;
  @observable activeLicenseCount: number = 0;
  @observable isContractLoaded: boolean = false;
  @observable productDetailsChangeDetector: number = 1;
  @observable writingNumberChangeDetector: number = 1;
  @observable noteChangeDetector: number = 1;
  @observable locationChangeDetector: number = 1;
  @observable principalAgentChangeDetector: number = 1;
  @observable productDetailSelection: any = [];
  @observable productDeleteRowId = new Date().getTime();

  @observable isSave: boolean = false;
  @observable isDoNotCall: boolean = false;
  @observable isDoNotEmail: boolean = false;
  @observable isDoNotMarket: boolean = false;
  @observable showActiveContract: boolean = false;
  @observable isDoNotCallDownline: boolean = false;
  @observable isDoNotEmailDownline: boolean = false;
  @observable isDoNotMarketDownline: boolean = false;

  @observable existingIsDoNotCallDownline: boolean = false;
  @observable existingIsDoNotEmailDownline: boolean = false;
  @observable existingIsDoNotMarketDownline: boolean = false;
  @observable isUpdateDownlineDNx: boolean = false;
  @observable isUpdateDownlineDiloagDisplay: boolean = false;
  @observable isFromViewContract: boolean = false;

  @observable selectedOldCompany: string = "";
  @observable selectedCompany: string = "";
  @observable selectedStatusReason: string = "";
  @observable existingStatus: string = "";
  @observable existingAgentStatus: string = "";
  @observable selectedStatus: string = "";
  @observable selectedStatusInitial: string = "";
  @observable updateAgentStatusBtn: boolean = true;
  @observable startDate: Date = new Date();
  @observable enteredDate: Date | undefined = new Date();
  @observable modifiedDate: Date | undefined;
  @observable createdByName: string | undefined = undefined;
  @observable modifiedByName: string | undefined = undefined;
  @observable endDate: Date | undefined;
  @observable contractSubmittedDate: Date | undefined;
  @observable selectedAdvanceMonth: string = "0";
  @observable selectedSalesType: string = "";
  @observable validateSalesType: boolean = false;
  @observable selectedAgentGroupId: string = "0";
  @observable selectedProcessedby: string = "";
  @observable selectedProcessedbyId: string = "";
  @observable contractReciptDate: Date | undefined;
  @observable approvalDate: Date | undefined;
  @observable applicationSubmittedwithin90days: Date | undefined;
  @observable selectedAppointmentType: string = "";
  @observable selectedeUpline: string = "";
  @observable selectedUplineAgentId: string = "";
  @observable selectedReportsTo: string = "0";
  @observable yearRange: string = "";
  @observable isAgencyIsPayee: boolean = false;
  @observable replacedFromLicenseId: string = "";
  @observable replacedByLicenseId: string = "";
  @observable statusForReplaceCheck: string = "";

  @observable selectedTabIndex: number = 0;
  @observable selectedPanel: string = ContractDetails.Info;

  @observable agentHierarchyData: any;
  @observable isAgentHierarchyLoaded: boolean = false;

  @observable selectedPayee: string = "";
  @observable existingPayee: string = "";
  @observable selectedPayeeLabel: string = "";
  @observable isPayeeChange: boolean = false;
  @observable isPayeeChangeConirm: boolean = false;

  @observable downlineContractCount: number | undefined = 0;
  @observable contractStatusId: number | undefined = 0;
  @observable terminateDownlineContract: boolean = false;
  @observable NotificationTemplatesList =
    new Collection<NotificationListModel>();
  @observable totalNotificationRowsCount: number = 0;
  @observable notificationSendVisibility: boolean = false;
  @observable isEmailNotificationSend: boolean = false;
  @observable isEmailNotificationActive: boolean = false;
  @observable startIndexForNotification: number = 0;
  @observable pageSizeForNotification: number = 10000;
  @observable sortColumnForNotification: string = "id";
  @observable sortOrderForNotification: boolean = false;
  @observable defaultStatusForNotification: number = 1;
  @observable TitleForNotification: string =
    " Do you want to send 'Contracting Update' email?";

  @observable isDeleteConfirm: boolean = false;
  @observable deleteContractContext: any = "";
  @observable deleteConfirmTitle: any = "";
  @observable isAddNoteConfirm: boolean = false;
  @observable enableCopyUpline: boolean = false;
  @observable enableCopyUplineDialog: boolean = false;

  // Message handling
  @observable isEndDateRequired: boolean = false;
  @observable isEndDateInvalid: boolean = false;

  @observable copyUplineSaveEnable: boolean = false;
  @observable isCancelChangesConfirmation: boolean = false;
  @observable selectedCopyUpline: string = "";
  @observable copyUplineLevelLists = [
    { label: "No Level Selected", value: "0" },
    { label: "LOA", value: "1" },
    { label: "Premier", value: "2" },
  ];
  @observable contractAgentName: string = "";
  @observable uplineContractId: string = "0";
  @observable timer: any;

  @observable isCarrierChangeConfirm: boolean = false;

  @observable isUpdateAgentStatusConfirm: boolean = false;
  @observable selectedAgentUpdateStatusId: string = "";
  @observable selectedAgentUpdateStatusIdInitial: string = "";
  @observable isAgentStatusUpdated: boolean = false;

  @observable gridApiNoRows: string = "";
  @observable callDataLoadAPI: boolean = true;
  @observable searchSucess: boolean = true;

  @observable contractEntityID: number = 0;

  @observable agentUpdateStatuses = new Collection<{
    label: string;
    value: string;
  }>();

  @observable companyList = new Collection<{
    label: string;
    value: string;
  }>();

  @observable statusList = new Collection<{
    label: string;
    value: string;
  }>();

  @observable statusReasonList = new Collection<{
    label: string;
    value: string;
  }>();

  @observable advanceMonthsList = new Collection<{
    label: string;
    value: string;
  }>();
  @observable salesTypeList = new Collection<{
    label: string;
    value: string;
  }>();
  @observable payeeList = new Collection<{
    label: string;
    value: string;
  }>();
  @observable categoryList = new Collection<{
    label: string;
    value: string;
  }>();
  @observable subcategoryList = new Collection<{
    label: string;
    value: string;
  }>();
  @observable productNameList = new Collection<{
    label: string;
    value: string;
  }>();
  @observable marketerList = new Collection<{
    label: string;
    value: string;
  }>();
  @observable levelList = new Collection<{
    label: string;
    value: string;
  }>();

  @observable activeCategoryList = new Collection<{
    label: string;
    value: string;
  }>();
  @observable activeSubCategoryList: any;

  @observable tempSubcategoryList = new Collection<SubCategoryModel>();
  @observable tempProductNameList = new Collection<ProductModel>();
  @observable tempProductsList = new Collection<any>();
  @observable activeProductsList = new Collection<any>();
  @observable isWritableList: any = [
    { label: "Writable", value: "1" },
    { label: "Not Writable", value: "0" },
  ];
  @observable locations = new Collection<{
    label: string;
    value: string;
  }>();
  @observable principalAgents = new Collection<{
    label: string;
    value: string;
  }>();
  @observable notes: string = "";
  @observable noteDescription: string = "";
  @observable noteId: number = 0;
  @observable noteRowId: number = 0;
  @observable isNoteEditMode: boolean = false;
  @observable isValidationToasterVisible: boolean = false;
  @observable isCustAttrWarning: boolean = false;

  @observable CommunicationsViewModel = new CommunicationsViewModel();
  @observable ContractDocumentsViewModel = new ContractDocumentsViewModel();
  @observable ContractPaymentplansViewModel =
    new ContractPaymentplansViewModel();
  @observable ContractPersistencyViewModel = new ContractPersistencyViewModel();
  @observable ContractStatesViewModel = new ContractStatesViewModel();
  @observable AddEditPaymentPlanViewModel: AddEditPaymentPlanViewModel =
    new AddEditPaymentPlanViewModel();
  @observable isAddPaymentPlan: boolean = false;

  @observable selectedPaymentPlanId: number = 0;
  @observable filteredPayeeList: any = [];
  @observable filteredUplineList = new Collection<ImmediateUplineResponse>();
  @observable agentGroupList = new Collection<{
    label: string;
    value: string;
  }>();
  @observable processedbyList = new Collection<{
    label: string;
    value: string;
  }>();
  @observable appointmentTypeList = new Collection<{
    label: string;
    value: string;
  }>();

  @observable reportsToList = new Collection<{
    label: string;
    value: string;
  }>();
  @observable awnOptionList = new Collection<{
    label: string;
    value: string;
    selected: boolean;
  }>();
  @observable isEdit: boolean = false;
  @observable agentStatuses = [
    { label: "Select", value: "0" },
    { label: "Deceased", value: "25" },
    { label: "Not in Busines", value: "16" },
    { label: "Not For Rehire", value: "14" },
    { label: "Prospective", value: "6" },
    { label: "Retired", value: "24" },
    { label: "Terminated", value: "5" },
  ];
  @observable selectedAgentStatusId: string = "0";
  @observable selectedAgentStatusIds: string = "";
  // validation model
  @observable
  public ErrorModel = null; //new ErrorModel(new AgentContractValidator());

  @observable LicenseNumbers = new Collection<WritingNumbers>();
  @observable ProductList = new Collection<LevelListModel>();
  @observable NoteList = new Collection<Notes>();
  @observable LocationList = new Collection<LicenseLocations>();
  @observable PrincipalAgentList = new Collection<PrinicpalAgents>();
  @observable isScrollVisible: boolean = false;

  // Custom Attributes
  @observable addAttributesModal: boolean = false;
  @observable
  CustomAttributesComponentViewModel: CustomAttributesComponentViewModel =
    new CustomAttributesComponentViewModel();
  @observable isCustomAttrActive: boolean = false;
  @observable attributesScreenPath: number = 4;
  @observable attributesScreenPathId: number = 4;
  @observable custAttrToEntityList = new Collection<CustAttrToEntityModel>();
  @observable customAttributeList: any = [];
  @observable payeeSearch: string = "This Agent/ Agency";
  @observable hierarchyFilter: string = "";
  /* #endregion */
  @action setUpdateAgentStatusConfirmation = async (value: boolean) => {
    this.isUpdateAgentStatusConfirm = value;
  };

  @action loadAgentStatus = async () => {
    let res = await ProducerStore.reportAgentStatus();
    if (res) {
      res.forEach((obj: any) => {
        var data = {
          label: obj.text ? obj.text : "",
          value: obj.value ? obj.value.toString() : "",
        };

        this.agentUpdateStatuses.values.push(data);
      });
      this.selectedAgentUpdateStatusId =
        this.selectedAgentStatusIds || this.agentUpdateStatuses.values[0].value;
    } else {
      this.agentUpdateStatuses.values = [];
    }
  };

  @action setCarrierChangeConfirmation = async (value: boolean) => {
    this.isCarrierChangeConfirm = value;
  };

  @action copyUplineClick = async () => {
    this.enableCopyUplineDialog = true;
    await this.getLevelLookup();
  };

  @action copyUplineCancel(value: boolean) {
    this.enableCopyUplineDialog = value;
  }

  @action copyUpline = async () => {
    this.enableCopyUplineDialog = false;
    await this.loadContractLevelsCopyUpline();
  };

  @action copyUplineChange(value: string) {
    this.selectedCopyUpline = value;
    this.copyUplineSaveEnable = false;
    if (this.selectedCopyUpline && this.selectedCopyUpline != "0")
      this.copyUplineSaveEnable = true;
  }

  @action getLevelLookup = async () => {
    try {
      this.selectedCopyUpline = "";
      this.copyUplineLevelLists = [];
      this.copyUplineLevelLists.push({
        label: "No Level Selected",
        value: "0",
      });
      let result = null;
      result = await ContractsStore.getLevelsLookup(
        Number(this.selectedCompany),
        undefined,
        undefined,
        Number(this.uplineContractId)
      );
      if (result.payoutLevels) {
        var sortList = [...result.payoutLevels].sort(Utils.compareListItem);
        for (let item of sortList) {
          this.copyUplineLevelLists.push({
            label: "" + item.text,
            value: "" + item.value,
          });
        }
      }
    } catch (e) {
      this.copyUplineLevelLists = [];
    }
  };

  /* #region load dropdown values */
  @action loadAllLookupList = async (contractId: number, agentId: number) => {
    try {
      const result = await ContractsStore.getContractDetails(
        contractId,
        agentId
      );
      if (result != null) {
        this.carrierName = result.companyName || "";
        this.loadCompanies(result.companyList);
        this.loadPayeeList(result.payeeList);
        this.loadStatusList(result.licenseStatus);
        await this.loadSalesTypeList(result.salesTypeList);
        await this.loadAppointmentTypeList(result.licenseAppointmentType);
        this.loadAwnOptionListOnLoad(result.licenseNumberTypes);
        this.loadAgentGroupList(result.agentGroups);
        this.loadAdvanceMonthsList(result.licenseAdvanceMonths);
        Utils.mapListItemsToDropdown(
          result.principalAgentsList || [],
          this.principalAgents,
          "Select",
          "0"
        );
        Utils.mapListItemsToDropdown(
          result.locationsList || [],
          this.locations,
          "Select",
          "0"
        );
        this.activeLicenseCount = result.activeLicenseCount || 0;
        this.isAgency = result.isAgency || false;
        this.agentName = result.agent || "";
        this.paymentPlanCount = result.paymentPlanCount || 0;
        this.setUpline(result.term ? result.term : "");
        this.contractAgentName = result.uplineAgentName || "";
        this.setUplineAgentId(
          result.uplineAgentId ? "" + result.uplineAgentId : ""
        );
        this.selectedReportsTo = String(result.reportsToLicenseId);
        this.existingAgentStatus = String(result.agentStatusId);

        /// Load contract details
        if (this.contractId > 0) {
          this.contractSubmittedDate = result.submittedDate || undefined;
          this.selectedCompany = String(result.companyId);
          await this.loadProduct();
          await this.getMarketersLookup();
          await this.fillFormValues(result);
          await this.loadContractLevels();
          let tmpStatus =
            result.licenseStatus &&
            result.licenseStatus.filter((itm) => {
              return itm.value == result.statusId;
            });
          this.statusForReplaceCheck = (tmpStatus && tmpStatus[0].text) || "";
          this.replacedByLicenseId = result.replacedByLicenseId
            ? result.replacedByLicenseId.toString()
            : "";
          this.replacedFromLicenseId = result.replacedFromLicenseId
            ? result.replacedFromLicenseId.toString()
            : "";
          this.LicenseNumbers.values = result.writingNumbers || [];
          this.LocationList.values = result.locations || [];
          if (result.notes && result.notes.length > 0) {
            let nonContractingSentNotes = result.notes.filter(
              (i) => !i.contractingSentNote
            );
            nonContractingSentNotes.forEach((i) => {
              this.NoteList.values.push(i);
            });
          }
          this.noteChangeDetector++;
          this.PrincipalAgentList.values = result.principalAgents || [];

          if (result.uplineAgentId !== this.agentId)
            this.uplineContractId = result.reportsToLicenseId
              ? result.reportsToLicenseId.toString()
              : "0";
        }
      }
    } catch (e) {
      this.companyList.values = [];
      this.payeeList.values = [];
      this.statusReasonList.values = [];
      this.statusList.values = [];
      this.advanceMonthsList.values = [];
      this.awnOptionList.values = [];
      this.LicenseNumbers.values = [];
      this.reportsToList.values = [];
      this.ProductList.values = [];
      this.NoteList.values = [];
      this.LocationList.values = [];
      this.PrincipalAgentList.values = [];
    }
  };
  @action loadContractLevels = async () => {
    //this.isMessgeVisible = false;
    //this.isLoading = true;
    try {
      if (this.contractId) {
        var result = await ContractsStore.getContractLevels(this.contractId);
        let i = 1;
        if (result && result.data && result.data.length > 0) {
          result.data.forEach((element: any) => {
            i++;
            element.rowId = new Date().getTime() + i;
            element.productId = [];
            element.categoryList = [];

            element.profileProductIds = [];
            element.profileSubcategoryIds = [];
            element.profileCategoryIds = [];
            element.productIds.forEach((item: any) => {
              element.productId.push(item.productId + "");
              if (item.profileAssociation) {
                element.profileProductIds.push(item.productId + "");
              }
            });

            element.profileAssociation =
              element.profileProductIds && element.profileProductIds.length > 0;
            element.existingProductIds = element.productId;
            element.categoryList = JSON.parse(
              JSON.stringify(this.categoryList.values)
            );
            let subcategoryList = JSON.parse(
              JSON.stringify(this.tempSubcategoryList.values)
            );
            let productNameList = JSON.parse(
              JSON.stringify(this.tempProductNameList.values)
            );

            subcategoryList = subcategoryList.filter((item: any) => {
              return (
                element.categoryIds &&
                element.categoryIds.indexOf(item.categoryId) > -1
              );
            });
            productNameList =
              productNameList &&
              productNameList.filter((item: any) => {
                return (
                  element.subCategoryIds &&
                  element.subCategoryIds.indexOf(item.subCategoryId) > -1
                );
              });
            element.categoryIds = element.categoryIds.map((item: any) => {
              return item + "";
            });
            element.subCategoryIds = element.subCategoryIds.map((item: any) => {
              return item + "";
            });

            let activeProductList = productNameList.filter((i) => {
              return !i.hidden || element.productId.indexOf(i.value) > -1;
            });

            ///// Profile association code changes
            if (
              element.profileProductIds &&
              element.profileProductIds.length > 0
            ) {
              activeProductList = activeProductList.map((i: any) => {
                i.disabled = element.profileProductIds.indexOf(i.value) > -1;
                return i;
              });

              element.profileSubcategoryIds = this.tempProductNameList.values
                .filter((item) => {
                  return (
                    element.profileProductIds &&
                    element.profileProductIds.indexOf(item.value) > -1
                  );
                })
                .map((item: any) => {
                  return item.subCategoryId + "";
                });
              element.profileCategoryIds = this.tempSubcategoryList.values
                .filter((item) => {
                  return (
                    element.profileSubcategoryIds &&
                    element.profileSubcategoryIds.indexOf(item.value) > -1
                  );
                })
                .map((item: any) => {
                  return item.categoryId + "";
                });

              subcategoryList = subcategoryList.map((i: any) => {
                i.disabled =
                  element.profileSubcategoryIds.indexOf(i.value) > -1;
                return i;
              });

              element.categoryList = element.categoryList.map((i: any) => {
                i.disabled = element.profileCategoryIds.indexOf(i.value) > -1;
                return i;
              });
            }

            element.subcategoryList = this.mapListItemsAsList(
              subcategoryList,
              "",
              ""
            );
            element.productNameList = this.mapListItemsAsList(
              activeProductList,
              "",
              ""
            );
            element.existingMarketerId = element.marketerId;
          });
          this.ProductList.values = result.data;
          this.tempProductsList.values = result.data;
        } else {
          this.ProductList.values = [];
          this.tempProductsList.values = [];
        }
      }
    } catch (e) {
      //this.noLevels();
      //this.isLoading = false;
      //this.isMessgeVisible = true;
      //this.response = e.response;
    }
    //this.isLoading = false;
  };

  @action mapListItemsAsList(
    listItems: any[],
    defaultName: string = "All",
    defaultValue: string = "0"
  ) {
    var assign = [];
    if (defaultName !== "") {
      var defaultItem = { label: defaultName, value: defaultValue };
      assign.push(defaultItem);
    }
    if (listItems != null) {
      listItems.forEach((item) => {
        var data = {
          label: item.text ? item.text : "",
          value: item.value ? item.value : "",
          disabled: item.disabled ? item.disabled : false,
          hidden: item.hidden ? item.hidden : false,
        };
        assign.push(data);
      });
    }
    return assign;
  }

  @action loadContractLevelsCopyUpline = async () => {
    try {
      if (this.uplineContractId && this.selectedCopyUpline) {
        var result = await ContractsStore.getContractLevels(
          Number(this.uplineContractId)
        );
        let i = 1;
        if (result && result.data && result.data.length > 0) {
          result.data = result.data.map((i) => {
            if (i.productIds && i.productIds.length > 0) {
              i.productIds = i.productIds.filter((j) => {
                return !j.hidden;
              });
            }
            return i;
          });

          result.data = result.data.filter((i) => {
            return i.productIds && i.productIds.length > 0;
          });

          result.data.forEach((element: any) => {
            i++;
            element.rowId = new Date().getTime() + i;

            element.productId = [];
            element.productIds.forEach((item: any) => {
              element.productId.push(item.productId + "");
            });
            element.categoryList = JSON.parse(
              JSON.stringify(this.categoryList.values)
            );

            let subcategoryList = JSON.parse(
              JSON.stringify(this.tempSubcategoryList.values)
            );
            let productNameList = JSON.parse(
              JSON.stringify(this.tempProductNameList.values)
            );
            subcategoryList = subcategoryList.filter((item: any) => {
              return (
                element.categoryIds &&
                element.categoryIds.indexOf(item.categoryId) > -1
              );
            });
            productNameList = productNameList.filter((item: any) => {
              return (
                element.subCategoryIds &&
                element.subCategoryIds.indexOf(item.subCategoryId) > -1
              );
            });
            element.categoryIds = element.categoryIds.map((item: any) => {
              return item + "";
            });
            element.subCategoryIds = element.subCategoryIds.map((item: any) => {
              return item + "";
            });
            let activeProductList = productNameList.filter((i: any) => {
              return !i.hidden;
            });
            element.subcategoryList = Utils.mapListItemsAsList(
              subcategoryList,
              "",
              ""
            );
            element.productNameList = Utils.mapListItemsAsList(
              activeProductList,
              "",
              ""
            );
            element.payoutLevelId = Number(this.selectedCopyUpline);
            this.ProductList.values.push(element);
          });
          this.productDetailsChangeDetector++;
        }
      }
    } catch (e) {
      //this.noLevels();
      //this.isLoading = false;
      //this.isMessgeVisible = true;
      //this.response = e.response;
    }
    //this.isLoading = false;
  };

  @action loadProduct = async () => {
    let companyId = isNaN(parseInt(this.selectedCompany))
      ? 0
      : parseInt(this.selectedCompany);
    try {
      var result = await ContractsStore.getProductDetailsLookup(
        this.agentId,
        companyId
      );
      if (result) {
        this.tempSubcategoryList.values = result.subCategory || [];
        this.tempProductNameList.values = result.products || [];

        let productNameList = JSON.parse(
          JSON.stringify(this.tempProductNameList.values)
        );
        let subcategoryList = JSON.parse(
          JSON.stringify(this.tempSubcategoryList.values)
        );
        let categoryList = JSON.parse(JSON.stringify(result.category || []));

        let subCategoryIds = productNameList
          .filter((i: any) => {
            return !i.hidden;
          })
          .map((i: any) => i.subCategoryId + "");

        let activeSubcategories = subcategoryList.filter((item: any) => {
          return subCategoryIds && subCategoryIds.indexOf(item.value) > -1;
        });
        this.activeSubCategoryList = JSON.parse(
          JSON.stringify(activeSubcategories || [])
        );

        let categoryIds = activeSubcategories.map(
          (i: any) => i.categoryId + ""
        );

        let activeCategories = categoryList.filter((item: any) => {
          return categoryIds && categoryIds.indexOf(item.value) > -1;
        });

        if (result.contractingSentNote) {
          this.NoteList.values.push(result.contractingSentNote);
          this.noteChangeDetector++;
        }
        Utils.mapListItemsToDropdown(
          result.category || [],
          this.categoryList,
          "",
          ""
        );
        Utils.mapListItemsToDropdown(
          activeCategories || [],
          this.activeCategoryList,
          "",
          ""
        );
        Utils.mapListItemsToDropdown(
          result.payoutLevels || [],
          this.levelList,
          "Select Level*",
          "0"
        );
      }
    } catch (e) {}
  };

  @action getMarketersLookup = async () => {
    try {
      //this.isMessgeVisible = false;
      //this.marketersData = [];
      var result = await ContractsStore.getMarketersLokup();
      if (result) {
        this.marketerList.values = [];
        var filterList = result.filter(
          (v: any, i: any, a: any) =>
            a.findIndex((t: any) => t.value === v.value) === i
        );
        var sortList = [...filterList].sort(Utils.compareListItem);
        this.marketerList.values.push({
          label: "Select",
          value: "0",
        });
        for (let item of sortList) {
          this.marketerList.values.push({
            label: "" + item.text,
            value: "" + item.value,
          });
        }
      }
    } catch (e) {
      // this.isLoading = false;
      // this.marketersData = [];
      // this.response = e.response;
      // this.isMessgeVisible = true;
    }
  };

  @action loadCompanies = async (res?: any[]) => {
    if (res != null) {
      this.companyList.values = [];
      res.forEach((obj) => {
        const data = {
          label: obj.text ? obj.text : "",
          value: obj.value ? obj.value : "",
        };
        this.companyList.values.push(data);
      });
    } else {
      this.companyList.values = [];
    }
  };

  @action loadPayeeList = async (res?: any[]) => {
    if (res != null) {
      this.payeeList.values = [];
      this.payeeList.values.push({
        label: "This Agent/ Agency",
        value: String(""),
      });
      res.forEach((obj) => {
        const data = {
          label: obj.payeeAgentFullName
            ? obj.payeeAgentFullName + "-" + obj.payeeAgentId
            : "",
          value: obj.payeeAgentId ? String(obj.payeeAgentId) : "",
        };
        this.payeeList.values.push(data);
      });
    } else {
      this.payeeList.values = [];
    }
  };

  @action loadSalesTypeList = async (res?: any[]) => {
    if (res != null) {
      this.salesTypeList.values = [];
      res.forEach((obj) => {
        const data = {
          label: obj.text ? obj.text : "",
          value: obj.value ? obj.value : "",
        };
        this.salesTypeList.values.push(data);
      });
      if (this.salesTypeList.values.length > 0) {
        this.selectedSalesType = this.salesTypeList.values[0].value;
      } else {
        this.selectedSalesType = "";
      }
    } else {
      this.salesTypeList.values = [];
    }
  };

  @action loadAppointmentTypeList = async (res?: any[]) => {
    if (res != null) {
      this.appointmentTypeList.values = [];
      Utils.mapListItemsToDropdown(res, this.appointmentTypeList, "Select", "");
    } else {
      this.appointmentTypeList.values = [];
    }
  };

  @action loadAgentGroupList = async (res?: any[]) => {
    if (res != null) {
      this.agentGroupList.values = [];
      Utils.mapListItemsToDropdown(
        res,
        this.agentGroupList,
        "Select the Agent group",
        "0"
      );
    } else {
      this.agentGroupList.values = [];
    }
  };

  @action loadStatusList = async (res?: any[]) => {
    if (res != null) {
      this.statusList.values = [];
      res.forEach((obj) => {
        const data = {
          label: obj.text ? obj.text : "",
          value: obj.value ? obj.value : "",
        };
        this.statusList.values.push(data);
      });
    } else {
      this.statusList.values = [];
    }
  };

  @action loadStatusReasonList = async (data?: any[]) => {
    if (data != null) {
      const unSortList: { text?: string; label: string; value: string }[] = [];
      this.statusReasonList.values = [];
      data.forEach((obj) => {
        unSortList.push({
          text: "" + obj.statusReason,
          label: "" + obj.statusReason,
          value: "" + obj.statusReasonId,
        });
      });

      let temp = [...unSortList].sort(Utils.compareListItem).map((v) => {
        delete v.text;
        return v;
      });

      this.statusReasonList.values = temp;

      this.selectedStatusReason = this.selectedStatusReason.toString();
    } else {
      this.statusReasonList.values = [];
    }
  };

  @action loadStatusReasonListByStatus = async (
    statusId: string,
    selectedStatusReasonId: string
  ) => {
    this.statusReasonList.values = [];
    try {
      const result = await ContractsStore.loadStatusReasonListByStatus(
        parseInt(statusId),
        false
      );
      if (result != null) {
        this.loadStatusReasonList(result);
      }
      this.selectedStatusReason = selectedStatusReasonId.toString();
    } catch (e) {
      this.statusReasonList.values = [];
    }
  };

  @action loadAdvanceMonthsList = async (res?: any[]) => {
    if (res != null) {
      this.advanceMonthsList.values = [];
      Utils.mapListItemsToDropdown(
        res,
        this.advanceMonthsList,
        "Select an Option",
        "0"
      );
    } else {
      this.advanceMonthsList.values = [];
    }
  };

  @action searchReportsTo = async (value: string) => {
    try {
      const result = await ContractsStore.getImmediateUpline(
        this.contractId,
        this.agentId,
        0,
        100,
        "Id",
        true,
        value,
        +this.selectedCompany,
        this.showActiveContract
      );
      this.loadReportsToList(result);
    } catch (e) {
      const noAgent: ImmediateUplineResponse = { agentId: 0 };
      this.filteredUplineList.values = [noAgent];
    }
  };

  @action loadReportsToList = async (data?: any[], value?: string) => {
    if (data != null && data.length > 0) {
      this.reportsToList.values = [];
      this.filteredUplineList.values = data;
      if (value) {
        this.setUpline(value);
      }
    } else {
      const noAgent: ImmediateUplineResponse = { agentId: 0 };
      this.filteredUplineList.values = [noAgent];
    }
  };

  @action loadAwnOptionList = async (res?: any[]) => {
    if (res != null) {
      this.awnOptionList.values = [];
      let awnData: {
        label: string;
        value: string;
        selected: boolean;
      }[] = [];
      awnData.push({ label: "Select", value: "0", selected: false });
      res.forEach((obj) => {
        const data = {
          label: obj.text ? obj.text : "",
          value: obj.value ? obj.value : "",
          selected: obj.selected ? obj.selected : false,
        };
        if (awnData.filter((ind) => ind.label === obj.text).length === 0) {
          awnData.push(data);
        }
      });
      this.awnOptionList.values = awnData;
    } else {
      this.awnOptionList.values = [];
    }
  };
  @action loadAwnOptionListOnLoad = async (res?: any[]) => {
    if (res != null) {
      this.awnOptionList.values = [];
      let awnData: {
        label: string;
        value: string;
        selected: boolean;
      }[] = [];
      awnData.push({ label: "Select", value: "0", selected: false });
      res.forEach((obj) => {
        const data = {
          label: obj.licenseNumberType ? obj.licenseNumberType : "",
          value: obj.id ? obj.id + "" : "",
          selected: obj.isWritableDefault ? obj.isWritableDefault : false,
        };
        if (
          awnData.filter((ind) => ind.label === obj.licenseNumberType)
            .length === 0
        ) {
          awnData.push(data);
        }
      });
      this.awnOptionList.values = awnData;
    } else {
      this.awnOptionList.values = [];
    }
  };
  @action loadUsers = async (value: string) => {
    if (value && value.length >= 3) {
      const result = await IdentityStore.getAllUsers(value);
      let values = null;
      if (result !== null) {
        values = result;
      }
      if (values !== undefined && values !== null) {
        this.processedbyList.values = this.mapListItemAndSort(values);
      } else {
        this.processedbyList.values = [];
      }
    }
  };
  @action mapListItemAndSort(listObjects: any) {
    if (listObjects) {
      const sortList = listObjects.sort(Utils.compareListItem);
      sortList.forEach((element: any) => {
        element.label = element.text;
      });
      return sortList;
    } else {
      return [];
    }
  }
  @action goToContracts = () => {
    this.carrierName = "";
    routes.agentContracts.replace({ agentId: "" + this.agentId });
  };
  /* #endregion */

  /* #region set values */
  @action setCancelChangesConfirmation(value: boolean) {
    this.isCancelChangesConfirmation = value;
  }

  @action setProductDetailSelection(productList: any) {
    if (Array.isArray(productList)) {
      let list = productList.filter((item) => {
        return !item.profileAssociation;
      });
      this.productDetailSelection = list;
    }
  }

  @action setUpline(value: string) {
    this.enableCopyUpline = false;
    this.selectedeUpline = value;
    if (!value) {
      this.setSelectedReportsTo("0");
    }
  }

  @action setUplineAgentId(value: string) {
    this.selectedUplineAgentId = value;
    this.enableCopyUpline = false;
    this.copyUplineEnable(value);
  }

  @action copyUplineEnable(value: string) {
    if (
      value &&
      Number(value) !== this.agentId &&
      this.selectedCompany &&
      this.selectedCompany != "0"
    ) {
      this.enableCopyUpline = true;
    }
  }

  @action payeeAccess = () => {
    let payeeAccess = false;
    if (this.isEdit) {
      const regardlessPaymentPlan = Utils.hasUserPermission(
        Permission.ChangePayeeRegardlessOfExistingPaymentPlan
      );
      const hasMaymentplan = Utils.hasUserPermission(
        Permission.ChangePayeeWithoutPaymentPlan
      );
      if (
        (!regardlessPaymentPlan && !hasMaymentplan) ||
        (!regardlessPaymentPlan && hasMaymentplan && this.paymentPlanCount > 0)
      ) {
        payeeAccess = true;
      }
    }
    return payeeAccess;
  };

  @action fillFormValues = async (result: ContractModel) => {
    this.enteredDate = result.enteredDate;
    this.createdByName = result.createdBy || undefined;
    this.modifiedByName = result.modifiedBy || undefined;
    this.modifiedDate = result.modifiedOn;
    this.endDate =
      result.endDate && "" + result.endDate !== "0001-01-01T00:00:00"
        ? result.endDate
        : undefined;

    if (this.selectedStatus && this.selectedStatus !== "2") {
      this.endDate = undefined;
    }
    this.startDate = new Date(result.startDate || new Date());
    this.selectedCompany = String(result.companyId);
    RouteParamDetails.carrierId = result.companyId;
    this.carrierName = result.companyName || "";
    this.existingStatus = String(result.statusId);
    this.existingAgentStatus = String(result.agentStatusId);
    this.selectedStatus = String(result.statusId);
    this.selectedStatusInitial = String(result.statusId);
    this.selectedSalesType = String(result.saleTypeId);
    this.selectedPayee = result.payeeId ? String(result.payeeId) : String("");
    this.existingPayee = result.payeeId ? String(result.payeeId) : String("");
    this.selectedPayeeLabel = "";
    this.selectedAdvanceMonth = String(result.licenseAdvancedId);
    this.isAgencyIsPayee = result.agnecyIsPayee || false;
    this.setUpline(result.term ? result.term : "");
    this.setUplineAgentId("" + result.uplineAgentId);
    this.contractAgentName = result.uplineAgentName || "";
    //this.notes = result.;
    this.selectedAgentGroupId = result.agentGroupId
      ? String(result.agentGroupId)
      : "0";
    this.isDoNotCall = result.doNotCall || false;
    this.isDoNotEmail = result.doNotEmail || false;
    this.isDoNotMarket = result.doNotMarket || false;
    this.isDoNotCallDownline = result.doNotCallDownlineDefault || false;
    this.isDoNotEmailDownline = result.doNotEmailDownlineDefault || false;
    this.isDoNotMarketDownline = result.doNotMarketDownlineDefault || false;
    this.existingIsDoNotCallDownline = result.doNotCallDownlineDefault || false;
    this.existingIsDoNotEmailDownline =
      result.doNotEmailDownlineDefault || false;
    this.existingIsDoNotMarketDownline =
      result.doNotMarketDownlineDefault || false;
    if (result.statusReasonList) {
      this.statusReasonList.values = [];
      this.statusReasonList.values = Utils.mapListItemsAsList(
        result.statusReasonList,
        ""
      );
      this.selectedStatusReason = "" + result.statusReasonId || "";
    }
    this.selectedProcessedbyId = result.processedBy
      ? "" + result.processedBy
      : "";
    this.selectedProcessedby = result.processedByName || "";
    this.contractReciptDate = result.contractReceiptDate || undefined;
    this.approvalDate = result.approvalDate || undefined;
    this.applicationSubmittedwithin90days =
      result.appSubmittedWithin90Days || undefined;
    this.selectedAppointmentType = result.appointmentType
      ? "" + result.appointmentType
      : "";
    this.downlineContractCount = result.downlinesCount;
    this.contractStatusId = result.statusId;
    this.showActiveContract = result.onlyShowActiveAndPending || false;
    RouteParamDetails.reportToLicenseId = result.reportsToLicenseId;
    RouteParamDetails.uplineAgentId = result.uplineAgentId;
    this.selectedAgentStatusIds = "" + result.agentStatusId || "";
    this.selectedAgentUpdateStatusIdInitial = "" + result.agentStatusId || "";
  };

  @action searchPayee = async (value: string) => {
    let result = this.payeeList.values.filter((x) =>
      x.label.toLowerCase().includes(value.toLowerCase())
    );
    this.filteredPayeeList = result;
  };
  @action setSelectedPayee(data: any) {
    this.selectedPayee = data;
  }
  @action setSelectedPayeeValue(data: any) {
    this.selectedPayeeLabel = data;
  }
  @action carrierChange = async (value: string) => {
    this.selectedCompany = value;
    this.enableSave();
    if (!this.isEdit) {
      this.LicenseNumbers.values = [];
      this.ProductList.values = [];
      this.NoteList.values = [];
      this.setAwnOptionsList();
      this.loadProduct();
      this.getMarketersLookup();
      this.copyUplineEnable(this.selectedUplineAgentId);
    }
  };

  @action enableSave = async () => {
    this.isSave = false;
    let productList = this.ProductList.values.filter((item: any) => {
      return (
        !(item.categoryIds && item.categoryIds.length > 0) ||
        !(item.subCategoryIds && item.subCategoryIds.length > 0) ||
        !item.productId ||
        !(item.productId && item.productId.length > 0) ||
        !item.marketerId ||
        !item.payoutLevelId ||
        item.payoutLevelId + "" == "0"
      );
    });
    let licenseNumbers = this.LicenseNumbers.values.filter((item: any) => {
      return !item.licenseNumberTypeId || !item.number || item.isDuplicate;
    });
    let notes = this.NoteList.values.filter((item) => {
      return !item.note;
    });
    let locations = this.LocationList.values.filter((item) => {
      return !item.locationId;
    });
    let principalAgentsList = this.PrincipalAgentList.values.filter((item) => {
      return !item.principalAgentId;
    });

    if (
      this.selectedCompany &&
      this.selectedStatus &&
      this.selectedStatusReason &&
      this.selectedStatusReason != "0" &&
      this.selectedSalesType &&
      this.selectedReportsTo &&
      this.selectedReportsTo != "0" &&
      productList.length == 0 &&
      licenseNumbers.length == 0 &&
      notes.length == 0 &&
      locations.length == 0 &&
      principalAgentsList.length == 0 &&
      !this.isValidationToasterVisible &&
      !this.isEndDateInvalid
    )
      this.isSave = true;
  };

  @action setAwnOptionsList = async () => {
    if (this.selectedCompany) {
      const result =
        await ContractsStore.getAllCompanyLicenseNumberDefaultsLookup(
          +this.selectedCompany
        );
      await this.loadAwnOptionList(result);
    }
  };

  @action resetFormFields() {
    this.isAgentStatusUpdated = false;
    this.showActiveContract = false;
    this.terminateDownlineContract = false;
    this.downlineContractCount = 0;
    this.contractStatusId = 0;
    this.isAgentHierarchyLoaded = false;
    this.contractSubmittedDate = undefined;
    this.selectedAgentStatusId = "0";
    this.selectedCompany = "";
    this.selectedStatusReason = "";
    this.selectedAgentGroupId = "0";
    this.selectedStatus = "";
    this.existingStatus = "";
    this.existingAgentStatus = "";
    this.endDate = undefined;
    this.isEndDateInvalid = false;
    this.startDate = new Date();
    this.enteredDate = new Date();
    this.modifiedDate = new Date();
    this.createdByName = undefined;
    this.modifiedByName = undefined;
    this.selectedPayee = "";
    this.selectedPayeeLabel = "";
    this.selectedReportsTo = "0";
    this.selectedeUpline = "";
    this.selectedAdvanceMonth = "0";
    this.selectedSalesType = "";
    this.isAgencyIsPayee = false;
    this.isEndDateRequired = false;
    this.notes = "";
    this.isDoNotCall = false;
    this.isDoNotEmail = false;
    this.isDoNotMarket = false;
    this.isDoNotCallDownline = false;
    this.isDoNotEmailDownline = false;
    this.isDoNotMarketDownline = false;
    this.selectedProcessedby = "";
    this.contractReciptDate = undefined;
    this.approvalDate = undefined;
    this.applicationSubmittedwithin90days = undefined;
    this.selectedAppointmentType = "";
    this.isFromViewContract = false;
    this.isEndDateRequired = false;
    this.isDeleteConfirm = false;
    this.deleteContractContext = "";
    this.deleteConfirmTitle = "";
    this.isAddNoteConfirm = false;
    this.enableCopyUpline = false;
    this.enableCopyUplineDialog = false;

    this.companyList.values = [];
    this.statusList.values = [];
    this.statusReasonList.values = [];
    this.payeeList.values = [];
    this.filteredPayeeList.values = [];
    this.advanceMonthsList.values = [];
    this.awnOptionList.values = [];
    this.reportsToList.values = [];
    this.filteredUplineList.values = [];
    this.LicenseNumbers.values = [];
    this.ProductList.values = [];
    this.NoteList.values = [];
    this.LocationList.values = [];
    this.PrincipalAgentList.values = [];
    this.categoryList.values = [];
    this.marketerList.values = [];
    this.levelList.values = [];

    this.setIsPayeeChange(false);
    this.isCustomAttrActive = false;
    PageContext.isCustomAttribute = false;
  }

  @action setEndSelectDate(value: Date) {
    this.endDate = value || undefined;
    this.customValidation();
    this.enableSave();
  }
  @action setStartSelectDate(value: Date) {
    this.startDate = value;
    this.customValidation();
    this.enableSave();
  }
  @action setSelectedReportsTo(value: string) {
    this.selectedReportsTo = value;
    this.enableSave();
  }

  @action appointmentTypechange = async (value: string) => {
    this.selectedAppointmentType = value;
  };

  @action setIsPayeeChange(value: boolean) {
    this.isPayeeChange = value;
  }

  @action setIsPayeeChangeConfirm(value: boolean) {
    this.isPayeeChangeConirm = value;
  }

  @action payeeChangeConfirm() {
    this.setIsPayeeChange(true);
    this.setIsPayeeChangeConfirm(false);
    this.saveContract();
  }

  @action async setUpdateContractwithDNxDisplay(val: boolean) {
    this.isUpdateDownlineDiloagDisplay = val;
  }

  @action terminateDownlineContractDialog = (value: boolean) => {
    this.terminateDownlineContract = value;
  };

  @action setNotificationSendVisibility = (value: boolean) => {
    this.notificationSendVisibility = value;
  };

  @action loadEmailNotificationTemplates = async () => {
    const activeNotificationTemplates =
      await NotificationStore.getEmailTemplates(
        this.startIndexForNotification,
        this.pageSizeForNotification,
        this.sortColumnForNotification,
        this.sortOrderForNotification,
        "",
        this.defaultStatusForNotification
      );
    if (activeNotificationTemplates) {
      this.NotificationTemplatesList.values =
        activeNotificationTemplates.data || [];
      this.totalNotificationRowsCount =
        activeNotificationTemplates.recordCount || 0;
      if (this.NotificationTemplatesList.values.length > 0) {
        var results = this.NotificationTemplatesList.values.filter(
          (item: any) => {
            return (
              item.name.toLowerCase() ==
                NotificationTemplateName.ContractUpdateTOH.toLowerCase() ||
              item.name.toLowerCase() ==
                NotificationTemplateName.ContractUpdateLoginUser.toLowerCase()
            );
          }
        );
        this.isEmailNotificationActive =
          results && results.length > 0 ? true : false;
      } else {
        this.isEmailNotificationActive = false;
      }
    } else {
      this.isEmailNotificationActive = false;
    }
  };

  @action setNotes(value: string) {
    this.notes = value;
  }
  @action setNoteDescription(value: string) {
    this.noteDescription = value;
  }

  @action setIsNoteEditMode(value: boolean) {
    this.isNoteEditMode = value;
  }

  @action setContractModified() {
    //this.isNoteEditMode = value;
  }

  @action setIsAddPaymentPlan(value: boolean) {
    this.isAddPaymentPlan = value;
  }

  @action setSelectedPanel = async (value: string) => {
    this.selectedPanel = value;
  };

  @action editPaymentplan = (paymentPlanId: number) => {
    this.selectedPaymentPlanId = paymentPlanId;
  };

  validateUpline() {
    if (this.selectedReportsTo) {
      if (this.selectedReportsTo === "0") {
        this.selectedeUpline = "";
      }
    } else {
      this.selectedeUpline = "";
    }
  }
  /* #endregion */

  /* #region action button click methods */
  @action addProduct() {
    let rowData = {
      rowId: new Date().getTime() + this.rowId++,
      levelId: 0,
      productId: undefined,
      productName: undefined,
      categoryName: undefined,
      categoryIds: [] as string[],
      subCategoryName: undefined,
      subCategoryIds: [],
      specialInstructions: "",
      commissions: 0,
      payoutLevelId: 0,
      description: undefined,
      documentId: 0,
      marketer: undefined,
      marketerId: 0,
      subcategoryList: [] as { label: string; value: string }[],
    } as any;

    rowData.categoryIds = this.activeCategoryList.values.map((item: any) => {
      return item.value + "";
    });

    rowData.categoryList = JSON.parse(
      JSON.stringify(this.activeCategoryList.values)
    );

    let subcategoryList: any = [];
    if (rowData.categoryIds && rowData.categoryIds.length > 0) {
      subcategoryList = this.activeSubCategoryList.filter((item: any) => {
        return (
          rowData.categoryIds &&
          rowData.categoryIds.indexOf(item.categoryId + "") > -1
        );
      });
    }
    rowData.subcategoryList = this.mapListItemsAsList(subcategoryList, "", "");
    this.ProductList.values.push(rowData);
    this.isSave = false;
  }

  @action addWritingNumber() {
    let rowData = {
      number: "",
      rowId: new Date().getTime() + this.rowId++,
      writingNumberId: 0,
      isWritable: false,
      licenseNumberTypeId: 0,
      licenseNumberTypeName: "",
    } as WritingNumbers;
    this.LicenseNumbers.values.push(rowData);
    this.isSave = false;
  }
  rowId: number = 1;
  @action addNote() {
    let rowData = {
      rowId: new Date().getTime() + this.rowId++,
      id: 0,
      contractingSentNote: false,
      note: this.noteDescription,
      createdDate: new Date(),
      createdUser: loggedInUser.displayName,
    } as Notes;
    this.NoteList.values.push(rowData);
    this.isSave = false;
  }

  @action addLocation() {
    let rowData = {
      rowId: new Date().getTime() + this.rowId++,
      id: 0,
      agentName: undefined,
      dba: "",
      locationId: 0,
      lastName: "",
    } as LicenseLocations;
    this.LocationList.values.push(rowData);
    this.isSave = false;
  }

  @action addPrincipalAgent() {
    let rowData = {
      rowId: new Date().getTime() + this.rowId++,
      id: 0,
      lastName: "",
      principalAgentId: 0,
      principalAgentName: "",
    } as PrinicpalAgents;
    this.PrincipalAgentList.values.push(rowData);
    this.isSave = false;
  }

  @observable deleteType: string = "";
  @observable selectedDeleteRowData: any;
  @action setAddNoteConfirmation(value: boolean) {
    this.isAddNoteConfirm = value;
  }
  @action setDeleteConfirmation(value: boolean, type?: string, rowData?: any) {
    this.isDeleteConfirm = value;
    if (value) {
      this.deleteType = type || "";
      this.selectedDeleteRowData = rowData;
      switch (this.deleteType) {
        case "Product":
          this.deleteConfirmTitle = "Delete Product";
          this.deleteContractContext =
            "Are you sure you want to delete this product?";
          break;
        case "ProductList":
          this.deleteConfirmTitle = "Delete Products";
          this.deleteContractContext =
            "Are you sure you want to delete selected products?";
          break;
        case "WritingNumber":
          this.deleteConfirmTitle = "Delete Writing Number";
          this.deleteContractContext =
            "Are you sure you want to delete this writing number?";
          break;
        case "Note":
          this.deleteConfirmTitle = "Delete Note";
          this.deleteContractContext =
            "Are you sure you want to delete this note?";
          break;
        case "Location":
          this.deleteConfirmTitle = "Delete Location";
          this.deleteContractContext =
            "Are you sure you want to delete this location?";
          break;
        case "PrincipalAgent":
          this.deleteConfirmTitle = "Delete Principal Agent";
          this.deleteContractContext =
            "Are you sure you want to delete this principal agent?";
          break;
      }
    } else {
      this.deleteType = "";
      this.selectedDeleteRowData = undefined;
      this.deleteContractContext = "";
    }
  }
  @action deleteItem = () => {
    let rowData = this.selectedDeleteRowData;
    switch (this.deleteType) {
      case "Product":
        if (rowData.levelId && rowData.levelId > 0) {
          this.ProductList.values = this.ProductList.values.filter((i: any) => {
            return i.levelId != rowData.levelId;
          });
        } else {
          this.ProductList.values = this.ProductList.values.filter((i: any) => {
            return i.rowId != rowData.rowId;
          });
        }
        break;
      case "ProductList":
        rowData.forEach((element: any) => {
          if (element.levelId && element.levelId > 0) {
            this.ProductList.values = this.ProductList.values.filter(
              (i: any) => {
                return i.levelId != element.levelId;
              }
            );
          } else {
            this.ProductList.values = this.ProductList.values.filter(
              (i: any) => {
                return i.rowId != element.rowId;
              }
            );
          }
        });
        this.productDetailSelection = [];

        break;
      case "WritingNumber":
        if (rowData.writingNumberId && rowData.writingNumberId > 0) {
          this.LicenseNumbers.values = this.LicenseNumbers.values.filter(
            (i: any) => {
              return i.writingNumberId != rowData.writingNumberId;
            }
          );
        } else {
          this.LicenseNumbers.values = this.LicenseNumbers.values.filter(
            (i: any) => {
              return i.rowId != rowData.rowId;
            }
          );
        }
        break;
      case "Note":
        if (rowData.id && rowData.id > 0) {
          this.NoteList.values = this.NoteList.values.filter((i: any) => {
            return i.id != rowData.id;
          });
        } else {
          this.NoteList.values = this.NoteList.values.filter((i: any) => {
            return i.rowId != rowData.rowId;
          });
        }
        break;
      case "Location":
        if (rowData.id && rowData.id > 0) {
          this.LocationList.values = this.LocationList.values.filter(
            (i: any) => {
              return i.id != rowData.id;
            }
          );
        } else {
          this.LocationList.values = this.LocationList.values.filter(
            (i: any) => {
              return i.rowId != rowData.rowId;
            }
          );
        }
        break;
      case "PrincipalAgent":
        if (rowData.id && rowData.id > 0) {
          this.PrincipalAgentList.values =
            this.PrincipalAgentList.values.filter((i: any) => {
              return i.id != rowData.id;
            });
        } else {
          this.PrincipalAgentList.values =
            this.PrincipalAgentList.values.filter((i: any) => {
              return i.rowId != rowData.rowId;
            });
        }
        break;
    }
    this.setDeleteConfirmation(false);
    this.enableSave();
  };

  /* #endregion */

  @action async updateContractwithDNx(val: boolean) {
    this.setUpdateContractwithDNxDisplay(false);
    this.isUpdateDownlineDNx = val;
    this.saveContract();
  }

  @action async saveContractwithDNx() {
    if (
      this.contractId !== 0 &&
      (this.isDoNotCallDownline !== this.existingIsDoNotCallDownline ||
        this.isDoNotEmailDownline !== this.existingIsDoNotEmailDownline ||
        this.isDoNotMarketDownline !== this.existingIsDoNotMarketDownline)
    ) {
      this.setUpdateContractwithDNxDisplay(true);
    } else {
      await this.saveContract();
    }
  }

  @action customValidation() {
    let isValid: boolean = true;
    if (!this.endDate && this.selectedStatus === "2") {
      isValid = false;
      this.isEndDateRequired = true;
    } else {
      this.isEndDateRequired = false;
    }

    if (this.endDate && this.startDate <= new Date(this.endDate)) {
      this.isEndDateInvalid = false;
    } else {
      if (this.selectedStatus !== "2") {
        this.isEndDateInvalid = false;
      } else {
        this.isEndDateInvalid = true;
      }
    }
    return isValid && !this.isEndDateInvalid;
  }

  @action validateDuplicateWritingNumbers = () => {
    this.LicenseNumbers.values.forEach((item: any) => {
      let duplicateList: any = this.LicenseNumbers.values.filter(
        (writeNumber: any) => {
          return (
            item.number &&
            writeNumber.number &&
            item.number.toLowerCase() == writeNumber.number.toLowerCase()
          );
        }
      );
      item.isDuplicate =
        duplicateList &&
        duplicateList.length > 1 &&
        (item.rowId
          ? item.rowId == duplicateList[1].rowId
          : item.writingNumberId == duplicateList[1].writingNumberId);
      item.duplicateNumber = "";
      if (item.isDuplicate) {
        item.duplicateNumber = item.number;
      }
    });
    this.writingNumberChangeDetector++;
    this.enableSave();
  };

  @action validateControls() {
    this.validateUpline();
    const isValid = this.customValidation();
    if (
      !this.terminateDownlineContract &&
      this.contractId != 0 &&
      (this.contractStatusId == 1 || this.contractStatusId == 3) &&
      parseInt(this.selectedStatus) == 2 &&
      this.downlineContractCount != undefined &&
      this.downlineContractCount > 0
    ) {
      this.terminateDownlineContractDialog(true);
      return;
    }
    this.terminateDownlineContractDialog(false);

    if (this.isEdit) {
      if (!localStorage.getItem("byPassRedirect")) {
        if (!this.isPayeeChange && this.selectedPayee !== this.existingPayee) {
          this.setIsPayeeChangeConfirm(true);
          return;
        }
      }
    }
    return isValid;
  }
  @action validateCustomAttributes = () => {
    let isValid2: boolean = true;
    if (this.customAttributeList) {
      var mandatoryCustomAttributeData = (
        this.customAttributeList || []
      ).filter(
        (x: any) =>
          x.isMandatory == true &&
          (x.attrValue == null || x.attrValue == "" || x.attrValue == undefined)
      );
      if (
        mandatoryCustomAttributeData != null &&
        mandatoryCustomAttributeData.length > 0 &&
        (Utils.hasUserPermission(Permission.PrivateCustomAttribute) ||
          Utils.hasUserPermission(Permission.PublicCustomAttribute))
      ) {
        isValid2 = false;
        this.isCustAttrWarning = true;
        // this.isCustAttrWarning && window.scrollTo(0, document.body.scrollHeight);
        toast.warning(`Please fill all required fields for custom attributes`, {
          position: toast.POSITION.TOP_RIGHT,
          closeOnClick: false,
          closeButton: closeToasterButtonTemplate(this.hideValidationToaster),
          className: "validation-toaster contract",
          draggable: false,
          toastId: "validation-toaster",
        });
        return;
      } else {
        toast.dismiss("validation-toaster");
        isValid2 = true;
        this.isCustAttrWarning = false;
      }
    }
    return isValid2;
  };
  @action saveContract = async () => {
    PageContext.isMessageVisible = false;
    if (this.validateControls()) {
      let model = this.createModel();
      let status: boolean = false;
      if (this.contractId && this.contractId > 0) {
        status = await this.createUpdateContract(true, model);
      } else {
        status = await this.createUpdateContract(false, model);
      }
      if (status) {
        this.isSave = false;

        if (this.isFromViewContract || this.isEdit) {
          if (
            (this.existingStatus != this.selectedStatus &&
              (this.selectedStatus == "1" || this.selectedStatus == "4")) ||
            this.isAgentStatusUpdated
          ) {
            await this.updateAgentStatus(+this.agentId);
            this.isAgentStatusUpdated = false;
          }

          Utils.showSuccessToaster("Contract updated.", 3000, 500);
          routes.contractDetails.push({
            agentId: "" + this.agentId,
            contractId: this.contractId,
          });
        } else {
          if (
            (this.existingAgentStatus == "2" ||
              this.existingAgentStatus == "6") &&
            (this.selectedStatus == "1" ||
              this.selectedStatus == "3" ||
              this.selectedStatus == "4")
          ) {
            await this.updateAgentStatus(+this.agentId);
          }

          Utils.showSuccessToaster("Contract created.", 3000, 500);
          routes.contractDetails.push({
            agentId: "" + this.agentId,
            contractId: this.contractEntityID,
          });
        }
      }
    }
  };

  @action createModel() {
    let id = 0;

    if (this.contractId && this.contractId !== 0) {
      id = this.contractId;
    }
    RouteParamDetails.carrierId = this.selectedCompany
      ? parseInt(this.selectedCompany)
      : 0;
    let productDetailsList: ProductDetailsModel[] = [];
    if (this.ProductList.values.length > 0) {
      this.ProductList.values.forEach((element: any, index: number) => {
        let existingProducts = this.tempProductsList.values.filter((item) => {
          return item.rowId == element.rowId;
        });
        element.productId.forEach((product: any) => {
          let selectedProduct: ProductDetailsModel;
          let levelId = 0;
          if (this.isEdit) {
            if (existingProducts && existingProducts.length > 0) {
              let level = existingProducts[0].productIds.filter((l: any) => {
                return l.productId == product;
              });
              levelId = level && level.length > 0 ? level[0].levelId : 0;
            }
          }

          selectedProduct = {
            levelId: levelId,
            productId: product,
            marketerId: element.marketerId,
            existingMarketerId: element.existingMarketerId,
            payoutLevelId: element.payoutLevelId,
            specialInstructions: element.specialInstructions,
            sortOrder: index + 1,
          } as ProductDetailsModel;
          productDetailsList.push(selectedProduct);
        });
      });
    }

    let model = {
      id: id,
      agentId: this.agentId,
      companyId: this.selectedCompany ? parseInt(this.selectedCompany) : 0,
      statusId: this.selectedStatus ? parseInt(this.selectedStatus) : 0,
      statusReasonId: this.selectedStatusReason
        ? parseInt(this.selectedStatusReason)
        : 0,
      apptDate: this.startDate,
      termDate: this.endDate ? new Date(this.endDate) : undefined,
      payeeId: this.selectedPayee ? parseInt(this.selectedPayee) : 0,
      advancedMonths: this.selectedAdvanceMonth
        ? parseInt(this.selectedAdvanceMonth)
        : 0,
      agencyIsPayee: this.isAgencyIsPayee,
      reportsToLicenseId: this.selectedReportsTo
        ? parseInt(this.selectedReportsTo)
        : 0,
      salesTypeId: this.selectedSalesType
        ? parseInt(this.selectedSalesType)
        : 1,
      leadId: 0,
      doNotCall: this.isDoNotCall,
      doNotEmail: this.isDoNotEmail,
      doNotMarket: this.isDoNotMarket,
      doNotCallDownlineDefault: this.isDoNotCallDownline,
      doNotEmailDownlineDefault: this.isDoNotEmailDownline,
      doNotMarketDownlineDefault: this.isDoNotMarketDownline,
      isUpdateDownlineDNx: this.isUpdateDownlineDNx,
      agentStatusId: this.isAgentStatusUpdated
        ? +this.selectedAgentUpdateStatusId
        : 0,
      submittedDate: this.contractSubmittedDate || undefined,
      processedBy:
        this.selectedProcessedby && this.selectedProcessedbyId
          ? +this.selectedProcessedbyId
          : null,
      contractReceiptDate: this.contractReciptDate,
      approvalDate: this.approvalDate,
      appSubmittedWithin90Days: this.applicationSubmittedwithin90days,
      appointmentType: this.selectedAppointmentType
        ? +this.selectedAppointmentType
        : undefined,
      agentGroupId: this.selectedAgentGroupId
        ? parseInt(this.selectedAgentGroupId)
        : 0,
      onlyShowActiveAndPending: this.showActiveContract,
      writingNumbers: this.LicenseNumbers.values,
      productDetails: productDetailsList,
      notes:
        this.NoteList.values &&
        this.NoteList.values.map((obj: any) => {
          if (!obj.contractingSentNote) {
            return {
              id: obj.id,
              note: obj.note,
              contractingSentNote: obj.contractingSentNote,
              createdDate: obj.createdDate,
            };
          }
        }),
      locations: this.LocationList.values,
      prinicpalAgents: this.PrincipalAgentList.values,
      isEmailNotificationSend: this.isEmailNotificationSend,
    } as CreateUpdateContractModel;
    if (model.notes && model.notes[0] == undefined) model.notes.shift();

    return model;
  }

  @action handleTabClick = async (index: number | undefined, key: string) => {
    this.selectedTabIndex = index || 0;
    this.setSelectedPanel(key);
    switch (this.selectedPanel) {
      case ContractDetails.Info:
        break;
      case ContractDetails.Hierarchy:
        this.isAgentHierarchyLoaded = true;
        window.addEventListener("resize", this.listenScrollEvent);
        break;
      case ContractDetails.Documents:
        this.ContractDocumentsViewModel.Load(this.contractId, this.agentId);
        break;
      case ContractDetails.Communications:
        this.CommunicationsViewModel.Load(this.contractId, this.agentId);
        break;
      case ContractDetails.PaymentPlans:
        this.isAddPaymentPlan = false;
        routes.editContractPaymentPlans.replace({
          agentId: "" + this.agentId,
          contractId: this.contractId ? this.contractId : 0,
        });
        break;
      case ContractDetails.Persistency:
        this.ContractPersistencyViewModel.Load(this.contractId, this.agentId);
        break;
      case ContractDetails.States:
        this.ContractStatesViewModel.Load(this.contractId, this.agentId);
        break;
    }
  };

  @action async createUpdateContract(
    isEdit: boolean,
    body?: CreateUpdateContractModel | undefined
  ): Promise<any> {
    PageContext.isMessageVisible = false;

    if (
      Utils.hasUserPermission(Permission.PrivateCustomAttribute) ||
      Utils.hasUserPermission(Permission.PublicCustomAttribute)
    ) {
      if (body !== undefined)
        body.customAttributes = await this.createUpdateAttributes();
    }

    let url_ =
      Utils.getServiceUrlByEnvironment() + "/api/contract/v1/contracts";
    url_ = url_.replace(/[?&]$/, "");

    const content = JSON.stringify(body);
    const authKey = await Utils.getApiKey();
    let host = Utils.getHost();
    let impersonated_User = UserContext.getUserId();
    let headers = {
      Impersonated_User:
        impersonated_User !== undefined && impersonated_User !== null
          ? "" + impersonated_User
          : "",
      "Content-Type": "application/json",
      Accept: "text/plain",
      HOSTNAME: host,
      authorization: authKey,
    };

    let promiseObj: any;
    if (isEdit) {
      promiseObj = axios.put(url_, content, { headers: headers });
    } else {
      promiseObj = axios.post(url_, content, { headers: headers });
    }
    this.validateSalesType = false;
    let status: boolean = false;
    await promiseObj
      .then(async (response: any) => {
        this.contractEntityID = isEdit ? this.contractId : response.data;
        status = true;
      })
      .catch((error: any) => {
        var data = error && error.response ? error.response.data : "";
        if (data && data.status === 400) {
          this.isValidationToasterVisible = true;
          if (
            data.title &&
            data.title.includes(
              "Sales type cannot be different than other records."
            )
          ) {
            this.validateSalesType = true;
          }
          toast.warning(`${data.title}`, {
            position: toast.POSITION.TOP_RIGHT,
            closeOnClick: false,
            closeButton: closeToasterButtonTemplate(this.hideValidationToaster),
            className: "validation-toaster contract",
            draggable: false,
            toastId: "validation-toaster",
          });
          this.enableSave();
        } else {
          return this.throwException(
            "An unexpected server error occurred.",
            data.status,
            data.title,
            headers,
            null
          );
        }
        status = false;
      });
    return status;
  }
  @action throwException = (
    message: string,
    status: number,
    response: string,
    headers: { [key: string]: any },
    result?: any
  ) => {
    if (result !== null && result !== undefined) throw result;
    else throw new ApiException(message, status, response, headers, null);
  };

  @action createUpdateAttributes = async () => {
    let data: any = [];
    (this.customAttributeList || []).map((item: any) => {
      item.attrValue =
        item.dataTypeId == 6
          ? item.attrValue
            ? Utils.getDateInFormat(new Date(item.attrValue))
            : ""
          : item.attrValue;
      data.push({
        customAttributeScreenId: item.scrnCustAttrId,
        entityId: this.contractEntityID,
        value: item.attrValue + "",
      });
    });

    return data;
  };

  listener: ContractUpdateListener | undefined;
  setListener(listener: ContractUpdateListener) {
    this.listener = listener;
  }

  @action updateAgentStatus = async (agentId: number) => {
    const result = await ProducerStore.getAgentStatusById(agentId);
    if (result) {
      if (this.listener) {
        this.listener.updateStatus(result.status || "");
      }
    }
  };

  @action hideValidationToaster = () => {
    this.isValidationToasterVisible = false;
    this.validateSalesType = false;
    toast.dismiss("validation-toaster");
    this.enableSave();
  };

  // custom attribute methods
  @action handleAttributesModal = (value: boolean) => {
    this.CustomAttributesComponentViewModel.disableRadioInitial = true;
    this.addAttributesModal = value;
    setTimeout(async () => {
      value &&
        this.CustomAttributesComponentViewModel.loadExistingAttributes(4);
      !value && this.CustomAttributesComponentViewModel.resetAttributes();
    }, 900);
  };

  protected async checkPermissions(): Promise<void> {
    localStorage.removeItem("CustomAttribute");
    // get Custom attribute screen permission
    var res = await this.getCustomAttributePermissionByScreen();
    if (res) {
      let data = res.filter(
        (x) =>
          x.path === Permission.AddEditStateLicensesScreen && x.active === true
      );
      this.isCustomAttrActive = data.length > 0 ? true : false;

      var attributes = await this.getCustomAttributesByScreen(4);
      if (attributes) {
        this.customAttributeList = attributes;
      }
      this.attributesScreenPathId = 4;
    }
  }

  @action async getCustomAttributePermissionByScreen() {
    try {
      var res = await CustomAttributeStore.getAllScreens();
      return res;
    } catch (e) {
      throw e;
    }
  }

  @action async getCustomAttributesByScreen(screenId: number) {
    try {
      var res = await CustomAttributeStore.getAllAttributesByScreen(
        screenId,
        this.contractId
      );
      return res;
    } catch (e) {
      throw e;
    }
  }

  @action AttributeSave = async (type: number) => {
    let data = await this.CustomAttributesComponentViewModel.saveAttribute(
      type
    );
    this.addAttributesModal = false;

    setTimeout(async () => {
      var attributes = await this.getCustomAttributesByScreen(4);
      if (attributes) {
        this.customAttributeList = attributes;
      }
      this.CustomAttributesComponentViewModel.resetAttributes();
    }, 900);
  };

  @action listenScrollEvent = () => {
    this.attachEvent(document.getElementById("root"), "scroll", this.update());
    this.attachEvent(window, "resize", this.update());
    this.update();
  };

  @action visibleY = (el: any) => {
    if (el) {
      var rect = (el && el.getBoundingClientRect()) || null,
        top = rect.top,
        height = rect.height,
        el = el.parentNode;
      return top <= document.documentElement.clientHeight;
    } else {
      return false;
    }
  };

  @action attachEvent = (element: any, event: any, callbackFunction: any) => {
    if (element.addEventListener) {
      element.addEventListener(event, callbackFunction, false);
    } else if (element.attachEvent) {
      element.attachEvent("on" + event, callbackFunction);
    }
  };

  @action update = () => {
    this.isScrollVisible = this.visibleY(
      document.getElementById("pleaseignoreanddonotdeleteit2")
    );
  };

  @action getHierarchy = async () => {
    this.agentHierarchyData = [];
    var result: any = await ContractsStore.getContractSummary(
      this.contractId,
      false
    );
    this.agentHierarchyData.push(result.agentHierarchy);
    window.addEventListener("scroll", this.listenScrollEvent);
    window.scrollTo(1, 1);
    return this.agentHierarchyData;
  };
  @action scrollLoader = () => {
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      window.scrollTo(0, 0);
    }, 400);
  };
}

export interface ContractUpdateListener {
  updateStatus(status: string): void;
}
