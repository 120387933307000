import React from "react";
import { observer } from "mobx-react";
import "primeflex/primeflex.css";
import { BookOfBusinessViewModel } from "./BookOfBusinessViewModel";
import { IMASMessagesHandler } from "../components/controls/IMASMessagesHandler";
import { Card } from "primereact/card";
import { Dialog } from "primereact/dialog";
import Utils from "../../infrastructure/Utils";
import { Permission } from "../../infrastructure/enum/Permission";
import "./BookOfBusiness.css";
import UserContext from "../../infrastructure/UserContext";
export const BookOfBusinessView: React.FC<{
  vm: BookOfBusinessViewModel;
}> = observer((props) => {
  const vm = props.vm;
    let env = Utils.getAppHostKey();
  let bookUrl =
    `https://book-${env}.integrityconnect.com/app?tenantName=${Utils.getBUName()}&downlineAgencyId=${
          UserContext.downlineAgencyId
    }`
      .replace("-stage", "-uat")
      .replace("-prod", "");
  return (
    <>
      {Utils.hasUserPermission(Permission.ViewBookOfBusiness) ? (
        <div className="bob-container">
          <iframe src={`${bookUrl}`} id="bob-iframe" frameBorder="0" />
        </div>
      ) : (
        <Card>
          <IMASMessagesHandler response={Utils.errorMsg()} />
        </Card>
      )}
    </>
  );
});
