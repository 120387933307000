import { routes } from "../../../../router";
import { action, observable } from "mobx";
import ViewModel from "../../../../infrastructure/ViewModel";
import RouteList from "../../../../infrastructure/RouteList";
import ContractsStore from "../../../../store/ContractsStore";
import Utils from "../../../../infrastructure/Utils";
import PageContext from "../../../../infrastructure/PageContext";
import StateLicenseStore from "../../../../store/StateLicenseStore";
export class LicenseManagementViewModel implements ViewModel {
  get CanClose(): boolean {
    throw new Error("Method not implemented.");
  }
  get CanRoute(): boolean {
    return true;
  }
  get IsLoaded(): boolean {
    throw new Error("Method not implemented.");
  }
  get IsLoading(): boolean {
    throw new Error("Method not implemented.");
  }
  Close(): void {
    throw new Error("Method not implemented.");
  }

  Route = async (currentRoute: RouteList): Promise<void> => {};

  @observable gridApi: any;
  @observable gridApiData: any;
  @observable pageIndex: number = 0;
  @observable pageSize: number = 100;
  @observable sortColumn: string = "Id";
  @observable sortAscending: boolean = true;
  @observable fullTextFilter: string = "";
  @observable licenseManagementModelList: any;
  @observable gridApiNoRows: string =
    "<span style='padding-top:80px;'>No records available to display</span>";
  @observable searchInputValue: string = "";
  @observable scrollPosition: number = window.scrollY;
  @observable totalRecords: number = 0;
  @observable lastSubmittedDate: string = "10/04/2024 11:30 PM";
  @observable arrayOfAgentID: any = [];
  @observable arrayOfStateLicenseId: any = [];
  @observable isDeleteConfirm: boolean = false;
  @observable selectedPanel: string = "Pending Requests";
  @observable emailSenderConfirm: boolean = false;
  @observable queueConfirm: boolean = false;
  @observable deQueueConfirm: boolean = false;
  @observable filterGridModel: any;
  @observable filterList: any = [];
  @observable isbuSendRemenders: boolean = false;
  @observable isbuQueueNiparUpdate: boolean = false;
  @observable refreshVisible: boolean = true;
  @observable selectedKey: string | undefined = "";
  @observable selectedTabIndex?: number = 0; 

  @action Load = async () => {
    this.selectedPanel = 'Pending Requests';
    this.selectedTabIndex = 0; 
    this.resetShowMoreProps();
    this.gridApiData = [];
    Utils.listenScrollEvent(PageContext.updateHorizontalScrollBar);
    await this.getData();
  };

  @action getStateFilterLookup = async () => {
    let result = await ContractsStore.appointmentStates();
    return result ? result.map((i) => i.abbreviation) : [];
  };

  @action getStatusFilterLookup = async () => {
    let licenseStatuses = await StateLicenseStore.getAllStateLicenseStatus();
    return licenseStatuses ? licenseStatuses.map((i) => i.text) : [];
  };

  @action getResidencyStatusFilterLookup = async () => {
    let statuses = await ContractsStore.getAllResidencyStatus();
    return statuses ? statuses.map((i) => i.text) : [];
  };

  @action onSearchChange = (e: any) => {
    this.searchInputValue = e.target.value;
    if (this.searchInputValue.length == 0) {
      this.resetShowMoreProps();
    }
    if (this.searchInputValue.length > 2 || this.searchInputValue.length == 0) {
      this.getData();
    }
  };

  @action resetShowMoreProps = () => {
    this.pageIndex = 0;
    this.pageSize = 100;
    this.sortColumn = "id";
    this.sortAscending = true;
    this.searchInputValue = "";
    this.arrayOfAgentID = [];
    this.arrayOfStateLicenseId = [];
    this.filterGridModel = undefined;
  };

  @action filterChange = async () => {
    this.filterList = [];
    if (this.filterGridModel) {
      let keys = Object.keys(this.filterGridModel);
      keys.forEach((key: any) => {
        const values = this.filterGridModel[key].values;
        const filter = this.filterGridModel[key].filter;
        const dateFrom = this.filterGridModel[key].dateFrom;
        const type = this.filterGridModel[key].filterType;
        this.filterList.push({
          key: key,
          set: type == "set" ? values : [],
          text: type == "text" ? filter : type == "date" ? dateFrom : "",
        });
      });
    }
  };
  @action onRowsPerPage = async (value: number) => {
    this.pageSize = value;
    this.getData();
  };

  onPaginationChanged = async (params: any = null, pageIndex: number) => {
    this.pageIndex = pageIndex;
    this.getData();
  };

  @action getData = async () => {
    PageContext.isLoading = true;
    this.filterChange();
    let response = await StateLicenseStore.getAllLicenseManagement(
      this.pageIndex,
      this.pageSize,
      this.sortColumn,
      this.sortAscending,
      this.searchInputValue,
      this.filterList && this.filterList.length > 0
        ? JSON.stringify(this.filterList)
        : "",
        this.selectedPanel == 'Request Log'
    );
    if (response && response.data) {
      this.gridApiData = response.data;
      this.totalRecords = response.recordCount || 0;
      this.lastSubmittedDate = "10/04/2024 11:30 PM";
      PageContext.updateHorizontalScrollBar();
    } else {
      this.gridApiData = [];
      this.totalRecords = 0;
    }
    setTimeout(() => {
      this.gridApi.setGridOption("rowCount", this.totalRecords);
      PageContext.isLoading = false;
    }, 100);
  };

  @action getDataByShowMore = () => {
    this.scrollPosition = window.scrollY;
    PageContext.isLoading = true;
    this.pageIndex += 1;
    this.getData();
  };

  @action setGridApi = (api: any) => {
    this.gridApi = api;
  };

  @action getGridApi = () => {
    return this.gridApi;
  };

  @action setFilterGridModel = async (filterModel: any) => {
    this.filterGridModel = filterModel;
    this.pageIndex = 0;
    this.pageSize = 100;
    this.arrayOfAgentID = [];
    this.arrayOfStateLicenseId = [];
    if (filterModel) {
      await this.getData();
      setTimeout(() => {
        this.gridApi.redrawRows();
      }, 0);
    }
  };

  @action onSelectionChanged = async () => {
    const selectedData = this.gridApi.getSelectedRows();
    this.arrayOfAgentID = (selectedData || []).map((i: any) => i.agentId);
    this.arrayOfStateLicenseId = (selectedData || []).map((i: any) => i.id);
  };

  @action sendRemindersConfirmation = async (value: boolean) => {
    this.emailSenderConfirm = value;
  };

  @action queueConfirmation = async (value: boolean) => {
    this.queueConfirm = value;
  };

  @action deQueueConfirmation = async (value: boolean) => {
    this.deQueueConfirm = value;
  };

  @action sendReminders = async () => {
    let body = {
      stateLicenseIds:
        this.isbuSendRemenders && this.totalRecords > 0
          ? []
          : this.arrayOfStateLicenseId.length > 0 &&
            !this.isbuSendRemenders 
          ? this.arrayOfStateLicenseId
          : [],
    };
    let response = await StateLicenseStore.sendReminders(body);
    this.emailSenderConfirm = false;
    if (this.isbuSendRemenders) {
      Utils.showSuccessToaster(
        "License renewal reminders are queued. Agents will receive the email shortly.",
        3000
      );
    } else {
      Utils.showSuccessToaster(
        "License renewal reminders sent" +
          (response > -1 ? " to " + response + " agents." : "."),
        3000
      );
    }
    this.getData();
  };

  @action sendQueue = async () => {
    let body = {
      agentIds:
        this.isbuQueueNiparUpdate && this.totalRecords > 0
          ? []
          : this.arrayOfAgentID.length > 0 &&
            !this.isbuQueueNiparUpdate 
          ? this.arrayOfAgentID
          : [],
    }; 
    let response = await StateLicenseStore.sendQueue(body);
    this.queueConfirm = false; 

    if(response === 0 ) {
      Utils.showSuccessToaster(
        "There is no update from NIPR for the selected agents.",
        3000
      );
    }else {
      Utils.showSuccessToaster(
        "Please visit Request Log tab to see the NIPR update for selected agents.",
        3000
      );
    } 
    this.getData();
  };

  @action sendDeDeueue = async () => {
    let body = {
      agentIds:
        this.arrayOfAgentID.length > 0 &&
        this.arrayOfAgentID.length != this.totalRecords
          ? this.arrayOfAgentID
          : undefined,
    };
    let response = await StateLicenseStore.sendDeQueue(body);
    this.deQueueConfirm = false;
    Utils.showSuccessToaster(
      "Request submitted to NIPR" +
        (response > -1 ? " for " + response + " agents." : ".") +
        " This may take 1-2 days to process.",
      3000
    );
    this.getData();
  };

  @action handleTabClick = (index: number | undefined, key: string): void => {
    this.resetShowMoreProps();
    this.selectedTabIndex = index;
    this.setSelectedPanel(key);
  };

  @action setSelectedPanel = (value: string) => {
    this.selectedPanel = value; 
    if(value =="Request Log"){
      this.sortColumn = 'niprUpdatedDate';
      this.sortAscending = false;
    }
    setTimeout(() => { 
      this.getData(); 
    }, 100);
  };

  @action goToAdmin = () => {
    routes.administration.push();
  };
}
