import { observable, action, computed } from "mobx";
import ViewModel from "../../infrastructure/ViewModel";
import RouteList from "../../infrastructure/RouteList";
import { ActionLogModel } from "../../services/IdentityService";
import utils from "../../infrastructure/Utils";
import IMASLog from "../../infrastructure/IMASLog";
import { routes } from "../../router";
import RouteParamDetails from "../../infrastructure/RouteParamDetails";
import { CustomerSummary } from "../../infrastructure/enum/Customer";
import {
  ContactModel,
  CreateUpdateCustomerModel,
  CreateUpdateCustomerNotesModel,
  CreateUpdateNoteModel,
  GetAllNotesByCustomerModel,
} from "../../services/InsuredService";
import InsuredStore from "../../store/InsuredStore";
import IdentityStore from "../../store/IdentityStore";
import Collection from "../../infrastructure/CollectionHelper";
import {
  ContactType,
  ContactTypeValue,
  EmailTypeValue,
  PhoneType,
  PhoneTypeValue,
} from "../../infrastructure/enum/Common";
import { MenuItem } from "primereact/components/menuitem/MenuItem";
import PageContext from "../../infrastructure/PageContext";
import UserContext from "../../infrastructure/UserContext";
import ProducerStore from "../../store/ProducerStore";
import { ErrorModel } from "../../infrastructure/ErrorModel";
import Utils from "../../infrastructure/Utils";
import { AbstractValidator } from "fluent-ts-validator";
import { Permission } from "../../infrastructure/enum/Permission";
import ContractsStore from "../../store/ContractsStore";
import { NoteModel } from "../../services/ProducerService";

export class CustomerSummaryViewModel implements ViewModel {
  @action Load = async () => {
    this.isLoading = false;
    this.customerId = RouteParamDetails.id;
    this.customerName = RouteParamDetails.customerName;
  };

  @observable isLoadRecentProfiles: boolean = false;
  @observable isLoading: boolean = false;
  @observable customerName: string | undefined = "";
  @observable customerId: number = 0;
  @observable noteId: number = 0;
  @observable dob: string = "";
  @observable selectedTabIndex?: number = 0;
  @observable selectedPanel: string = CustomerSummary.Customer;
  @observable isCollapsed: boolean = true;
  @observable selectedType: string = "0";
  @observable selectedUser: string = "0";
  @observable sortColumn: string = "createdDate";
  @observable sortOrder: boolean = false;
  @observable startIndex: number = 0;
  @observable userName: string = "";
  @observable exceptionMessage: any;
  @observable response: any = "";
  @observable isException: boolean = false;
  @observable isSuccess: boolean = false;
  @observable note: string = "";
  @observable notesVisible: boolean = false;
  @observable isDeleteContactConfirm: boolean = false;
  @observable selectContactId: number = 0;
  @observable selectContactTypeId: number = 0;
  @observable title: string = "";
  @observable isContactDialogVisible: boolean = false;
  @observable contactId: number = 0;
  @observable isMessgeVisible: boolean = false;
  @observable isEdit: boolean = false;
  @observable isDefaultContact: boolean = false;
  @observable isCommissionContact: boolean = false;
  @observable isNotValidContact: boolean = false;
  @observable contactValueLabel: string = ContactType.Email;
  @observable contactDefault: any | undefined = undefined;
  @observable addContactDto: any | undefined = [];
  @observable contactSortColumn: string | undefined = "Id";
  @observable pageIndex: number = 0;
  @observable pageSize: number = 100;
  @observable contactSortOrder: boolean = false;
  @observable
  public ErrorModelContact = new ErrorModel(new ContactValidatory());
  @action
  public ValidateContact = async () => {
    return this.ErrorModelContact.Validate(this);
  };
  @action
  public ResetValidateContact = async () => {
    return this.ErrorModelContact.ResetValidation(this);
  };
  @observable contactType: string = "";
  @observable isSMSEnabled: boolean = false;
  @observable contactValue: string = "";
  @observable contactDescription: string = "";
  @observable contactTypeList = new Collection<any>();

  Route = async (currentRoute: RouteList): Promise<void> => {};
  @observable customerSummary: any | null = null;

  static Symbol: symbol = Symbol("CustomerSummaryViewModel");

  getSymbol(): symbol {
    return CustomerSummaryViewModel.Symbol;
  }
  get CanClose(): boolean {
    throw new Error("Method not implemented.");
  }
  get CanRoute(): boolean {
    return true;
  }
  get IsLoaded(): boolean {
    throw new Error("Method not implemented.");
  }
  get IsLoading(): boolean {
    throw new Error("Method not implemented.");
  }
  Close(): void {
    throw new Error("Method not implemented.");
  }
  @action setCustomerSummaryResponse(result: CreateUpdateCustomerModel) {
    this.customerSummary = result;
    if (this.customerSummary) {
      this.dob =
        "" +
        (utils.getDateInFormat(this.customerSummary.bDay) +
          utils.getAge(this.customerSummary.bDay));
    }
  }
  @action load = async (id: number) => {
    // reset note tab to table grid
    this.selectedTabIndex = 0;
    this.setSelectedPanel(CustomerSummary.Customer);
    if (this.customerId !== id) {
      this.customerId = +id;
    }
    if (RouteParamDetails.customerSummaryCall === true) {
      this.loadCustomerSummary();
      RouteParamDetails.customerSummaryCall = false;
    }
    this.actionLog();
  };

  @observable rows: number = 25;
  @observable totalRecords: number = 0;
  @observable first: number = 0;
  @observable isAddNoteVisible: boolean = false;
  @observable NotesList = new Collection<GetAllNotesByCustomerModel>();
  @observable ContactList = new Collection<any>();

  @action loadNotes = async () => {
    try {
      var customerNotes = await InsuredStore.getCustomerNotes(
        this.customerId,
        this.startIndex,
        this.rows,
        this.sortColumn,
        this.sortOrder
      );
      if (customerNotes != null) {
        if (customerNotes.data) {
          this.totalRecords = customerNotes.recordCount
            ? customerNotes.recordCount
            : 0;
          this.NotesList.values = customerNotes.data;
          this.notesVisible = true;
        } else {
          this.noRecordsFound();
        }
      }
    } catch (e: any) {
      IMASLog.log("exception from store: " + e.value);
      this.noRecordsFound();
    }
    this.isLoading = false;
  };

  @action getContactType = (contact: any) => {
    return (
      "" +
      contact.contactType +
      " " +
      (contact.contactType === ContactType.PhoneNumber && contact.phoneType
        ? "(" + contact.phoneType + ")"
        : "")
    );
  };

  @action actionLog = () => {
    var dto: ActionLogModel = {
      actionId: 2,
      entityId: this.customerId ? +this.customerId : undefined,
      userId: UserContext.getUserId(),
    };
    IdentityStore.createActionLog(dto);
    this.isLoadRecentProfiles = true;
  };

  @action setLoadRecentProfiles = () => {
    this.isLoadRecentProfiles = false;
  };

  @action dialerLog = async (number: any) => {
    let reqData: CreateUpdateNoteModel = {
      id: 0,
      insuredId: this.customerId,
      title: "Contacted Insured via phone",
      note: `Called ${number} from the Insured contacts`,
      private: false,
      documentAttachmentList: [],
    };
    await InsuredStore.addInsuredNote(reqData);
    this.loadNotes();
  };

  @action getContactNumber = (contact: ContactModel) => {
    this.dialerLog(contact.contactValue);
    window.open("rcapp://r/call?number=" + contact.contactValue, "_parent");
  };
  @action getContactValue = (contact: any) => {
    if (contact.contactType === "Phone Number") {
      //3
      return this.getPhoneNumberUSformat("" + contact.contactValue);
    } else return "" + contact.contactValue;
  };

  @action getPhoneNumberUSformat = (phoneNumber: string) => {
    var cleaned = ("" + phoneNumber).replace(/\D/g, "");
    var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      var intlCode = match[1] ? "+1 " : "";
      return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("");
    }
    return phoneNumber;
  };

  @action getContactHref = (contact: any) => {
    if (contact.contactType === "Phone Number") {
      //3
      return "tel:" + contact.contactValue;
    } else if (contact.contactType === "Email") {
      return "mailto:" + contact.contactValue + "?Subject=Integrity%20Connect";
    } else return "" + contact.contactType;
  };

  @action getContactActionItems = (rowData: any) => {
    let deleteMenuItem: MenuItem = {
      label: "Delete",
      icon: "fa fa-trash",
      command: () => {
        this.isDeleteContactConfirm = true;
      },
    };
    var contactActionItems: MenuItem[] = [];
    let setDefault = {
      label: rowData.default ? "Set as Not Default" : "Set as Default",
      icon: "fa fa-star",
      command: () => {
        this.setContactAsDefault(rowData.default);
      },
    };
    let setSMS = {
      label: rowData.enableSMS ? "Disable SMS" : "Enable SMS",
      icon: "fa fa-comment",
      command: () => {
        if (rowData.enableSMS !== undefined) {
          this.contactEnableSMS(rowData.enableSMS);
        }
      },
    };
    // Utils.hasUserPermission(Permission.SetDefaultContactTypeViaAgentProfile) &&
    contactActionItems.unshift(setDefault);
    // if(rowData.contactType === ContactType.PhoneNumber && rowData.phoneType === PhoneType.Mobile){
    //     contactActionItems.push(setSMS);
    // }
    // Utils.hasUserPermission(Permission.DeleteContactOption) && contactActionItems.push(deleteMenuItem)
    contactActionItems.push(deleteMenuItem);
    return contactActionItems;
  };

  @action contactEnableSMS = async (enableSMS: boolean) => {
    try {
      // add code once sms is enabled
      // await ProducerStore.setEnableSMS(this.selectContactId, !enableSMS)
      // await this.getContacts(this.agentId);
    } catch (error) {
      this.isLoading = false;
    }
  };

  @observable totalContactRecords: number | undefined = 0;
  @observable contactOptionsPageNO: number = 0;

  @action loadContacts = async () => {
    try {
      this.ContactList.values = [];
      var customerContacts = await InsuredStore.getAllContact(
        this.customerId,
        this.pageIndex,
        this.pageSize,
        this.contactSortColumn,
        this.contactSortOrder,
        ""
      );
      if (customerContacts != null) {
        if (customerContacts.data) {
          if (!Utils.hasUserPermission(Permission.ViewPhoneNumber))
            customerContacts.data = customerContacts.data.filter(
              (i) => i.contactTypeId != ContactTypeValue.PhoneNumber
            );
          this.totalContactRecords =
            customerContacts.data.length > 0 ? customerContacts.data.length : 0;
          this.sortContacts(customerContacts.data);
        } else {
          this.noContactRecordsFound();
        }
      }
    } catch (e: any) {
      IMASLog.log("exception from store: " + e.value);
      this.noContactRecordsFound();
    }
    this.isLoading = false;
  };

  @action sortContacts(data: ContactModel[]) {
    var sortData = [...data].sort(this.compareContact);
    this.ContactList.values = sortData;
  }
  @action contactsSort = async (column: string) => {
    this.contactSortColumn = column;
    this.contactSortOrder = !this.contactSortOrder;
    await this.loadContacts();
  };
  compareContact = (a: ContactModel, b: ContactModel) => {
    const nameA = a.default ? "a" : "b";
    const nameB = b.default ? "a" : "b";

    let comparison = 0;
    if (nameA > nameB) {
      comparison = 1;
    } else if (nameA < nameB) {
      comparison = -1;
    }
    return comparison;
  };

  @action onPageContacts(firstIndex: number) {
    this.contactOptionsPageNO = firstIndex;
    var startIndex = firstIndex / this.rows;
    this.loadContacts();
  }

  @action noRecordsFound() {
    this.totalRecords = 0;
    this.NotesList.values = [];
  }

  @action noContactRecordsFound() {
    this.totalRecords = 0;
    this.ContactList.values = [];
  }
  listener: CustomerSummaryListener | undefined;
  setListener(listener: CustomerSummaryListener) {
    this.listener = listener;
  }

  @computed
  get id(): string {
    if (this.customerSummary) return "" + this.customerSummary.id;
    return "";
  }
  @computed
  get firstName(): string {
    if (this.customerSummary)
      return (
        "" + (this.customerSummary.fName ? this.customerSummary.fName : "")
      );
    return "";
  }

  @computed
  get lastName(): string {
    if (this.customerSummary)
      return (
        "" + (this.customerSummary.lName ? this.customerSummary.lName : "")
      );
    return "";
  }

  @computed
  get middleName(): string {
    if (this.customerSummary)
      return "" + (this.customerSummary.mi ? this.customerSummary.mi : "");
    return "";
  }

  @computed
  get suffix(): string {
    if (this.customerSummary)
      return (
        "" + (this.customerSummary.suffix ? this.customerSummary.suffix : "")
      );
    return "";
  }

  @computed
  get city(): string {
    if (this.customerSummary)
      return "" + (this.customerSummary.city ? this.customerSummary.city : "");
    return "";
  }

  @computed
  get state(): string {
    if (this.customerSummary)
      return (
        "" + (this.customerSummary.state ? this.customerSummary.state : "")
      );
    return "";
  }

  @computed
  get county(): string {
    if (this.customerSummary)
      return (
        "" +
        (this.customerSummary.countyName ? this.customerSummary.countyName : "")
      );
    return "";
  }

  @computed
  get zip(): string {
    if (this.customerSummary)
      return "" + (this.customerSummary.zip ? this.customerSummary.zip : "");
    return "";
  }

  @computed
  get ssn(): string {
    if (this.customerSummary)
      return "" + (this.customerSummary.ssn ? this.customerSummary.ssn : "");
    return "";
  }

  @computed
  get mbi(): string {
    if (this.customerSummary)
      return (
        "" +
        (this.customerSummary.medicareBeneficiaryIdentificationNumber
          ? this.customerSummary.medicareBeneficiaryIdentificationNumber
          : "")
      );
    return "";
  }

  @computed
  get hicn(): string {
    if (this.customerSummary)
      return "" + (this.customerSummary.hicn ? this.customerSummary.hicn : "");
    return "";
  }

  @computed
  get payor(): string {
    if (this.customerSummary)
      return (
        "" +
        (this.customerSummary.payorName ? this.customerSummary.payorName : "")
      );
    return "";
  }

  @computed
  get address1(): string {
    if (this.customerSummary)
      return (
        "" + (this.customerSummary.street ? this.customerSummary.street : "")
      );
    return "";
  }
  @computed
  get gender(): string {
    if (this.customerSummary)
      return (
        "" +
        (this.customerSummary.gender && this.customerSummary.gender.trim()
          ? this.customerSummary.gender === "M"
            ? "Male"
            : "Female"
          : "Not Selected")
      );
    return "";
  }

  @computed
  get address2(): string {
    if (this.customerSummary)
      return (
        "" + (this.customerSummary.street2 ? this.customerSummary.street2 : "")
      );
    return "";
  }

  @computed
  get medicaidNumber(): string {
    if (this.customerSummary)
      return (
        "" +
        (this.customerSummary.medicaidNumber
          ? this.customerSummary.medicaidNumber
          : "")
      );
    return "";
  }

  @computed
  get subsidyLevel(): string {
    if (this.customerSummary)
      return (
        "" +
        (this.customerSummary.subsidyLevel
          ? this.customerSummary.subsidyLevel
          : "")
      );
    return "";
  }

  @computed
  get statePharmaceuticalNumber(): string {
    if (this.customerSummary)
      return (
        "" +
        (this.customerSummary.statePharmaceuticalProgramMemberNumber
          ? this.customerSummary.statePharmaceuticalProgramMemberNumber
          : "")
      );
    return "";
  }

  @action setSelectedPanel(value: string) {
    this.selectedPanel = value;
  }

  @action setIsCollapsed(value: boolean) {
    this.isCollapsed = value;
  }

  @action getName = () => {
    var name = "";
    if (
      this.firstName &&
      this.firstName != null &&
      this.lastName &&
      this.lastName != null &&
      this.middleName &&
      this.middleName != null
    ) {
      name = this.firstName + " " + this.lastName + " " + this.middleName;
    } else if (
      this.firstName &&
      this.firstName != null &&
      this.lastName &&
      this.lastName != null
    ) {
      name = this.firstName + " " + this.lastName;
    } else if (!this.firstName || this.firstName === null) {
      name = "" + this.lastName;
    } else {
      name = "" + this.firstName;
    }
    return name;
  };

  @action loadCustomerSummary = async () => {
    try {
      this.customerSummary = await InsuredStore.getCustomerSummary(
        this.customerId
      );
      if (this.customerSummary) {
        this.dob =
          "" +
          (utils.getDateInFormat(this.customerSummary.bDay) +
            utils.getAge(this.customerSummary.bDay));
        if (this.listener) {
          this.listener.updateCustomerInfo(
            this.customerSummary.fName,
            this.customerSummary.lName
          );
        }
        this.loadNotes();
      }
    } catch (e: any) {
      this.isLoading = false;
      routes.customerSearch.replace();
      IMASLog.log("exception from store: " + e.value);
    }
  };

  @action resetData() {
    this.note = "";
  }

  @action setSortOrder() {
    this.sortOrder = !this.sortOrder;
    this.startIndex = 0;
    this.first = 0;
  }
  @action setSortColumn(column: string) {
    this.sortColumn = column;
  }

  @action resetAll = async () => {
    this.isSuccess = false;
    this.isException = false;
    this.response = "";
    this.exceptionMessage = "";
  };

  @action addNotes = async () => {
    try {
      this.resetAll();
      var dto: CreateUpdateCustomerNotesModel = {
        // customerId: this.customerId,
        customerId: RouteParamDetails.id,
        note: this.note,
      };
      await InsuredStore.addCustomerNote("" + this.customerId, dto);
      this.isSuccess = true;

      await this.loadNotes();
      this.resetAll();
      this.resetData();
      this.isAddNoteVisible = false;

      this.isLoading = false;
    } catch (e: any) {
      this.isLoading = false;
      this.isException = true;
      this.exceptionMessage = e.response;
      this.isSuccess = false;
      this.isAddNoteVisible = true;
      IMASLog.log("exception while creating customer notes");
    }
  };

  @action onPage(firstIndex: number, rows: number) {
    this.first = firstIndex;
    this.rows = rows;
    this.startIndex = firstIndex / this.rows;
    this.loadNotes();
  }

  @action onNoteCancel = async () => {
    this.isAddNoteVisible = false;
    this.note = "";
  };

  @action setNotes = (value: string) => {
    this.note = value;
  };

  @action onAddNoteClick = async () => {
    this.isAddNoteVisible = true;
  };

  @action setContactDialogVisibility = async (value: boolean) => {
    this.resetContactForm();
    await this.ResetValidateContact();
    this.isContactDialogVisible = value;
    if (value) {
      await this.loadLookups();
    }
    if (this.customerId == 0 && value && this.contactId !== 0) {
      var contact = this.addContactDto.find((item: any) => {
        return item.id == this.contactId;
      });
      if (contact) {
        this.setContactDescription(
          contact.description ? contact.description : ""
        );
        this.setContactType("" + contact.contactTypeId);
        this.setContactVale(
          contact.contactValue
            ? this.getPhoneNumberUSformat("" + contact.contactValue)
            : ""
        );
        if (contact.enableSMS !== null) {
          this.setphoneType(contact.phoneTypeId || PhoneTypeValue.Mobile);
          this.setSMS(contact.enableSMS || false);
        } else {
          this.setSMS(false);
          this.setphoneType(PhoneTypeValue.Mobile.toString());
        }
        this.setEmailType(
          contact.emailTypeId ? contact.emailTypeId.toString() : "0"
        );
      }
    } else if (value && this.contactId !== 0) {
      var result = await InsuredStore.getContactDetails(this.contactId);
      if (result) {
        this.setContactDescription(
          result.description ? result.description : ""
        );
        this.setContactType(
          result.contactTypeId ? result.contactTypeId.toString() : "0"
        );
        this.setContactVale(
          result.contactValue
            ? this.getPhoneNumberUSformat("" + result.contactValue)
            : ""
        );
        if (result.enableSMS !== null) {
          this.setphoneType(
            result.phoneTypeId ? result.phoneTypeId.toString() : "0"
          );
          this.setSMS(result.enableSMS || false);
        } else {
          this.setSMS(false);
          this.setphoneType(PhoneTypeValue.Mobile.toString());
        }
        this.setEmailType(
          result.emailTypeId ? result.emailTypeId.toString() : "0"
        );
      }
    }
  };
  @observable isLookupsLoaded = false;
  @action async loadLookups() {
    if (!this.isLookupsLoaded) {
      this.getContactTypes();
      let result = await ProducerStore.getPhoneTypes();
      if (result) {
        this.phoneTypeList.values = [];
        for (let item of result) {
          this.phoneTypeList.values.push({
            label: "" + item.text,
            value: "" + item.value,
          });
        }
      }

      let res = await ProducerStore.getEmailTypes();
      if (result) {
        this.emailTypeList.values = [];
        for (let item of res) {
          this.emailTypeList.values.push({
            label: "" + item.text,
            value: "" + item.value,
          });
        }
      }
      this.isLookupsLoaded = true;
    }
  }
  @action resetContactForm() {
    this.enableSMS = false;
    this.contactType = "1";
    this.phoneType = PhoneTypeValue.Mobile;
    this.emailType = EmailTypeValue.Work;
    this.contactValue = "";
    this.contactDescription = "";
    this.contactValueLabel = ContactType.Email;
    this.contactDefault = undefined;
    this.isMessgeVisible = false;
  }

  @action onRowClick = async (data: any) => {
    this.contactDefault = data.default;
    this.selectContactId = data.id;
    this.selectContactTypeId = this.getContactTypeId("" + data.contactType);
    this.enableSMS = data.enableSMS;
  };

  @action setContactAsDefaultItem = async () => {
    await this.setContactAsDefault(this.contactDefault);
  };

  @action setContactAsDefault = async (value: boolean) => {
    try {
      this.isLoading = true;
      this.isException = false;

      if (this.customerId === 0) {
        var addcontactListobj = this.addContactDto;
        var cListobj = this.ContactList.values;
        this.addContactDto = [];
        this.ContactList.values = [];
        var contact = addcontactListobj.find((item: any) => {
          return item.id === this.selectContactId;
        });
        var contactTypeid = 0,
          ctypeid = 0;
        if (contact) {
          contact.default = !value;
          contactTypeid = contact.contactTypeId;
        }

        var contactobj = cListobj.find((item: any) => {
          return item.id === this.selectContactId;
        });
        if (contactobj) {
          contactobj.default = !value;
          ctypeid = contactobj.contactTypeId;
        }

        if (!value) {
          var contactNotDefault = addcontactListobj.filter((item: any) => {
            return (
              item.id !== this.selectContactId &&
              item.contactTypeId === contactTypeid
            );
          });
          for (var i = 0; i < contactNotDefault.length; i++) {
            contactNotDefault[i].default = value;
          }

          var contactNotDefaultobj = cListobj.filter((item: any) => {
            return (
              item.id !== this.selectContactId && item.contactTypeId === ctypeid
            );
          });
          for (var i = 0; i < contactNotDefaultobj.length; i++) {
            contactNotDefaultobj[i].default = value;
          }
        }
        this.addContactDto = addcontactListobj;
        this.ContactList.values = cListobj;
      } else {
        await InsuredStore.defaultContact(
          this.selectContactId,
          this.customerId,
          this.selectContactTypeId,
          value
        );
        setTimeout(async () => {
          await this.loadContacts();
          this.isLoading = false;
        }, 700);
      }
    } catch (error: any) {
      this.isLoading = false;
      this.isException = true;
      this.exceptionMessage = error.response;
    }
  };

  @action setSelectedContactId(
    value: number | undefined,
    contactType: string | undefined
  ) {
    this.selectContactId = value ? value : 0;
    this.selectContactTypeId = this.getContactTypeId("" + contactType);
  }

  getContactTypeId = (value: string) => {
    switch (value) {
      case "Email":
        return 1;
      case "Phone Number":
        return 3;
      case "Alternative Point of Contact":
        return 4;
      default:
        return 3;
    }
  };

  @action addContact = async () => {
    this.isNotValidContact = false;
    if (!(await this.ValidateContact())) {
      await this.validateContactValue();
      if (this.isNotValidContact) return true;
      try {
        var dto: any = {
          id: this.contactId,
          insuredId: this.customerId,
          contactTypeId: +this.contactType,
          contactValue:
            this.contactType === "3"
              ? this.contactValue.replace(/[^\d]/g, "")
              : this.contactValue,
          description: this.contactDescription,
          dateCreated: new Date(),
          enableSMS: false,
          phoneTypeId: this.phoneType,
          emailTypeId: this.emailType ? this.emailType : undefined,
          phoneType: Utils.getLabelByValue(
            this.phoneTypeList.values,
            this.phoneType
          ),
          createdByUserId: UserContext.getUserId(),
          default: false,
        };

        if (this.customerId == 0) {
          await this.addEditContact(dto);
        } else {
          if (this.contactId === 0) {
            await InsuredStore.addContact(dto);
          } else {
            dto.id = this.contactId;
            await InsuredStore.editContact(dto);
          }
          await this.setContactDialogVisibility(false);
          await this.loadContacts();
        }
        PageContext.setIsMessageVisible(false);
        this.resetContactForm();
        this.isLoading = false;
      } catch (error: any) {
        PageContext.setIsMessageVisible(false);
        this.isLoading = false;
        error.text().then((_responseText: any) => {
          this.response = _responseText;
          this.isMessgeVisible = true;
        });
        IMASLog.log("exception: " + error);
      }
    }
  };

  private async addEditContact(dto: any) {
    var id = new Date().getTime();
    var isValid = false;
    if (this.contactType == "3") {
      isValid = await InsuredStore.verifyContactPhoneNumber(dto);
    } else {
      isValid = true;
    }

    if (isValid) {
      if (this.contactId > 0) {
        var contact = this.addContactDto.find((item: any) => {
          return item.id === this.contactId;
        });
        if (contact) {
          contact.contactTypeId = +this.contactType;
          contact.contactValue = this.contactValue;
          contact.description = this.contactDescription;
          contact.dateCreated = new Date();
          contact.enableSMS = false;
          contact.phoneTypeId = this.phoneType;
          contact.emailTypeId = this.emailType;
          contact.phoneType = Utils.getLabelByValue(
            this.phoneTypeList.values,
            this.phoneType
          );
          contact.emailType = Utils.getLabelByValue(
            this.emailTypeList.values,
            this.emailType
          );
          contact.createdByUserId = UserContext.getUserId();
        }

        var contactobj = this.ContactList.values.find((item: any) => {
          return item.id === this.contactId;
        });

        if (contactobj) {
          contactobj.contactTypeId = +this.contactType;
          contactobj.contactType = this.contactValueLabel;
          contactobj.contactValue = this.contactValue;
          contactobj.description = this.contactDescription;
          contactobj.dateCreated = new Date();
          contactobj.enableSMS = false;
          contactobj.phoneTypeId = this.phoneType;
          contactobj.emailTypeId = this.emailType;
          contactobj.createdByUserId = UserContext.getUserId();
          contactobj.phoneType = Utils.getLabelByValue(
            this.phoneTypeList.values,
            this.phoneType
          );
          contactobj.emailType = Utils.getLabelByValue(
            this.emailTypeList.values,
            this.emailType
          );
        }
      } else {
        dto.id = id;
        this.addContactDto.push(dto);
        this.ContactList.values.push({
          id: id,
          default: false,
          commission: false,
          contactTypeId: +this.contactType,
          contactType: this.contactValueLabel,
          contactValue: this.contactValue,
          description: this.contactDescription,
          dateCreated: new Date(),
          enableSMS: false,
          phoneType: Utils.getLabelByValue(
            this.phoneTypeList.values,
            this.phoneType
          ),
          phoneTypeId: this.phoneType,
          emailType: Utils.getLabelByValue(
            this.emailTypeList.values,
            this.emailType
          ),
          emailTypeId: this.emailType,
          createdByUserId: UserContext.getUserId(),
        });
      }
      await this.setContactDialogVisibility(false);
    }
  }

  @action setContactVale(value: string) {
    this.isNotValidContact = false;
    this.contactValue = value;
  }

  @action getContactTypes = async () => {
    let result = await InsuredStore.getContactTypes();
    if (result) {
      this.contactTypeList.values = [];
      Utils.mapListItemsToDropdown(result, this.contactTypeList, "", "");
      if (
        this.contactId === 0 &&
        !Utils.hasUserPermission(Permission.AddPhoneNumber) &&
        this.contactTypeList.values.length > 0
      )
        this.contactTypeList.values = this.contactTypeList.values.filter(
          (i) => i.label != ContactType.PhoneNumber
        );
      if (
        this.contactId != 0 &&
        !Utils.hasUserPermission(Permission.EditPhoneNumber) &&
        this.contactTypeList.values.length > 0
      )
        this.contactTypeList.values = this.contactTypeList.values.filter(
          (i) => i.label != ContactType.PhoneNumber
        );
      this.contactType =
        this.contactTypeList.values.length > 0
          ? this.contactTypeList.values[0].value
          : "";
      this.contactValueLabel = ContactType.Email;
    }
  };

  @action setContactType(value: string) {
    this.contactType = value;
    this.contactValue = "";
    switch (value) {
      case "1":
        this.contactValueLabel = ContactType.Email;
        break;
      case "2":
        this.contactValueLabel = ContactType.TextMessage;
        break;
      case "3":
        this.contactValueLabel = ContactType.PhoneNumber;
        break;
      case "4":
        this.contactValueLabel = ContactType.Name;
        break;
    }
    this.isNotValidContact = false;
    this.ResetValidateContact();
  }
  @action setCommisionContact(value: boolean) {
    this.isCommissionContact = value;
  }
  @action setDefaultContact(value: boolean) {
    this.isDefaultContact = value;
  }
  @action setContactDescription(value: string) {
    this.contactDescription = value;
  }
  @action setContactId(value: number) {
    this.contactId = value;
  }

  @action cancel = async () => {
    this.enableSMS = false;
    this.isContactDialogVisible = false;
    this.resetContactForm();
  };

  @action setSMS = async (value: boolean) => {
    this.enableSMS = value;
  };

  @action validateContactValue = async () => {
    this.isNotValidContact = false;
    this.contactValue = this.contactValue.trim();
    if (this.contactValue === "") {
      this.isNotValidContact = true;
    } else {
      this.isNotValidContact = false;
    }
    if (this.contactType === "1") {
      // eslint-disable-next-line
      var result = this.contactValue.match(
        /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i
      );
      if (result) {
        this.isNotValidContact = false;
      } else {
        this.isNotValidContact = true;
      }
    }
    if (this.contactType === "3") {
      var phoneResult = this.contactValue.match(
        /^(((\d{3})(\d{3})(\d{4}))|((\(\d{3}\))[\s]?\d{3}[\-]\d{4})|(\d{3}[\s]\d{3}[\s]\d{4}))$/
      );
      if (phoneResult) {
        this.isNotValidContact = false;
      } else {
        this.isNotValidContact = true;
      }
    }
  };

  @observable phoneTypeList = new Collection<any>();
  @observable emailTypeList = new Collection<any>();
  @observable emailType: number = EmailTypeValue.Work;
  @observable phoneType: number = PhoneTypeValue.Mobile;
  @observable enableSMS: boolean = false;

  @action setphoneType = async (value: string) => {
    this.phoneType = parseInt(value);
    if (this.phoneType !== PhoneTypeValue.Mobile) {
      this.setSMS(false);
    }
  };
  @action setEmailType = async (value: string) => {
    this.emailType = parseInt(value);
  };

  @action setDeleteContactConfirmation(value: boolean) {
    this.isDeleteContactConfirm = value;
  }

  @action deleteContact = async () => {
    try {
      this.isException = false;
      if (this.customerId === 0) {
        this.addContactDto = this.addContactDto.filter((item: any) => {
          return item.id != this.selectContactId;
        });
        this.ContactList.values = this.ContactList.values.filter(
          (item: any) => {
            return item.id != this.selectContactId;
          }
        );
        this.isDeleteContactConfirm = false;
      } else {
        await InsuredStore.deleteContact(this.selectContactId);
        this.isDeleteContactConfirm = false;
        setTimeout(async () => {
          await this.loadContacts();
          this.isLoading = false;
        }, Utils.timeDelay_Delete());
      }
    } catch (error: any) {
      this.isLoading = false;
      this.isException = true;
      this.exceptionMessage = error.response;
      IMASLog.log("exception: " + error);
    }
  };
}

export interface CustomerSummaryListener {
  updateCustomerInfo(fName: string, lName: string): void;
}

class ContactValidatory extends AbstractValidator<CustomerSummaryViewModel> {
  public constructor() {
    super();
    this.validateIfString((input) => input.contactType)
      .isNotEmpty()
      .isNotEqualTo("0")
      .isNotEqualTo("None")
      .isNotEqualTo("NONE")
      .withFailureMessage("Type is required");
    this.validateIfString((input) => input.contactValue)
      .isNotEmpty()
      .withFailureMessage("Value is required");
  }
}
