import { createRouter, defineRoute, createGroup } from "type-route";

const agentDetails = defineRoute(
  {
    agentId: "path.param.string",
  },
  (p) => `/agents/${p.agentId}`
);

const agentNotes = agentDetails.extend("/notes");
const agentNotesByUser = agentNotes.extend(
  {
    userId: "path.param.number",
  },
  (p) => `/${p.userId}`
);
const groupDetails = agentDetails.extend("/groups");
const agentGroupDetails = groupDetails.extend(
  {
    groupId: "path.param.number",
  },
  (p) => `/${p.groupId}`
);
//const agentEmail = agentDetails.extend("/email");
const agentMessaging = agentDetails.extend("/messaging");
const agentMessagingByTab = agentMessaging.extend(
  {
    tabId: "path.param.number",
  },
  (p) => `/${p.tabId}`
);
const agentProduction = agentDetails.extend("/production");
const agentAppointments = agentDetails.extend("/appointments");
const manageRTS = agentDetails.extend("/manageRTS");
const agentPolicies = agentDetails.extend("/policies");
const agentContracts = agentDetails.extend("/contracts");
const agentContractSummary = agentDetails.extend("/contractsummary");
const agentCreditCheckHistory = agentDetails.extend("/creditcheck");
const agentStateLicenses = agentDetails.extend("/statelicenses");
const agentAutoAppointments = agentAppointments.extend("/autoappointments");
const pdfViewer = agentDetails.extend(
  {
    docId: "path.param.number",
    typeId: "path.param.number",
  },
  (p) => `/pdfViewer/${p.docId}/${p.typeId}`
);
const dmsDocsViewer = defineRoute(
  {
    docId: "path.param.number",
    typeId: "path.param.number",
  },
  (p) => `/documentviewer/${p.docId}/${p.typeId}`
);
const workflowDocsViewer = defineRoute(
  {
    docId: "path.param.number",
  },
  (p) => `/workflowviewer/${p.docId}`
);
const agentContractsMarketer = agentContracts.extend(
  {
    marketerId: "path.param.number",
    divisionId: "path.param.number",
  },
  (p) => `/${p.marketerId}/${p.divisionId}`
);
const agentTasks = agentDetails.extend("/tasks");
const agentDocs = agentDetails.extend("/docs");
const agentOrders = agentDetails.extend("/orders");
const agentAccount = agentDetails.extend("/account");
const contractDetails = agentContracts.extend(
  {
    contractId: "path.param.number",
  },
  (p) => `/details/${p.contractId}`
);
const agent = defineRoute(
  {
    agentID: "path.param.number",
  },
  (p) => `/agent/${p.agentID}`
);
const home = defineRoute("/");
const noAccess = defineRoute("/noAccess");
const mapsView = defineRoute("/maps");
const emailsView = defineRoute("/emails");
const emailDownloadWitoutType = emailsView.extend(
  {
    fileName: "path.param.string",
  },
  (p) => `/fileName=${p.fileName}`
);
const emailDownload = emailsView.extend(
  {
    fileName: "path.param.string",
    reportType: "path.param.string",
  },
  (p) => `/fileName=${p.fileName}/${p.reportType}`
);

// const marketingEvent = home.extend("/marketingEvent");
const marketingEvent = defineRoute("/marketingEvent");
const agentSearch = defineRoute("/agents");
const rtsReview = defineRoute("/rtsReview");
const bookOfBusiness = defineRoute("/bookOfBusiness");

const geolocationDownload = agentSearch.extend(
  {
    zipcode: "path.param.string",
    carrier: "path.param.string",
    distance: "path.param.string",
  },
  (p) => `/zipcode=${p.zipcode}/${p.carrier}/${p.distance}`
);

const customerSearch = defineRoute("/customers");
const addCustomer = defineRoute("/addCustomer");
const mergeCustomers = defineRoute("/mergeCustomers");
const mergeAgents = defineRoute("/mergeAgents");
const customerDetails = defineRoute(
  {
    customerId: "path.param.string",
  },
  (p) => `/customers/${p.customerId}`
);
const customerNotes = customerDetails.extend("/notes");
const customerSummary = customerDetails.extend("/summary");
const customerTabNotes = customerDetails.extend("/insurednotes");
const customerPolicies = customerDetails.extend("/policies");
const customerDocs = customerDetails.extend("/docs");
const agentDocsViewer = agentDetails.extend(
  {
    docId: "path.param.number",
    typeId: "path.param.number",
  },
  (p) => `/customerDocsViewer/${p.docId}/${p.typeId}`
);
const customerDocsViewer = customerDetails.extend(
  {
    docId: "path.param.number",
    typeId: "path.param.number",
  },
  (p) => `/customerDocsViewer/${p.docId}/${p.typeId}`
);
const customerTasks = customerDetails.extend("/tasks");
const administration = defineRoute("/administration");
const tasks = defineRoute("/tasks");
const accounting = administration.extend("/accounting");
const autoDialer = defineRoute("/dialer");
const backtoautodialer = defineRoute("/backtodialer");
const general = administration.extend("/general");
const carrierBankDeposit = accounting.extend("/carrierbankdeposit");
const creditCardCharge = accounting.extend("/creditCardCharge");
const accountingJournal = accounting.extend("/accountingJournal");
const creditCardChargeRequest = accounting.extend("/ccchargerequest");
const announcement = general.extend("/announcement");
const carrier = general.extend("/carriers");
// const carrierManagement = administration.extend("/carrierManagement");
const carrierManagement = defineRoute("/carrierManagement");
const addCarrierManagement = carrierManagement.extend("/add");
const carrierCMList = carrierManagement.extend("/carriers");
const carrierGroupCMList = carrierManagement.extend("/carrierGroups");
const carrierGroups = general.extend("/carriergroups");
const licensing = administration.extend("/licensing");
const licenseStatusReasons = licensing.extend("/licensestatusreasons");
const licensesplitter = licensing.extend("/licensesplitter");
const licenseDashboard = licensing.extend("/licenseDashboard");
const adminDocuments = administration.extend("/documents");
const renewalImportUndo = general.extend("/RevenueImportUndo");
const certifications = administration.extend("/certifications");
const adminSupplies = administration.extend("/supplies");
const agentLeadDisabledCarrier = administration.extend(
  "/sales/agentLeadDisabledCarrier"
);
const agentGroups = administration.extend("/agentgroups");
const leads = defineRoute("/marketerleads");
const salesLeads = defineRoute("/salesopsleads");
const demotest = defineRoute("/StyleGuide");
const supplies = defineRoute("/supplies");
const reports = defineRoute("/reports");
// const carrierManagement = defineRoute("/carrierManagement");
const standardReports = reports.extend("/standardReports");
const customReports = reports.extend("/customReports");
const reportsQueue = reports.extend("/reportsqueue");
const reportsQueueDownload = reportsQueue.extend(
  {
    id: "path.param.number",
  },
  (p) => `/${p.id}`
);
const addCustomReport = customReports.extend("/add");
const editCustomReport = customReports.extend(
  {
    id: "path.param.number",
  },
  (p) => `/editCustomReport/${p.id}`
);
const previewCustomReport = customReports.extend(
  {
    id: "path.param.number",
  },
  (p) => `/previewCustomReport/${p.id}`
);
const previewCarrierManagement = carrierManagement.extend(
  {
    Id: "path.param.number",
  },
  (p) => `/previewCarrierManagement/${p.Id}`
);
const documents = defineRoute("/documents");
const needhelp = defineRoute("/needhelp");
const termsofuse = defineRoute("/termsofuse");
const products = general.extend("/products");
const productLines = general.extend("/productlines");
const emailFaxDetails = general.extend("/emailfaxdetails");
const manageAgentDept = general.extend("/manageagentdept");
const passwordlist = general.extend("/passwordlist");
const importAgentCertificates = general.extend("/importagentcertificates");
const importagentdebts = accounting.extend("/importagentdebts");
const importlicensepersistencytypes = licensing.extend(
  "/importlicensepersistencytypes"
);
const manageimportagentdebtsimport = accounting.extend(
  "/manageimportagentdebtsimport"
);
const printmailshipping = general.extend("/printmailshipping");
const productSubCategory = general.extend("/productsubcategories");
const webLinks = general.extend("/weblinks");
const notification = general.extend("/notifications");
const ManageAttributes = general.extend("/ManageAttributes");
const BulkUpload = general.extend("/BulkUpload");
const licenseManagement = general.extend("/licenseManagement");

// const PreviewNotification = notification.extend("/preview");
const PreviewNotification = notification.extend(
  {
    Id: "path.param.string",
  },
  (p) => `/preview/${p.Id}`
);

const role = administration.extend("/roles");
const team = administration.extend("/teams");
const commissionPlan = administration.extend("/commissionplans");
const user = administration.extend("/users");
const userActivity = administration.extend("/userActivity");
const policyAdjustments = administration.extend("/policyAdjustments");
const userAuditTrail = administration.extend("/userAuditTrail");
const userAuditReport = administration.extend("/userAuditReport");
const policyDashboard = administration.extend("/policyDashboard");
const renewalDashboard = administration.extend("/revenueDashboard");
const renewal = administration.extend("/revenue");
const companyLicenseNumberDefaults = licensing.extend(
  "/companyLicenseNumberDefaults"
);
const marketerMailConfiguration = licensing.extend(
  "/marketerMailConfiguration"
);
const submissionManagement = general.extend("/submissionManagement");
const documentFileType = licensing.extend("/manageDocumentFileTypes");
const listImports = administration.extend("/listImports");
const manageColumnDefinition = administration.extend("/manageColumnDefinition");

const productDetails = products.extend(
  {
    productId: "path.param.string",
  },
  (p) => `/${p.productId}`
);

const deductibles = productDetails.extend("/deductibles");

const searchWorkflowDocs = defineRoute("/searchWorkflowDocs");
const docWorkflow = defineRoute("/docWorkflow");
const documentDetails = docWorkflow.extend(
  {
    documentId: "path.param.number",
  },
  (p) => `/documentInfo/${p.documentId}`
);
const editContract = agentContracts.extend(
  {
    contractId: "path.param.number",
  },
  (p) => `/edit/${p.contractId}`
);

export const { routes, listen, getCurrentRoute, history } = createRouter({
  //home: defineRoute("/"),
  logout: defineRoute("/logout"),
  home,
  marketingEvent,
  listMarketingEvent: marketingEvent.extend("/list"),
  addMarketingEvent: marketingEvent.extend("/add"),
  editMarketingEvent: marketingEvent.extend(
    {
      marketingEventId: "path.param.number",
    },
    (p) => `/edit/${p.marketingEventId}`
  ),
  marketingEventDetail: marketingEvent.extend(
    {
      marketingEventId: "path.param.number",
    },
    (p) => `/details/${p.marketingEventId}`
  ),

  agentSearch,
  bookOfBusiness,
  rtsReview,
  noAccess,
  mapsView,
  emailsView,
  emailDownloadWitoutType,
  emailDownload,

  geolocationDownload,
  addAgent: agentSearch.extend("/add"),

  agent,
  agentDetails,
  editAgent: agentDetails.extend("/edit"),
  agentSummary: agentDetails.extend("/summary"),
  agentNotes,
  agentNotesByUser,
  agentPolicies,
  agentAppointments,
  agentAutoAppointments,
  manageRTS,
  //agentEmail,
  agentMessaging,
  agentMessagingByTab,
  agentProduction,
  agentContracts,
  agentGroupDetails,
  agentContractSummary,
  agentStateLicenses,
  addAgentStateLicense: agentStateLicenses.extend("/add"),
  editAgentStateLicense: agentStateLicenses.extend(
    {
      Id: "path.param.number",
    },
    (p) => `/edit/${p.Id}`
  ),
  agentContractsMarketer,
  agentCreditCheckHistory,
  pdfViewer,
  customerDocsViewer,
  agentDocsViewer,
  dmsDocsViewer,
  workflowDocsViewer,
  addContract: agentContracts.extend("/add"),
  editContract,
  replaceContract: agentContracts.extend(
    {
      contractId: "path.param.number",
    },
    (p) => `/replace-contract/${p.contractId}`
  ),

  addNote: agentNotes.extend("/add"),
  contractDetails,
  policyDetails: agentPolicies.extend(
    {
      policyId: "path.param.number",
    },
    (p) => `/details/${p.policyId}`
  ),
  editAgentPolicy: agentPolicies.extend(
    {
      policyId: "path.param.number",
    },
    (p) => `/edit/${p.policyId}`
  ),

  editAgentNote: agentNotes.extend(
    {
      noteId: "path.param.number",
    },
    (p) => `/edit/${p.noteId}`
  ),
  agentTasks,
  addTask: agentTasks.extend("/add"),
  editTask: agentTasks.extend(
    {
      taskId: "path.param.number",
    },
    (p) => `/edit/${p.taskId}`
  ),
  agentDocs,
  addDoc: agentDocs.extend("/add"),
  editDoc: agentDocs.extend(
    {
      docId: "path.param.number",
    },
    (p) => `/edit/${p.docId}`
  ),

  agentOrders,
  orderDetails: agentOrders.extend(
    {
      orderId: "path.param.number",
    },
    (p) => `/details/${p.orderId}`
  ),
  addSupplyOrder: agentOrders.extend("/add"),
  editSupplyOrder: agentOrders.extend(
    {
      orderId: "path.param.number",
    },
    (p) => `/edit/${p.orderId}`
  ),
  addSupplyItem: agentOrders.extend("/supplyItem"),
  agentTripPoints: agentDetails.extend("/points"),
  agentProfile: agentDetails.extend("/profile"),
  agentAccount,
  addAgentDebt: agentAccount.extend("/addAgentDebt"),
  editAgentDebt: agentAccount.extend(
    {
      debtId: "path.param.number",
    },
    (p) => `/editAgentDebt/${p.debtId}`
  ),
  agentWorkflow: agentDetails.extend("/workflow"),
  administration,
  demotest,
  reports,
  customReports,
  addCustomReport,
  editCustomReport,
  previewCustomReport,
  standardReports,
  reportsQueue,
  reportsQueueDownload,
  autoDialer,
  startCalling: autoDialer.extend(
    {
      callListId: "path.param.number",
    },
    (p) => `/${p.callListId}`
  ),
  backToAutoDialer: backtoautodialer,
  supplies,
  suppliesOrderDetails: supplies.extend(
    {
      orderId: "path.param.number",
    },
    (p) => `/details/${p.orderId}`
  ),
  suppliesAddOrder: supplies.extend("/add"),
  suppliesEditOrder: supplies.extend(
    {
      orderId: "path.param.number",
    },
    (p) => `/edit/${p.orderId}`
  ),
  documents,
  needhelp,
  termsofuse,
  accounting,
  general,
  userActivity,
  policyAdjustments,
  userAuditTrail,
  userAuditReport,
  licensing,
  announcement,
  listAnnouncement: announcement.extend("/list"),
  addAnnouncement: announcement.extend("/add"),
  editAnnouncement: announcement.extend(
    {
      Id: "path.param.number",
    },
    (p) => `/edit/${p.Id}`
  ),
  carrier,
  listCarrier: carrier.extend("/list"),
  addCarrier: carrier.extend("/add"),
  editCarrier: carrier.extend(
    {
      Id: "path.param.number",
    },
    (p) => `/edit/${p.Id}`
  ),
  carrierManagement,
  addCarrierManagement,
  editCarrierManagement: carrierManagement.extend(
    {
      Id: "path.param.number",
    },
    (p) => `/edit/${p.Id}`
  ),
  carrierCMList,
  previewCarrierManagement,
  carrierGroupCMList,
  role,
  listRole: role.extend("/list"),
  rolePermissions: role.extend(
    {
      Id: "path.param.number",
    },
    (p) => `/rolePermissions/${p.Id}`
  ),
  notification,
  ManageAttributes,
  BulkUpload,
  licenseManagement,
  PreviewNotification,
  listNotification: notification.extend("/list"),
  commissionPlan,
  listCommissionplan: commissionPlan.extend("/list"),
  addCommissionplan: commissionPlan.extend("/add"),
  editCommissionplan: commissionPlan.extend(
    {
      Id: "path.param.number",
    },
    (p) => `/edit/${p.Id}`
  ),
  policyDashboard,
  renewalDashboard,
  renewal,
  addRenewal: renewal.extend("/add"),
  editRenewal: renewal.extend(
    {
      Id: "path.param.number",
    },
    (p) => `/edit/${p.Id}`
  ),
  user,
  listUser: user.extend("/list"),
  addUser: user.extend("/add"),
  editUser: user.extend(
    {
      Id: "path.param.number",
    },
    (p) => `/edit/${p.Id}`
  ),
  carrierGroups,
  listCarrierGroups: carrierGroups.extend("/list"),
  addCarrierGroups: carrierGroups.extend("/add"),
  editCarrierGroups: carrierGroups.extend(
    {
      Id: "path.param.number",
    },
    (p) => `/edit/${p.Id}`
  ),
  accountingJournal,
  creditCardCharge,
  listCreditCardCharge: creditCardCharge.extend("/list"),
  editCreditCardCharge: creditCardCharge.extend(
    {
      CreditCardChargeID: "path.param.number",
    },
    (p) => `/edit/${p.CreditCardChargeID}`
  ),

  creditCardChargeRequest,
  listCreditCardChargeRequest: creditCardChargeRequest.extend("/list"),
  editCreditCardChargeRequest: creditCardChargeRequest.extend(
    {
      Id: "path.param.number",
    },
    (p) => `/edit/${p.Id}`
  ),

  companyLicenseNumberDefaults,
  listCompanyLicenseNumberDefaults:
    companyLicenseNumberDefaults.extend("/list"),
  addCompanyLicenseNumberDefaults: companyLicenseNumberDefaults.extend("/add"),
  editCompanyLicenseNumberDefaults: companyLicenseNumberDefaults.extend(
    {
      Id: "path.param.number",
    },
    (p) => `/edit/${p.Id}`
  ),
  licensesplitter,
  listLicenseSplitter: licensesplitter.extend("/list"),
  licensesplittercontractDetails: licensesplitter.extend(
    {
      contractId: "path.param.number",
      agentId: "path.param.number",
    },
    (p) => `/agents/${p.agentId}/contracts/details/${p.contractId}`
  ),
  licenseDashboard,

  licenseStatusReasons,
  listLicenseStatusReasons: licenseStatusReasons.extend("/list"),
  addLicenseStatusReasons: licenseStatusReasons.extend("/add"),
  editLicenseStatusReasons: licenseStatusReasons.extend(
    {
      Id: "path.param.number",
    },
    (p) => `/edit/${p.Id}`
  ),
  documentFileType,
  listDocumentFileType: documentFileType.extend("/list"),
  addDocumentFileType: documentFileType.extend("/add"),
  editDocumentFileType: documentFileType.extend(
    {
      Id: "path.param.number",
    },
    (p) => `/edit/${p.Id}`
  ),

  marketerMailConfiguration,
  listMarketerMailConfiguration: marketerMailConfiguration.extend("/list"),
  carrierBankDeposit,
  listCarrierBankDeposit: carrierBankDeposit.extend("/list"),
  addCarrierBankDeposit: carrierBankDeposit.extend("/add"),
  editCarrierBankDeposit: carrierBankDeposit.extend(
    {
      Id: "path.param.number",
    },
    (p) => `/edit/${p.Id}`
  ),
  renewalImportUndo,
  listRenewalImports: administration.extend("/backoutRevenueImport"),
  renewalBackout: administration.extend("/revenueBackout"),
  processCommissions: administration.extend("/processCommissions"),
  printCommissions: administration.extend("/printCommissions"),
  payoutLevels: administration.extend("/payoutLevels"),
  tasks,
  addMyTask: tasks.extend("/add"),
  editMyTask: tasks.extend(
    {
      taskId: "path.param.number",
    },
    (p) => `/edit/${p.taskId}`
  ),
  customerSearch,
  customerDetails,
  customerSummary,
  customerNotes,
  customerTasks,
  customerTabNotes,
  customerPolicies,
  customerDocs,
  addCustomer,
  mergeCustomers,
  mergeAgents,
  addPolicy: customerDetails.extend("/addPolicy"),
  addCustomerDoc: customerDetails.extend("/addDoc"),
  editCustomer: customerDetails.extend("/edit"),
  customerpolicyDetails: customerDetails.extend(
    {
      policyId: "path.param.number",
    },
    (p) => `/details/${p.policyId}`
  ),
  customerpolicyRevenueHistoryDetails: customerDetails.extend(
    {
      policyId: "path.param.number",
      tabIndex: "path.param.number",
    },
    (p) => `/details/${p.policyId}/${p.tabIndex}`
  ),
  editCustomerPolicy: customerDetails.extend(
    {
      policyId: "path.param.number",
    },
    (p) => `/edit/${p.policyId}`
  ),
  editCustomerTask: customerDetails.extend(
    {
      taskId: "path.param.number",
    },
    (p) => `/editTask/${p.taskId}`
  ),
  docWorkflow,
  documentInfo: documentDetails.extend(
    {
      workflowStatus: "path.param.string",
    },
    (p) => `/${p.workflowStatus}`
  ),
  licenseCommunication: documentDetails.extend("/contacts"),
  searchWorkflowDocs,
  salesLeads,
  listSalesLeads: salesLeads.extend("/list"),
  unmatchedLeads: salesLeads.extend("/unmatched"),
  detailUnmatchedLeads: salesLeads.extend(
    {
      AgentLeadId: "path.param.number",
    },
    (p) => `/unmatched/${p.AgentLeadId}`
  ),
  addSaleOpsLead: salesLeads.extend("/add"),
  detailSaleOpsLeads: salesLeads.extend(
    {
      AgentLeadId: "path.param.number",
    },
    (p) => `/saleops/${p.AgentLeadId}`
  ),
  editSaleOpsLead: salesLeads.extend(
    {
      AgentLeadId: "path.param.number",
    },
    (p) => `/edit/${p.AgentLeadId}`
  ),

  leads,
  listLeads: leads.extend("/list"),
  contractedLeads: leads.extend("/contracted"),
  addAgentLead: leads.extend("/add"),
  detailNonContarctedLeads: leads.extend(
    {
      AgentLeadId: "path.param.number",
    },
    (p) => `/non-contracted/${p.AgentLeadId}`
  ),
  detailContarctedLeads: leads.extend(
    {
      AgentLeadId: "path.param.number",
    },
    (p) => `/contracted/${p.AgentLeadId}`
  ),

  editAgentLead: leads.extend(
    {
      AgentLeadId: "path.param.number",
    },
    (p) => `/edit/${p.AgentLeadId}`
  ),
  error: defineRoute("/error"),
  contractLevels: contractDetails.extend("/levels"),
  addContractLevel: contractDetails.extend("/addLevel"),
  editContractLevel: contractDetails.extend(
    {
      levelId: "path.param.number",
    },
    (p) => `/editLevel/${p.levelId}`
  ),
  addProductDetails: editContract.extend("/addLevel"),
  editProductDetails: editContract.extend(
    {
      levelId: "path.param.number",
    },
    (p) => `/editLevel/${p.levelId}`
  ),
  contractPaymentPlans: contractDetails.extend("/paymentPlans"),
  addPaymentPlan: contractDetails.extend("/addPaymentPlan"),
  editPaymentPlan: contractDetails.extend(
    {
      paymentPlanId: "path.param.number",
    },
    (p) => `/editPaymentPlan/${p.paymentPlanId}`
  ),
  addPaymentPlanFromLevel: contractDetails.extend(
    {
      levelId: "path.param.number",
    },
    (p) => `/levels/${p.levelId}/addPaymentPlan`
  ),
  editContractPaymentPlans: editContract.extend("/paymentPlans"),
  editContractAddPaymentPlan: editContract.extend("/addPaymentPlan"),
  editContractEditPaymentPlan: editContract.extend(
    {
      paymentPlanId: "path.param.number",
    },
    (p) => `/editPaymentPlan/${p.paymentPlanId}`
  ),
  editContractAddPaymentPlanFromLevel: editContract.extend(
    {
      levelId: "path.param.number",
    },
    (p) => `/levels/${p.levelId}/addPaymentPlan`
  ),
  products,
  listProduct: products.extend("/list"),
  addProduct: products.extend("/add"),
  editProduct: products.extend(
    {
      Id: "path.param.number",
    },
    (p) => `/edit/${p.Id}`
  ),
  productLines,
  listProductLines: productLines.extend("/list"),
  addProductLine: productLines.extend("/add"),
  editProductLine: productLines.extend(
    {
      Id: "path.param.number",
    },
    (p) => `/edit/${p.Id}`
  ),
  certifications,
  listImports,
  addImports: listImports.extend("/add"),
  editImports: listImports.extend(
    {
      Id: "path.param.number",
    },
    (p) => `/edit/${p.Id}`
  ),
  manageColumnDefinition,
  listCertifications: certifications.extend("/list"),
  addCertifications: certifications.extend("/add"),
  editCertifications: certifications.extend(
    {
      Id: "path.param.number",
    },
    (p) => `/edit/${p.Id}`
  ),
  adminSupplies,
  listAdminSupplies: adminSupplies.extend("/list"),
  addAdminSupplies: adminSupplies.extend("/add"),
  editAdminSupplies: adminSupplies.extend(
    {
      Id: "path.param.number",
    },
    (p) => `/edit/${p.Id}`
  ),
  cloneAdminSupplies: adminSupplies.extend(
    {
      Id: "path.param.number",
    },
    (p) => `/clone/${p.Id}`
  ),
  agentLeadDisabledCarrier,
  listAgentLeadDisabledCarrier: agentLeadDisabledCarrier.extend("/list"),
  addAgentLeadDisabledCarrier: agentLeadDisabledCarrier.extend("/add"),
  editAgentLeadDisabledCarrier: agentLeadDisabledCarrier.extend(
    {
      Id: "path.param.number",
    },
    (p) => `/edit/${p.Id}`
  ),
  webLinks,
  listWebLinks: webLinks.extend("/list"),
  addWebLink: webLinks.extend("/add"),
  editWebLink: webLinks.extend(
    {
      Id: "path.param.number",
    },
    (p) => `/edit/${p.Id}`
  ),
  agentGroups,
  listagentGroup: agentGroups.extend("/list"),
  addagentGroup: agentGroups.extend("/add"),
  editagentGroup: agentGroups.extend(
    {
      Id: "path.param.number",
    },
    (p) => `/edit/${p.Id}`
  ),
  submissionManagement,
  submissionMethod: submissionManagement.extend("/submissionMethod"),
  autoUnchecks: submissionManagement.extend("/autoUnchecks"),
  requirements: submissionManagement.extend("/requirements"),
  submissionTypes: submissionManagement.extend("/submissionTypes"),
  senderSubmissionTypes: submissionManagement.extend("/senderSubmissionTypes"),
  viewSubmissionMethod: submissionManagement.extend("/submissionMethod/view"),
  viewRequirement: submissionManagement.extend("/requirements/view"),
  addSubmissionMethod: submissionManagement.extend("/submissionMethod/add"),
  addSubmissionMethodConnector: submissionManagement.extend(
    "/submissionMethod/add/connector/add"
  ),
  editSubmissionMethodConnector: submissionManagement.extend(
    {
      Id: "path.param.number",
    },
    (p) => `/submissionMethod/add/connector/edit/${p.Id}/`
  ),
  editSubmissionMethod: submissionManagement.extend(
    {
      Id: "path.param.number",
    },
    (p) => `/submissionMethod/edit/${p.Id}`
  ),
  addSubmissionMethodConnectorOnEdit: submissionManagement.extend(
    {
      Id: "path.param.number",
    },
    (p) => `/submissionMethod/edit/${p.Id}/connector/add`
  ),
  editSubmissionMethodConnectorOnEdit: submissionManagement.extend(
    {
      Id: "path.param.number",
      connectorId: "path.param.number",
    },
    (p) => `/submissionMethod/edit/${p.Id}/connector/edit/${p.connectorId}`
  ),
  addAutoUnchecks: submissionManagement.extend("/autoUnchecks/add"),
  editAutoUnchecks: submissionManagement.extend(
    {
      Id: "path.param.number",
    },
    (p) => `/autoUnchecks/edit/${p.Id}`
  ),
  addRequirements: submissionManagement.extend("/requirements/add"),
  editRequirements: submissionManagement.extend(
    {
      Id: "path.param.number",
    },
    (p) => `/requirements/edit/${p.Id}`
  ),
  addSubmissionTypes: submissionManagement.extend("/submissionTypes/add"),
  editSubmissionTypes: submissionManagement.extend(
    {
      Id: "path.param.number",
      type: "path.param.string",
    },
    (p) => `/submissionTypes/edit/${p.Id}/${p.type}`
  ),
  deductibles,
  listDeductible: deductibles.extend("/list"),
  addDeductible: deductibles.extend("/add"),
  editDeductible: deductibles.extend(
    {
      deductibleId: "path.param.number",
    },
    (p) => `/edit/${p.deductibleId}`
  ),
  emailFaxDetails,
  listEmailFaxDetails: emailFaxDetails.extend("/list"),
  addEmailFaxDetails: emailFaxDetails.extend("/add"),
  editEmailFaxDetails: emailFaxDetails.extend(
    {
      Id: "path.param.number",
    },
    (p) => `/edit/${p.Id}`
  ),
  manageAgentDept,
  listManageAgentDept: manageAgentDept.extend("/list"),
  addManageAgentDept: manageAgentDept.extend("/add"),
  editManageAgentDept: manageAgentDept.extend(
    {
      Id: "path.param.number",
    },
    (p) => `/edit/${p.Id}`
  ),
  productSubCategory,
  listProductSubCategory: productSubCategory.extend("/list"),
  productDetails: productSubCategory.extend(
    {
      Id: "path.param.number",
    },
    (p) => `/details/${p.Id}`
  ),
  addProductSubCategory: productSubCategory.extend("/add"),
  editProductSubCategory: productSubCategory.extend(
    {
      Id: "path.param.number",
    },
    (p) => `/edit/${p.Id}`
  ),
  adminDocuments,
  addDocument: adminDocuments.extend("/add"),
  editDocument: adminDocuments.extend(
    {
      documentId: "path.param.number",
    },
    (p) => `/edit/${p.documentId}`
  ),
  updateDocument: adminDocuments.extend(
    {
      documentId: "path.param.number",
    },
    (p) => `/update/${p.documentId}`
  ),
  cloneDocument: adminDocuments.extend(
    {
      documentId: "path.param.number",
    },
    (p) => `/clone/${p.documentId}`
  ),
  packets: adminDocuments.extend("/packet"),
  addPacket: adminDocuments.extend("/packet/add"),
  editPacket: adminDocuments.extend(
    {
      packetId: "path.param.number",
    },
    (p) => `/packet/edit/${p.packetId}`
  ),
  importAgentCertificates,
  importAgentCertificatesDownloadFile: importAgentCertificates.extend(
    {
      filename: "path.param.string",
    },
    (p) => `/downloadfile/${p.filename}`
  ),
  importagentdebts,
  importlicensepersistencytypes,
  manageimportagentdebtsimport,
  printmailshipping,
  passwordlist,
  listPasswordList: passwordlist.extend("/list"),
  addPasswordList: passwordlist.extend("/add"),
  editPasswordList: passwordlist.extend(
    {
      Id: "path.param.number",
    },
    (p) => `/edit/${p.Id}`
  ),
  team,
  listTeam: team.extend("/list"),
});

export const carrierBankDepositGroup = createGroup([
  routes.listCarrierBankDeposit,
  routes.addCarrierBankDeposit,
  routes.editCarrierBankDeposit,
]);

export const announcementGroup = createGroup([
  routes.listAnnouncement,
  routes.addAnnouncement,
  routes.editAnnouncement,
]);

export const creditCardChargeGroup = createGroup([
  routes.listCreditCardCharge,
  routes.editCarrierBankDeposit,
  routes.listCreditCardChargeRequest,
]);
export const customerGroup = createGroup([
  routes.customerDetails,
  routes.customerNotes,
  routes.customerSummary,
  routes.customerTasks,
  routes.customerTabNotes,
  routes.customerPolicies,
  routes.customerDocs,
  routes.addPolicy,
  routes.addCustomerDoc,
  routes.customerpolicyDetails,
  routes.customerpolicyRevenueHistoryDetails,
  routes.editCustomerPolicy,
  routes.editCustomerTask,
]);

export const importGroup = createGroup([
  routes.listImports,
  routes.addImports,
  routes.editImports,
  routes.manageColumnDefinition,
]);

export const accountingGroup = createGroup([
  routes.accounting,
  routes.importagentdebts,
  carrierBankDepositGroup,
]);

export const commissionGroup = createGroup([
  routes.listCommissionplan,
  routes.addCommissionplan,
  routes.editCommissionplan,
  routes.policyDashboard,
  routes.renewalDashboard,
  routes.renewal,
  routes.addRenewal,
  routes.editRenewal,
  routes.listRenewalImports,
  routes.renewalBackout,
  routes.processCommissions,
  routes.printCommissions,
]);

export const licensingGroup = createGroup([
  routes.listLicenseStatusReasons,
  routes.listDocumentFileType,
  routes.listLicenseSplitter,
  routes.payoutLevels,
  routes.licenseDashboard,
  routes.importlicensepersistencytypes,
]);

export const appSettingGroup = createGroup([
  routes.listRole,
  routes.rolePermissions,
  routes.listTeam,
  routes.listUser,
  routes.addUser,
  routes.editUser,
  routes.userActivity,
  routes.userAuditTrail,
  routes.userAuditReport,
  routes.policyAdjustments,
]);

export const reportGroup = createGroup([
  routes.reports,
  routes.customReports,
  routes.standardReports,
  routes.reportsQueue,
  routes.addCustomReport,
  routes.editCustomReport,
  routes.previewCustomReport,
  routes.reportsQueueDownload,
]);

export const submissionGroup = createGroup([
  routes.submissionManagement,
  routes.submissionMethod,
  routes.autoUnchecks,
  routes.requirements,
  routes.submissionTypes,
  routes.senderSubmissionTypes,
  routes.addSubmissionMethod,
  routes.editSubmissionMethod,
  routes.addSubmissionMethodConnector,
  routes.editSubmissionMethodConnector,
  routes.addSubmissionMethodConnectorOnEdit,
  routes.editSubmissionMethodConnectorOnEdit,
  routes.addAutoUnchecks,
  routes.editAutoUnchecks,
  routes.addRequirements,
  routes.editRequirements,
  routes.addSubmissionTypes,
  routes.editSubmissionTypes,
  routes.viewRequirement,
  routes.viewSubmissionMethod,
]);

export const carrierManagementGroup = createGroup([
  routes.carrierManagement,
  routes.carrierCMList,
  routes.carrierGroupCMList,
  routes.addCarrierManagement,
  routes.previewCarrierManagement,
  routes.editCarrierManagement,
]);

export const administrationGroup = createGroup([
  routes.administration,
  routes.listCreditCardCharge,
  routes.listCreditCardChargeRequest,
  routes.listCarrierBankDeposit,
  routes.listAnnouncement,
  routes.addAnnouncement,
  routes.addCarrierBankDeposit,
  routes.editAnnouncement,
  routes.editCarrierBankDeposit,
  routes.editCreditCardCharge,
  routes.editCreditCardChargeRequest,
  routes.listCarrier,
  routes.addCarrier,
  routes.editCarrier,
  routes.listCarrierGroups,
  routes.addCarrierGroups,
  routes.editCarrierGroups,
  routes.listLicenseStatusReasons,
  routes.addLicenseStatusReasons,
  routes.editLicenseStatusReasons,
  routes.listLicenseSplitter,
  routes.licenseDashboard,
  routes.listProduct,
  routes.addProduct,
  routes.editProduct,
  routes.listProductLines,
  routes.addProductLine,
  routes.editProductLine,
  routes.listCertifications,
  routes.addCertifications,
  routes.editCertifications,
  routes.listAdminSupplies,
  routes.addAdminSupplies,
  routes.editAdminSupplies,
  routes.cloneAdminSupplies,
  routes.listAgentLeadDisabledCarrier,
  routes.addAgentLeadDisabledCarrier,
  routes.editAgentLeadDisabledCarrier,
  routes.listWebLinks,
  routes.addWebLink,
  routes.editWebLink,
  routes.listagentGroup,
  routes.addagentGroup,
  routes.editagentGroup,
  routes.submissionManagement,
  routes.submissionMethod,
  routes.autoUnchecks,
  routes.requirements,
  routes.submissionTypes,
  routes.senderSubmissionTypes,
  routes.addSubmissionMethod,
  routes.editSubmissionMethod,
  routes.addSubmissionMethodConnector,
  routes.editSubmissionMethodConnector,
  routes.addSubmissionMethodConnectorOnEdit,
  routes.editSubmissionMethodConnectorOnEdit,
  routes.addAutoUnchecks,
  routes.editAutoUnchecks,
  routes.addRequirements,
  routes.editRequirements,
  routes.addSubmissionTypes,
  routes.editSubmissionTypes,
  routes.viewRequirement,
  routes.viewSubmissionMethod,
  routes.listDeductible,
  routes.addDeductible,
  routes.editDeductible,
  routes.listProductSubCategory,
  routes.addProductSubCategory,
  routes.editProductSubCategory,
  routes.productDetails,
  routes.adminDocuments,
  routes.addDocument,
  routes.editDocument,
  routes.updateDocument,
  routes.cloneDocument,
  routes.packets,
  routes.addPacket,
  routes.editPacket,
  announcementGroup,
  routes.listEmailFaxDetails,
  routes.addEmailFaxDetails,
  routes.editEmailFaxDetails,
  routes.importAgentCertificates,
  routes.importAgentCertificatesDownloadFile,
  routes.importagentdebts,
  routes.listManageAgentDept,
  routes.addManageAgentDept,
  routes.editManageAgentDept,
  routes.importlicensepersistencytypes,
  routes.printmailshipping,
  commissionGroup,
  appSettingGroup,
  licensingGroup,
  routes.passwordlist,
  routes.listPasswordList,
  routes.addPasswordList,
  routes.editPasswordList,
  routes.listMarketerMailConfiguration,
  routes.accountingJournal,
  routes.agentGroupDetails,
  importGroup,
  routes.notification,
  routes.PreviewNotification,
  routes.ManageAttributes,
  routes.BulkUpload,
  routes.licenseManagement
]);

export const carrierGroup = createGroup([
  routes.listCarrier,
  routes.addCarrier,
  routes.editCarrier,
]);

export const licenseStatusReasonGroup = createGroup([
  routes.licensing,
  routes.listLicenseStatusReasons,
  routes.addLicenseStatusReasons,
  routes.editLicenseStatusReasons,
]);

export const productGroup = createGroup([
  routes.listProduct,
  routes.addProduct,
  routes.editProduct,
]);

export const productSubCategoryGroup = createGroup([
  routes.listProductSubCategory,
  routes.addProductSubCategory,
  routes.editProductSubCategory,
]);

export const deductibleGroup = createGroup([
  routes.listDeductible,
  routes.addDeductible,
  routes.editDeductible,
]);

export const workflowGroup = createGroup([
  routes.documentInfo,
  routes.licenseCommunication,
]);

export const autoDialerGroup = createGroup([routes.startCalling]);

export const agentGroup = createGroup([
  // routes.agent,
  routes.agentDetails,
  routes.agentSummary,
  routes.agentNotes,
  routes.agentNotesByUser,
  routes.agentContracts,
  routes.agentContractsMarketer,
  routes.agentPolicies,
  routes.addContract,
  routes.editContract,
  routes.replaceContract,
  routes.addNote,
  routes.editAgentNote,
  routes.contractDetails,
  routes.policyDetails,
  routes.editAgentPolicy,
  routes.agentTasks,
  routes.addTask,
  routes.editTask,
  routes.agentDocs,
  routes.addDoc,
  routes.editDoc,
  routes.agentTripPoints,
  routes.agentOrders,
  routes.orderDetails,
  routes.addSupplyOrder,
  routes.editSupplyOrder,
  routes.addSupplyItem,
  routes.agentWorkflow,
  routes.agentAccount,
  routes.agentProfile,
  routes.addAgentDebt,
  routes.editAgentDebt,
  routes.contractPaymentPlans,
  routes.addPaymentPlan,
  routes.addPaymentPlanFromLevel,
  routes.editPaymentPlan,
  routes.editContractPaymentPlans,
  routes.editContractAddPaymentPlan,
  routes.editContractEditPaymentPlan,
  routes.editContractAddPaymentPlanFromLevel,
  routes.contractLevels,
  routes.addContractLevel,
  routes.editContractLevel,
  routes.addProductDetails,
  routes.editProductDetails,
  routes.agentAppointments,
  routes.manageRTS,
  routes.agentAutoAppointments,
  //routes.agentEmail,
  routes.agentMessaging,
  routes.agentMessagingByTab,
  routes.agentProduction,
  routes.agentStateLicenses,
  routes.addAgentStateLicense,
  routes.editAgentStateLicense,
]);
