import { AbstractValidator } from "fluent-ts-validator/AbstractValidator";
import { action, observable } from "mobx";
import Collection from "../../../../infrastructure/CollectionHelper";
import { ErrorModel } from "../../../../infrastructure/ErrorModel";
import Utils from "../../../../infrastructure/Utils";
import {
    CarrierGroupModel,
    CarrierGroupsListModel,
} from "../../../../services/ContractService";
import ContractsStore from "../../../../store/ContractsStore";
import { BaseListViewModel } from "../../../../viewModels/components/BaseListViewModel";
import { Id, toast } from "react-toastify";

export class CarrierGroupCMListViewModel extends BaseListViewModel<CarrierGroupsListModel> {
    @observable actionTitle: string = "";
    @observable isEditorVisible: boolean = false;
    @observable public ErrorModel = new ErrorModel(new CarrierGroupCMValidator());
    @observable isCarrierGroupToast: boolean = false;
    @observable timer: any;
    @observable CMchecked: boolean | undefined = false;
    @observable toasterIcon: string | null = "";
    @observable toasterMessage: string | null = "";
    @observable selectedGroupName: string = "";
    @observable hidden: boolean = false;
    @observable disableSave: boolean = false;
    @observable activeCarriers: any = "";
    @observable cmSearchInputValue: string = "";
    @observable rows: number = 50;
    @observable totalRecords: number = 0;
    @observable searchValue: string = "";
    @observable isHidden: boolean | null | undefined = null;
    @observable carrierGroupList = new Collection<CarrierGroupsListModel>();
    @observable selectedGlobalStatus: string = "";
    @observable selectedStatus: string = "1";
    @observable CarrierGroupStatusList = [
        { label: "Active", value: "1" },
        { label: "Inactive", value: "0" },
    ];
    @action setEditorVisibility(isVisible: boolean) {
        this.isEditorVisible = isVisible;

        clearTimeout(this.timer);
        this.timer = setTimeout(async () => {
            this.isCarrierGroupToast = false;
        }, 9000);
    }
    @action checkActiveCarrierOnhandleToggle = async (Id: number, groupname: string, hidden: boolean) => {
        this.selectedId = Id;
        this.hidden = hidden ? false : true;
        if (this.selectedId !== 0) {
            this.selectedGroupName = groupname;
            this.checkActiveCarriers();
        }
    };
    @action handleToggle = async (Id: number, groupname: string, hidden: boolean) => {

        this.selectedId = Id;
        this.hidden = hidden ? false : true;

        if (
            this.activeCarriers.length > 0 &&
            this.selectedId !== 0) {
            this.isEditorVisible = true;
        }
        else {
            this.isEditorVisible = false;
            this.selectedGroupName = groupname;
            //this.saveSelectedItem();
            var item = this.toServiceModel();
            await ContractsStore.updateCarriersGroup(item);
            this.loadItems();
            setTimeout(() => {
                this.toasterIcon = "cg-modified";
                localStorage.setItem("ssrCreateToast", this.toasterIcon);
                this.toasterMessage = item.groupName + " status";
                this.isCarrierGroupToast = true;
            }, 300);
        }

    };

    @action onEditorHide() {
        this.setEditorVisibility(false);
    }
    @action resetFilters = () => {
        this.searchValue = "";
        this.cmSearchInputValue = "";
        this.selectedGlobalStatus = "";
        this.isHidden = null;
        this.sortColumn = "id";
        this.isSortAscending = false;
        this.resetPagingValues();
    };

    @action resetPagingValues() {
        this.totalRecords = 0;
    }
    @action editItem = async (id: number) => {
        this.isCarrierGroupToast = false;
        if (id === 0) {
            this.actionTitle = "Add Carrier Group";
            this.selectedId = 0;
            this.selectedGroupName = "";
            this.hidden = false;
            this.disableSave = true;
        } else {
            this.actionTitle = "Edit Carrier Group";
            var item = await ContractsStore.getCarriersGroupById(id);
            this.activeCarriers = "";
            this.disableSave = false;
            this.selectedId = item.id || 0;
            this.selectedGroupName = item.groupName || "";
            this.hidden = item.hidden || false;
        }
        this.ResetValidate();
        this.setEditorVisibility(true);
    };
    @action addItem = async () => {
        this.editItem(0);
    };

    protected toServiceModel() {
        var dto: CarrierGroupModel = {
            id: this.selectedId,
            groupName: this.selectedGroupName,
            hidden: this.hidden,
        };
        return dto;
    }
    @action public Validate = async () => {
        return await this.ErrorModel.Validate(this);
    };
    @action public ResetValidate = async () => {
        return await this.ErrorModel.ResetValidation(this);
    };

    @action saveSelectedItem = async () => {
        try {
            this.isCarrierGroupToast = false;
            if (!(await this.Validate())) {
                var item = this.toServiceModel();
                if (this.selectedId === 0) {
                    await ContractsStore.addCarriersGroup(item);
                    this.toasterIcon = "cg-create";
                    localStorage.setItem("ssrCreateToast", this.toasterIcon);
                    this.toasterMessage = "Carrier Group " + item.groupName;
                    setTimeout(() => {
                        this.isCarrierGroupToast = true;
                    }, 300);
                } else {
                    setTimeout(() => {
                        this.toasterIcon = "cg-modified";
                        localStorage.setItem("ssrCreateToast", this.toasterIcon);
                        this.toasterMessage = item.groupName + " status";
                        this.isCarrierGroupToast = true;
                    }, 300);
                    await ContractsStore.updateCarriersGroup(item);
                }
                this.sortColumn = "id";
                this.isSortAscending = false;
                this.setEditorVisibility(false);
            }
            setTimeout(() => {
                this.Load();
            }, 10);
        } catch (e) {
            this.setEditorVisibility(false);
        }
    };
    @action checkActiveCarriers = async () => {
        this.isCarrierGroupToast = false;
        this.disableSave = true;
        if (this.hidden) {
            await this.getCarrierGroupByCompanyId();
        } else {
            this.disableSave = false;
            this.activeCarriers = "";
        }
    };

    @action getCarrierGroupByCompanyId = async () => {
        try {
            this.isCarrierGroupToast = false;
            let result = await ContractsStore.getCarrierGroupByCompanyId(
                this.selectedId
            );
            this.activeCarriers = "";
            let res: any = result;
            if (res) {
                if (res.length > 0) {
                    this.activeCarriers = result;
                    this.disableSave = true;
                } else {
                    this.disableSave = false;
                }
            } else {
                this.disableSave = false;
            }
        } catch (e) {
            this.disableSave = false;
        }
    };

    constructor() {
        super("Carrier Groups", "Carrier Group", null, null, "id", "id", false);
        this.pageSize = 150;
    }

    protected async getItems(
        pageIndex: number,
        pageSize: number,
        sortColumn: string,
        sortOrder: boolean
    ) {
        this.handleSessionCarrierGroup();
        return await ContractsStore.getAllCarriersGroups(
            this.selectedGlobalStatus ? this.selectedGlobalStatus === "0" : null,
            pageIndex,
            pageSize,
            sortColumn,
            sortOrder,
            this.cmSearchInputValue
        );
    }

    resetFiltersToDefault() {
        this.pageSize = 150;
        this.resetFilters();
    }

    @action downloadCarrierGroupListAsExcel = async () => {
        this.isCarrierGroupToast = false;
        let res = await ContractsStore.getCarrierGroupsAsExcel(
            this.selectedGlobalStatus ? this.selectedGlobalStatus === "0" : null,
            this.cmSearchInputValue
        );
        Utils.saveDownloadedReportFile(res, "Carriers Groups");
        setTimeout(() => {
            this.toasterIcon = "download";
            this.toasterMessage = "Carrier Group List downloaded.";
            this.isCarrierGroupToast = true;
        }, 300);

        clearTimeout(this.timer);
        this.timer = setTimeout(async () => {
            toast.dismiss();
            this.isCarrierGroupToast = false;
        }, 9000);
    };

    @action handleSessionCarrierGroup = () => {
        const searchInput = sessionStorage.getItem("searchInputValueGroup");
        const searchStatus = sessionStorage.getItem("selectedStatusGroup");
        if (
            (searchInput != null || searchStatus != null) &&
            this.cmSearchInputValue == "" &&
            this.selectedGlobalStatus == ""
        ) {
            this.cmSearchInputValue = String(searchInput === null ? "" : searchInput);
            this.selectedGlobalStatus = String(
                sessionStorage.getItem("selectedStatusGroup")
            );
            this.selectedStatus = String(
                sessionStorage.getItem("selectedStatusGroup")
            );
        }
    };
}

class CarrierGroupCMValidator extends AbstractValidator<CarrierGroupCMListViewModel> {
    public constructor() {
        super();
        this.validateIfString((input) => input.selectedGroupName)
            .isNotEmpty()
            .isNotEqualTo("0")
            .matches(/\S+/, "i")
            .withFailureMessage("Name for Carrier Group is required");
    }
}
