import { action } from "mobx";
import { getToken } from "../infrastructure/Auth0Authenticator";
import IMASLog from "../infrastructure/IMASLog";
import Utils from "../infrastructure/Utils";
import ServiceClient from "../infrastructure/client/ServiceClient";
import { ServiceType } from "../infrastructure/enum/ThemeSettings";
import {
  LicenseManagementRequest,
  SendLicenseReminderRequest,
  Client as StateLicenseService,
} from "../services/StateLicenseService";
import UserContext from "../infrastructure/UserContext";

class StateLicenseStore {
  protected jsonParseReviver: ((key: string, value: any) => any) | undefined =
    undefined;
  getApiVersion = () => {
    return "1";
  };
  getTimezoneOffset = () => {
    return new Date().getTimezoneOffset();
  };
  getApiKey = async (): Promise<string> => {
    var token = await getToken();
    return token ? `Bearer ${token}` : "";
  };

  getClient = (): StateLicenseService => {
    return ServiceClient.getClient(ServiceType.StateLicense);
  };

  @action getAllStateLicenseStatus = async () => {
    var result = await this.getClient().getAllStateLicenseStatus(
      this.getApiVersion(),
      UserContext.getUserId()
    );
    return result;
  };
  @action getAllStateLicenseActionStatus = async (statusId: number) => {
    var result = await this.getClient().getStateLicenseActionStatusLookup(
      this.getApiVersion(),
      statusId,
      UserContext.getUserId()
    );
    return result;
  };
  @action getAllLicenseManagement = async (
    pageIndex: number,
    pageSize: number,
    sortColumn: string,
    sortAscending: boolean,
    fullTextFilter: string,
    searchConditions: string,
    isHistory?: boolean
  ) => {
    let request: LicenseManagementRequest = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortColumn: sortColumn,
      sortAscending: sortAscending,
      fullTextFilter: fullTextFilter,
      searchConditions: searchConditions,
      history:isHistory
    };
    var result = await this.getClient().getAllLicenseManagement(
      this.getApiVersion(),
      UserContext.getUserId(),
      undefined,
      request
    );
    return result;
  };

  @action sendReminders = async (body: SendLicenseReminderRequest) => {
    var result = await this.getClient().sendReminders(
      this.getApiVersion(),
      UserContext.getUserId(),
      undefined,
      body
    );
    return result;
  };
  @action sendQueue = async (body: any) => {
    var result = await this.getClient().queue(
      this.getApiVersion(),
      UserContext.getUserId(),
      undefined,
      body
    );
    return result;
  };
  @action sendDeQueue = async (body: any) => {
    var result = await this.getClient().dequeue(
      this.getApiVersion(),
      UserContext.getUserId(),
      undefined,
      body
    );
    return result;
  };
}

export default new StateLicenseStore();
